//  Core

.badge {
  text-transform: uppercase;
  height: 20px;
  line-height: 21px;
}

@mixin badge-neutral-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;
  }
}

@each $color, $value in $theme-colors-neutral {
  @include badge-neutral-variant(".badge-neutral-#{$color}", $value);
}
.badge-matic {
  color:white;
  background-color:#c32ef4 ;
}
// Dots

.badge-xl {
  height: 1rem !important;
  width: 1rem;
  border-radius: 2rem !important;
}


.badge {
  &.badge-circle-inner,
  &.badge-circle {
    text-indent: -999rem;
    @include border-radius(100%);
    height: 10px;
    width: 10px;
    padding: 0;
    box-shadow: 0 0 0 2px $white;
  }

  &.badge-circle-inner {
    width: 16px;
    height: 16px;
    position: relative;

    &::after {
      content: '';
      background: $white;
      width: 8px;
      height: 8px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -4px 0 0 -4px;
      @include border-radius(100%);
    }
  }
}

// Dots positions

.badge-wrapper {
  position: relative;
}

.badge-position {
  position: absolute !important;

  &--top-right {
    &.badge-circle {
      right: -5px;
      top: -5px;
    }

    &.badge-circle-inner {
      right: -8px;
      top: -8px;
    }
  }

  &--top-center {
    &.badge-circle {
      left: 50%;
      margin-left: -5px;
      top: -5px;
    }

    &.badge-circle-inner {
      left: 50%;
      margin-left: -8px;
      top: -8px;
    }
  }

  &--top-left {
    &.badge-circle {
      left: -5px;
      top: -5px;
    }

    &.badge-circle-inner {
      left: -8px;
      top: -8px;
    }
  }

  &--bottom-right {
    &.badge-circle {
      right: -5px;
      bottom: -5px;
      top: auto;
    }

    &.badge-circle-inner {
      right: -8px;
      bottom: -8px;
      top: auto;
    }
  }

  &--bottom-center {
    &.badge-circle {
      left: 50%;
      margin-left: -5px;
      bottom: -5px;
      top: auto;
    }

    &.badge-circle-inner {
      left: 50%;
      margin-left: -8px;
      bottom: -8px;
      top: auto;
    }
  }

  &--bottom-left {
    &.badge-circle {
      left: -5px;
      bottom: -5px;
      top: auto;
    }

    &.badge-circle-inner {
      left: -8px;
      bottom: -8px;
      top: auto;
    }
  }
}
