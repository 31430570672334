.payments {
    .screen__content .control-box-wrapper .control-box-list {
        display: inline-block !important;
    }

    .btn-wrapper-left screen-footer__btn multi-select-col {
        padding-right: 13em;
    }

    .btn_control__okay {
        width: fit-content;
    }

    .subscription-containter>* {
        margin: 0 25px;
    }

    .error-msg {
        padding: 1em;
        font-weight: bold;
        color: red;
        font-style: italic;
    }
}