.single-column {
    width: 45% !important; 
    max-width: 50%;
    margin-right: 3em !important; 
}

.btn-row {
    margin-top: -4.4em;
    justify-content: right;
    width: 100% !important;
}

.btn-row-create {
    margin-top: 2em;
    justify-content: right;
    width: 100% !important; 
}

.btn-fit {
    min-width: fit-content;
}

.profile-content {
    justify-content: flex-start !important;
    padding-left: 4em;
}

.send-password-reset-btn {
    margin-left: 4em;
    margin-right: 1em;
}

.multi-select-col {
    margin-bottom: 1.4em;
}

.user-details-page-loading {
    text-align: center;
    padding-top: 20%;
    width: 100%;
}

.alert-message {
    width: 30%;
    text-align-last: center;
    position: absolute;
    margin-left: 30%;
    margin-top: -4.3em;
    top: 1.3em;
    z-index: 2000;
}

.narrow-dropdown {
    width: 22rem;
}

.min-num-observ-container {

    .field-input .generic-input-component select {
        width: 5rem;
    }

    .field-input .generic-input-component select option {
        font-size: 1.25rem;
    }

}