.payout-list {
    .table-wrapper .row-area .data-row .col.actions .disabled {
        opacity: 0.5;
        cursor: default;
    }
}

.payout-filter {
    .child {
        max-width: unset;
        width: 16.6rem;
    }
}