// Material UI Helpers
body {
  .MuiButtonBase-root {
    min-width: 0;
    outline: none !important;
  }

  .MuiButton-root {
    line-height: initial;

    &:focus {
      outline: none;
    }
  }

  .MuiPaper-root {
    color: $body-color;
  }
}

.MuiGrid-item,
.MuiPaper-root {
  position: relative;
}
@include media-breakpoint-down(lg) {
  .MuiGrid-item {
    width: 100%;
  }
}

.MuiCard-root {
  transition: $transition-base;
}

a.MuiButton-outlined:hover {
  color: initial;
}

.min-w-auto {
  min-width: 5px !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden {
  outline: none !important;
}

.MuiButton-root.btn-inverse:hover {
  background-color: $sksecondary;
}

// Custom spacing

.mb-spacing-6 {
  margin-bottom: 24px;
}

.mb-spacing-6-x2 {
  margin-bottom: 48px;
}

// Forms

body {

  .MuiOutlinedInput-notchedOutline {
    transition: $transition-base;
  }

  .MuiOutlinedInput-root:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: #2ebc7f;
    }
  }

  svg.MuiSvgIcon-root {
    fill: white;
  }

  .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    color: white;
  }

  .MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, 0.04);
  }

  .MuiTouchRipple-child {
    background-color: white;
  }

  .MuiTableCell-stickyHeader {
    background: $first;
  }

/*FIX*/
  .MuiTableSortLabel-root:focus {
    color: rgba(73, 226, 135, 0.54);
  }

  .MuiTableSortLabel-root:hover {
    color: rgba(73, 226, 135, 0.54);
  }
  
  .MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: rgba(73, 226, 135, 0.87);
  }

  .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: rgba(73, 226, 135, 0.54);
    opacity: 1;
  }

  .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon {
    opacity: 0.5;
  }

  .MuiTableRow-root {
    color: white !important;
  }

  .MuiTableCell-root {
    font-family: 'Raleway',sans-serif;
    border-bottom: 1px solid rgba(0, 60, 91, 0.30);
  }

  th.MuiTableCell-root.MuiTableCell-head.stickyHeader{
    border-bottom: 1px solid $nietDarkBlue !important;
  }

  .tableMaxHeight, .skOverview {
    height: 59rem;
  }

  .stickyHeader {
    top: 0;
    left: 0;
    z-index: 2;
    position: sticky;
    background-color: $first;
    text-align: center;
  }

  .MuiTableCell-head {
    color: $nietDarkBlue;
    font-weight: 500;
  }

  .MuiTableCell-alignRight {
    flex-direction: row;
  }

  .MuiTableCell-body {
    color: $nietDarkBlue;
  }
  
  .MuiTableCell-footer {
    color: rgba(255, 255, 255, .54);
  }

  .MuiTablePagination-root {
    color: rgba(255, 255, 255, 0.87);
  }

  .MuiInputBase-root { 
    color: rgba(255, 255, 255, 0.87);
    font-family: 'Raleway', sans-serif;
  }

  .MuiInputBase-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.38);
    cursor: default;
  }
  
  .MuiInputBase-input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    -webkit-tap-highlight-color: transparent;
  }

  .MuiInputBase-input::-webkit-input-placeholder {
    color: currentColor;
    opacity: 0.42;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .MuiInputBase-input::-moz-placeholder {
    color: currentColor;
    opacity: 0.42;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .MuiInputBase-input:-ms-input-placeholder {
    color: currentColor;
    opacity: 0.42;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .MuiInputBase-input::-ms-input-placeholder {
    color: currentColor;
    opacity: 0.42;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    color: $nietOrange;
    background-color: rgba(244, 126, 58, 0.10);
  }

  .MuiListItem-button:hover {
    background-color: rgba(255, 255, 255, 0.04);
  }

  .MuiMenu-list, .MuiListItem-button {
    background-color: #fff;
    color: $nietDarkBlue;
  }

  .MuiInput-underline:after {
    border-bottom-color: $sksecondary;
  }
  
  .MuiInput-underline:before {
    border-bottom-color: white;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid #2ebc7f;
  }

  .MuiTablePagination-toolbar {
    padding-top: .5rem;
  }

  p.MuiTypography-root.MuiTablePagination-caption.MuiTypography-body2, .MuiSelect-root.MuiSelect-select.MuiTablePagination-select.MuiSelect-selectMenu.MuiInputBase-input,
  .MuiList-root.MuiMenu-list {
    font-weight: bold;
  }

  .MuiTypography-body2 {
    font-size: 1rem;
  }

  .contactUsMenu {
    font-weight: 400 !important;
  }

  .MuiList-root .MuiListItem-root.MuiListItem-button {
    font-weight: bold;  
  }
  
  .MuiAutocomplete-listbox {
    overflow: hidden;
  }

  p.MuiTypography-root {
    font-family: 'Raleway';
  }

  /*Abbreviate Rows per page to Rows*/
  p.MuiTypography-root.MuiTablePagination-caption.MuiTypography-body2.MuiTypography-colorInherit:nth-child(2) {
    width: 2.75rem;
    overflow: hidden;
    white-space: nowrap;
  }

  .app-content--inner {
    padding-bottom: 0;
  }

  .skTabs .MuiTabs-scroller{
    background: $primary;
    height: 5rem;
    border-radius: .75rem;
  }

  .skTabs .MuiTabs-indicator {
    display: none;
  }
  
  .card-header .MuiButton-root.MuiButton-root {
     background-color: $primary !important;
     border: none;
  }

  .accordion-toggle .MuiButtonBase-root.MuiButton-root:hover {
    background-color: rgba(255, 255, 255, 0.04);
  }

  .MuiPickersToolbar-toolbar {
    background-color: $nietDarkBlue;
  }

  .MuiPickersDay-daySelected {
    background-color: $nietDarkBlue;
  }

  .MuiPickersBasePicker-pickerView .MuiIconButton-root:hover, .filterDatePicker .MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .MuiPickersBasePicker-pickerView .MuiPickersDay-daySelected.MuiIconButton-root:hover  {
    background-color: $nietBlue;
  }

  .MuiTypography-caption, .MuiTypography-h4, .MuiTypography-h5, .MuiTypography-subtitle1, .MuiMenuItem-root {
    font-family: 'Raleway', sans-serif;
  }

  .MuiPickersCalendarHeader-transitionContainer {
    color: $nietDarkBlue;
  }

  .MuiPickersCalendarHeader-iconButton svg.MuiSvgIcon-root{
    fill: $nietDarkBlue;
  }

  .MuiPickersCalendar-transitionContainer {
    min-height: 222px;
  }

}
