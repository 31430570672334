.hidden-file-input {
    display: none;
}

.dialog-errors {
    padding-left: 1rem;
    padding-right: 1rem;

    &-item {
        color: var(--error);
    }
}


.dialog-messages {
    padding-left: 1rem;
    padding-right: 1rem;
}

.tagPicker {
    position: relative;
    top: 0rem;
    width: 100%;
    // height: 3rem;
    border-radius: 4px !important;
    border: 1px solid var(--nietBlue) !important;
    padding-left: 0.5rem;
    color: var(--nietDarkBlue) !important;
}

.title-header {
    margin-bottom: 0.25rem;
}

.title {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
    top: 0rem;
    left: 0rem;
    font-weight: bold;
    font-size: 1.25rem;
}

.breadcrumb-table-wrapper {
    height: 100%;

    &-nodrill {
        height: calc(100% + (var(--footer-height)) - 6rem);
    }

    .contentDisplay-list {
        height: calc(100% + (var(--footer-height) - 10rem))
    }

    .breadcrumb-wrapper {
        display: flex;
        align-items: center;
        margin-left: 17rem;
        gap: 1rem;
        margin-bottom: 1rem;



        .breadcrumb {
            font-size: 1.4rem;
            font-weight: 600;
            cursor: pointer;
            transition: opacity 100ms ease-in;

            &:hover {
                opacity: .5;
                transition: opacity 100ms ease-in;
            }

            &:last-child {
                cursor: unset;
                opacity: unset;
                transition: unset;
            }
        }

        .fa-angle-right {
            font-size: 2rem;
        }
    }
}

.fa-spin {
    -webkit-animation: spin 2s;
    -moz-animation: spin 2s;
    -o-animation: spin 2s;
    animation: spin 2s;
}