.create-user-btn {
    left: 19em;
    bottom: 2em;
    position: relative;
}

.user-mgr-header {}

.display-inline {
    display: inline;
}

.sub-header {
    margin-left: 2em;
}

.page-loading {
    text-align: center;
    padding-top: 20%;
}

.activate-check-fa-icon {
    cursor: pointer;

    &.not-selected {
        background-color: rgba(250, 250, 250, 0);
        border: 1px solid rgba(6, 27, 39, 0.75);
        border-radius: 3px;
        color: white;
    }
}

// .picker-style {
//     width: 307px;
//     // border-radius: 0.5rem;
//     // border: 1px solid var(--nietDarkBlue);
//     // box-shadow: inset 0px 0px 1px 1px rgba(156, 156, 156, 0.5);
// }

.activate-users-dialog {
    margin-left: 3rem;

    .activate-users-list {
        display: flex;
        justify-content: left;
        margin-left: 1rem;
        width: 100%;
        max-height: 20rem;
        overflow-y: auto;
    }
}

.merge-input{
    display: flex;
    margin-bottom:1rem;
    max-width: 50rem;

    &.success{
        color:var(--success);
        font-size: 1.5rem;
        text-align: center;
        font-weight: 400;
        width: 100%;
        text-align: center;
        display:flex;
        justify-content: center;
    }
}