

.print-report {
    .signature-message {
        width: 100%;
        text-align: center;
        margin-top: 4rem;
        font-style: italic;
    }

    .report-header {
        font-size: 2rem;
        font-weight: bold;
        padding: 0rem;
        width: 100%;
        text-align: center;
        font-size: 2rem;
    }

    .report-subheader {
        font-size: 1.5rem;
        font-weight: bold;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }

    .report-domain-text {
        font-size: 1.25rem;
        font-weight: bold;
        font-style: italic;
        margin-bottom: .5rem;
    }

    .observation-subheader {
        .observee-area {
            .observee-metadata {
                div {
                    padding-top: .5rem;
                }
            }
        }
    }

    .report-body {
        padding-top: 1rem;
    }

    .report-domain-grid {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;

        .name {
            width: 40%;
            height: 3.5rem;
            padding-left: .5rem;
            font-weight: 600;
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
        }

        .rating {
            font-weight: 600;
            width: 20%;
            text-align: center;
            height: 3.5rem;
            padding-left: .5rem;
            border-left: 1px solid var(--darkgray-1);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .desc {
            height: 3.5rem;
            padding-left: .5rem;
            font-weight: 600;
            border-left: 1px solid var(--darkgray-1);
            width: 40%;
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
        }

        .grid-header {
            color: white;
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: var(--nietDarkBlue);
            border-left: 1px solid var(--darkgray-1);
            padding-top: .25rem;
            padding-bottom: .25rem;
            line-height: 1.75rem;

            .name {
                color: white;
            }

            .rating {
                color: white;
            }

            .desc {
                color: white;
            }
        }



        .grid-body {
            display: flex;
            flex-direction: column;

            .evidence-section {
                display: flex;
                flex-direction: column;
                align-items: left;
                justify-content: center;
                min-height: 5rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
                color: black;
                gap: 1rem;

                .evidence {
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;

                    .evidence-title {
                        font-weight: 600;
                    }

                    .print-detail-image-evidence {
                        position: relative;
                        margin-right: 1rem;
                        margin-bottom: 1rem;
                        width: 10rem;
                        height: 6rem;
                        border: 1px solid rgba(0, 0, 0, 0.25);
                        background-repeat: no-repeat;
                        background-size: cover;

                        &.flex-it {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .fa-icon {
                            font-size: 3.5rem;
                        }

                        .cnt {
                            position: absolute;
                            bottom: 0rem;
                            right: 0rem;
                            width: 2rem;
                            height: 2rem;
                            border-radius: 50%;
                            background-color: #f47e3a;
                            color: white;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }

                .evidence-none {
                    font-style: italic;
                    text-align: center;
                    width: 100%;
                }
            }

            .grid-row {
                display: flex;
                flex-direction: row;
                background-color: var(--row-odd);

                &.row-odd {
                    background-color: var(--row-even);
                }


                .name {
                    color: var(--nietDarkBlue);
                    font-weight: 700;
                    border-bottom: 1px solid var(--darkgray-1);
                }

                .rating {
                    color: var(--nietDarkBlue);
                    font-weight: 700;
                    border-bottom: 1px solid var(--darkgray-1);
                }

                .desc {
                    font-weight: 400;
                    color: var(--nietDarkBlue);
                    border-bottom: 1px solid var(--darkgray-1);
                }
            }
        }
    }

    .report-summary {
        padding-bottom: .5rem;
        margin-bottom: 1rem;

        .subheader {
            display: flex;
            flex-direction: row;
            gap: .5rem;
            margin-bottom: .5rem;
        }

        .subheader-text {
            font-weight: bold;
        }

        .text {
            margin-bottom: .5rem;
            white-space: pre-wrap;
            word-wrap: break-word;
        }
    }

    .signature-area {
        width: 100%;
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        .signature-content {
            font-size: 1rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            margin-top: 5rem;
        }

        .signature-section {
            display: flex;
            flex-direction: row;
            width: 95%;
            gap: 8rem;

            .signature-body {
                display: flex;
                flex-direction: column;
                gap: .25rem;
                width: 50%;

                .signature-header {
                    font-size: 1.1rem;
                    font-weight: bold;
                }


                .signature-sign {
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;

                    .signature-line {
                        display: flex;
                        flex-direction: row;
                        gap: 1rem;
                        margin-top: 1rem;

                        .signature-desc {
                            width: 3rem;
                            text-align: left;
                            align-self: flex-end;
                        }

                        .signature-name {
                            font-size: 1.35rem;
                            border-bottom: 1px solid var(--nietDarkBlue);
                            width: 100%;

                            padding-left: 1.5rem;
                        }

                        .signature {
                            font-size: 2.5rem;
                            font-family: 'Brush Script MT', cursive;
                            border-bottom: 1px solid var(--nietDarkBlue);
                            width: 100%;

                            padding-left: 1.5rem;

                            span {
                                position: relative;
                                top: 1rem;
                            }
                        }

                        .signature-date {
                            width: 100%;
                            font-size: 1.35rem;
                            border-bottom: 1px solid var(--nietDarkBlue);
                            padding-left: 1.5rem;
                        }
                    }

                    .signature-not-manual {
                        font-size: .9rem;
                        font-style: italic;
                    }
                }


            }
        }


    }
}