.configureContent {
    // width: 80vw;
    // position: relative;
    // left: calc(-43vw + 55%)

    /* left: calc(-43vw + 55%); */
    position: relative;
    width: 79rem;

    .container {
        display: grid;
        grid-template-columns: 28em auto;
    }

    .rubric-header-inner {
        display: grid;
        grid-template-columns: 8.5em auto;
    }

    .rubric-header-inner-rightside {
        display: grid;
        grid-template-columns: 6.8em auto;
    }
}

.configureHeaderLabel {
    display: inline-block;
}

.headerLabelRight {
    padding-left: 14%;
}

.headerColor {
    font-weight: 600;
}

.configureRubric-list {
    position: relative;
    padding-top: 1rem;
    padding-bottom: 2rem;
    padding-right: 1rem;
    height: calc(100% + var(--footer-height));
}

.add-performance-btn {
    font-size: smaller;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}

.add-domain-btn {
    font-size: .9rem;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
}

.performance-btn-row {
    width: 100%;
    display: flex;
    justify-content: left;
    flex-direction: row;
    gap: 1rem;
    position: relative;
    padding-left: .7rem;
}

.assign-domain {
    padding-left: 57%;
}

.resetOrgInput {
    position: absolute;
    padding-top: 0.5rem;
    padding-left: 0.5rem;
}

.rs-toggle-checked .rs-toggle-presentation:hover {
    background-color: var(--nietDarkBlue) !important;
}

.rs-toggle-checked .rs-toggle-presentation {
    background-color: var(--nietDarkBlue) !important;
}

.layout-btn {
    display: inline-block;
    margin-right: 30px;
    width: 130px;
    background-color: var(--white);
    box-shadow: 2px 2px 1px var(--lightgray-2);
    border: 2px solid var(--nietDarkBlue);
    border-radius: 6px;
    color: var(--nietDarkBlue);
    padding: 1.4rem 1rem 1.4rem 1rem;
    font-size: 13px;

    &:hover {
        background-color: var(--lightgray-2);
    }
}


.exit-btn {
    display: inline-block;
    width: 130px;
    background-color: var(--white);
    box-shadow: 2px 2px 1px var(--lightgray-2);
    border: 2px solid var(--nietDarkBlue);
    border-radius: 6px;
    color: var(--nietDarkBlue);
    padding: 1.4rem 1rem 1.4rem 1rem;
    font-size: 13px;

    &:hover {
        background-color: var(--lightgray-2);
    }
}

.add-indicator-btn {
    padding: 1.35rem 1rem 1.35rem 1rem;
    font-size: 12px;

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        cursor: default;
    }
}

.btn_control.btn_control__okay.add-indicator-btn.disabled {
    cursor: default;
}

.status-label {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 1px;
    padding-top: 1px;
    margin-right: 10px;
    font-family: ui-monospace;
    margin-left: 10px;
    font-size: unset;
    border: 1.2px solid var(--nietDarkBlue);
    border-radius: 6px;
}

.status-label-published {
    background-color: darkorange;
}

.pencil {
    &:hover {
        cursor: pointer;
    }
}

.rubric-label {
    display: inline-block;
    padding-left: 5px;
}

.plus-icon {
    font-size: large;
    top: 0.4em;
    position: absolute;
    left: -0.6em;

    &:hover {
        cursor: pointer;
    }
}

.plus-icon-domain {
    font-size: large;
    top: -.8rem;
    position: absolute;
    left: 0;

    &:hover {
        cursor: pointer;
    }
}

.plus-icon-disabled {
    // border: 1.2px solid var(--white);
    // border-radius: 6px;
    font-size: large;
    margin-bottom: -5px;
    color: grey;
}

.body {
    font-size: 14px;
}

.rubric-dialog {

    .rs-picker-toggle-wrapper {
        margin-top: 0;
    }

    .x-icon {
        float: right;
        margin-top: -44px;
        margin-right: 9px;
        z-index: 20000;
        position: relative;

        &:hover {
            cursor: pointer;
        }
    }

    .new-abbr-input-wrapper {}

    .abbr-error {
        position: relative;
        top: -1.5rem;
        color: red;
    }

    .rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
        top: 4px;
        font-size: inherit;
    }

    .generic-input-select {
        position: relative;
        top: 0rem;
        width: 100%;
        height: 2.3rem;
        border-radius: 0.5rem;
        border: 1px solid var(--nietDarkBlue);
        box-shadow: inset 0px 0px 1px 1px rgba(156, 156, 156, 0.5);
        padding-left: 0.85rem;
        color: var(--nietDarkBlue);

        option {
            color: var(--nietDarkBlue);
        }

        &::placeholder {
            color: var(--nietDarkBlue);
            opacity: 0.63;
        }
    }

    .dialog-control .dialog .extra-top-space {
        padding-top: 0.5em;
        padding-right: 1em;
    }

    .picker-style {
        width: 307px;
        // border-radius: 0.5rem;
        // border: 1px solid var(--nietDarkBlue);
        // box-shadow: inset 0px 0px 1px 1px rgba(156, 156, 156, 0.5);
    }

    .rs-picker-default .rs-picker-toggle.rs-btn,
    input.rs-picker-search-bar-input,
    .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
        border-radius: 0.5rem;
        border: 1px solid var(--nietDarkBlue);
        box-shadow: inset 0px 0px 1px 1px rgba(156, 156, 156, 0.5);
    }

    .rs-picker-toggle {
        font-size: 1rem;
    }

    s .top-spacing {
        padding-top: 1.6em;
    }

    .rs-btn-close {
        display: none;
    }

    // .dropdown {
    //     position: relative;
    //     top: 0rem;
    //     width: 100%;
    //     height: 3rem;
    //     border-radius: 4px;
    //     border: 1px solid var(--nietBlue);
    //     padding-left: 0.5rem;
    //     color: var(--nietDarkBlue)
}

// }