.dialog-control {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;

    top: 0;
    left: 0;
    background-color: var(--disabled-background);
    width: 100%;
    height: 100%;
    z-index: 10001;

    &__block-ui {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10000;
        background-color: var(--blockui);
    }

    .dialog {
        flex-direction: column;
        min-width: 50rem;
        background-color: var(--white);
        border: 2px solid #000000;
        border-radius: 8px;
        padding: .5rem 1.5rem 1.5rem 1.5rem;

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 1rem;

            &-title {
                width: 100%;
                text-align: center;
                margin-left: 1rem;
                white-space: normal;

                h2 {
                    width: 100%;
                    font-weight: bold;
                    margin-bottom: .1rem;
                    margin-top: 1rem;
                }

                h5 {
                    width: 100%;
                    /*max-width: 50rem;*/
                    font-weight: 400;
                    margin-top: 1rem;
                }
            }

            &-close {
                color: var(--primary-blue);
                font-weight: 700;
                font-size: 1.75rem;
                cursor: pointer;
                z-index: 1000;
            }
        }

        &__error {
            color: var(--error);
            margin-bottom: 1rem;
        }

        &__inner {
            width: 100%;
            min-height: 5rem;
            margin-bottom: .5rem;
            margin-left: auto;
            margin-right: auto;
            max-height: 55rem;
            overflow-y: auto;
        }

        &__inner__center {
            min-height: 4rem;
        }

        .narrow-content {
            width: 55%;
        }


        &__footer {
            width: 100%;
            display: flex;
            justify-content: right;
            flex-direction: row;

            gap: 1rem;

            .dialog-btn {
                font-size: .8rem;
                min-width: 9rem;
                height: 3rem;
                padding: 0 .7rem 0 .7rem;
                line-height: 2.7rem;

                &-previous {
                    background-color: var(--white);
                    color: var(--nietDarkBlue);
                }

                .inner_loading {
                    padding-top: .8rem;
                }

            }


        }

        &__footer__center {
            justify-content: center;
        }

        .full-width {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }

        .extra-top-space {
            padding-top: 1.5em;
            padding-right: 3em;
        }

        .indicator-settings-footer {
            margin-top: 4em;
        }
    }
}