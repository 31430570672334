// .field-input {
//     padding-bottom: 1em;

//     .generic-input-component input {
//         border: 0.1px solid var(--nietDarkBlue);
//         box-shadow: inherit;
//         border-radius: 0.25rem;
//     }
// }
.transactions {
    .bottom-space {
        padding-bottom: 1em;

        .generic-input-component input {
            border: 0.1px solid var(--nietDarkBlue);
            box-shadow: inherit;
            border-radius: 0.25rem;
        }
    }

    .btn-row {
        margin-top: -4.4em;
        justify-content: right;
    }

    .screen__content .screen-footer__btn {
        width: fit-content;
        padding-left: 1em;
    }

    .btn_control__cancel {
        width: 11em;
        margin-right: 1em;
    }

    .btn_control__okay {
        margin-right: 1em;
    }
}