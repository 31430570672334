.rubric-indicator-table-wrapper {
    .table-wrapper {
        .header-row {
            width: 100%;
            height: fit-content;
            padding-top: 0.1em;
            padding-bottom: 0.5em;

            .col {
                justify-content: center;

                &:last-child {
                    border-right: unset;
                }

            }
        }

        .row-area {
            overflow-y: hidden;

            .data-row {
                min-height: 16rem;

                .col {
                    display: flex;
                    flex-direction: column;
                    align-items: unset;
                    position: relative;

                    &:last-child {
                        border-right: unset;
                    }

                    .pencil-outer-container {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;


                        .pencil-container {
                            width: 100%;
                            display: flex;
                            justify-content: flex-end;
                            padding-right: 1rem;
                            padding-bottom: .5rem;
                        }

                        .pencil-indicator {
                            &:hover {
                                cursor: pointer;
                            }
                        }

                        .pencil {
                            position: absolute;
                            bottom: 0;
                            padding-bottom: 5px;

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}

.rubric-page-loading {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.loading-font {
    padding-top: 0.7em;
    font-size: 1.5rem;
    font-weight: 600;
}

.create-rubric-btn {
    left: 19em;
    top: -1em;
}