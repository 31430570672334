@import url('https://fonts.googleapis.com/css2?family=Raleway&family=Roboto&display=swap');


.observation-page {

    --table-header-blue: #023c5b;
    --table-row-bk-color: #eae4db;
    --lightning-color: #fcb34c;
    --tagged-note-color: #ffe9ca;
    --hover-tagged-note-color: #ffc778;

    --fixed-row-width: 9rem;


    position: absolute;
    top: var(--header-height);
    left: 0rem;
    height: calc(100% - var(--header-height) - var(--footer-height) - .5rem);
    width: 100%;
    font-family: 'Raleway', sans-serif;

    .small-loading {
        float: right;
    }

    .observation-screen-wrapper {
        position: absolute;
        top: 1rem;
        left: 0rem;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        overflow-x: hidden;

        &.overflow-auto {
            overflow: auto !important;
            overflow-y: scroll !important;
            overflow-x: hidden;
            margin-top: 2rem;
            height: calc(100% - 1.9rem);
        }

        &.center-content {
            align-items: center;
            margin: auto;
            display: flex;
            flex-direction: column;
            min-width: 1024px;
            margin-top: 1.5rem;
        }

        .observation-message {
            margin-top: 1rem;

        }

        .observation-subheader {
            position: absolute;
            top: 0rem;
            left: 0rem;
            height: 10rem;
            width: 100%;
            display: flex;
            padding-left: 2rem;
            padding-right: 4rem;
            min-width: 1024px;
            justify-content: space-between;

            .observee-area {
                width: 35%;

                .observee-banner {
                    position: relative;
                    top: 2rem;
                    padding-left: 3.5rem;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .text {
                        font-size: 1.5rem;
                        font-weight: 700;
                    }

                    .edit-icon {
                        padding-left: 1.25rem;
                        font-size: 1.25rem;

                    }
                }

                .observee-metadata {
                    position: relative;
                    line-height: 1.75rem;
                    top: 2rem;
                    padding-left: 3.5rem;
                    white-space: nowrap;
                    font-size: 1.12rem;
                    font-weight: 600;
                    white-space: pre-wrap;

                    span {
                        margin-left: 0.25rem;
                        cursor: pointer;

                        -webkit-touch-callout: none;
                        -webkit-user-select: none;
                        -khtml-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                    }
                }
            }

            .timer-area {
                position: relative;
                top: 2rem;
                height: calc(100% - 2rem);
                width: 31%;
                white-space: nowrap;

                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                .stop-watch {
                    text-align: center;
                    color: black;
                    font-family: 'Arial';
                    font-style: normal;
                    line-height: 74px;
                    font-size: 4rem;
                    font-weight: 700;
                }

                .control-btns {
                    position: relative;
                    left: 25%;
                    width: 50%;
                    height: 2rem;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;

                    .btn {
                        min-width: 5.5rem;
                        text-align: center;
                        font-weight: 700;
                        font-size: 1rem;
                        padding-left: 0.5rem;
                        padding-right: 0.5rem;
                        border: 1px solid black;
                        border-radius: 6px;
                        color: #3B3E66;
                        cursor: pointer;

                        &.disable {
                            cursor: not-allowed;
                            opacity: .5;
                        }
                    }
                }
            }

            .control-box-right-header {
                margin-top: 2rem;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: 50%;
            }

            .observation-message {
                display: block;
                text-align: left;
                font-size: 1.2rem;
            }

            .control-button-area {
                min-width: 33%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 1.5rem;
                //margin-right: 1rem;

                &.self-reflection {
                    justify-content: end;
                }

                .btn {
                    // padding: 1.5rem 0.75rem 1.5rem 0.75rem;
                    // height: 2rem;
                    // width: auto;
                    min-width: 10rem;
                    // font-size: 12px;
                    // font-weight: 700;
                    // color: #3B3E66;
                    // background-color: white;
                    // border: 2px solid #3B3E66;
                    // border-radius: 6px;

                    &.disabled {
                        //opacity: 0.5;
                        cursor: not-allowed;
                    }

                    &.continue-later-btn {
                        min-width: 15rem;
                    }
                }
            }

        }

        .content-section {
            position: absolute;
            top: 13.5rem;
            left: 0%;
            height: 80%;
            width: 100%;
            display: flex;

            .self-reflection-summary-section {
                position: relative;
                border-top: 1px solid var(--primary-blue);
                border-left: 1px solid var(--primary-blue);
                height: 100%;
                overflow-x: hidden;
                overflow-y: auto;

                .drop-down-and-txt-wrapper {
                    position: relative;
                    width: 75%;
                    left: 50%;
                    transform: translateX(-50%);

                    label {
                        position: relative;
                        font-size: 0.9rem;
                        font-weight: 600;
                    }

                    .select-list-wrapper {
                        position: relative;
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 1rem;
                        width: 100%;
                    }
             
                    .select-list-container {
                        display: flex;
                        align-items: center;
                    }

                    .loading-container {
                        margin-left: 10px; /* Adjust the margin as needed */
                    }

                    .loading-wrapper-summary {
                        width: 100%;
                        display: flex;
                        flex-direction: row;

                    }

                    .summary-text-area {
                        position: relative;
                        padding: 0.5rem;
                        width: 100%;
                        min-height: 7rem;
                        border: 1px solid black;
                        border-radius: 4px;
                    }
                }

                h1 {
                    margin-top: 1rem;
                    margin-left: 1rem;
                    text-align: center;
                    font-weight: 700;
                }

                h2 {
                    margin-top: 1rem;
                    margin-left: 1rem;
                    font-weight: 700;
                }

                // &::-webkit-scrollbar-track {
                //     border-left: 1px solid black;
                //     border-right: 1px solid black;
                //     background-color: #ffffff;
                // }

                // &::-webkit-scrollbar {
                //     width: 16px;
                //     background-color: #ffffff;
                //     border-left: 1px solid black;
                //     border-right: 1px solid black;
                // }

                // &::-webkit-scrollbar-thumb {
                //     border-radius: 7px;
                //     background-color: #666666;
                //     border: 4px solid transparent;
                //     background-clip: content-box;
                // }

                // &::-webkit-scrollbar-button {
                //     border-style: solid;
                //     width: 16px;
                //     height: 10px;
                // }

                // &::-webkit-scrollbar-button:vertical:decrement {
                //     border-width: 0 7px 14px 7px;
                //     border-color: transparent transparent #9e9e9e transparent;
                // }

                // &::-webkit-scrollbar-button:vertical:increment {
                //     border-width: 14px 7px 0 7px;
                //     border-color: #9e9e9e transparent transparent transparent;
                // }
            }

            .script-section {
                position: relative;
                border-top: 1px solid var(--primary-blue);
                height: 100%;

                .grey-out-most-notes {
                    position: absolute;
                    top: 0rem;
                    left: 0rem;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(234, 228, 219, 0.65);
                    z-index: 99;
                }

                .tabs-section {
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;

                    position: absolute;
                    top: -2.5rem;
                    right: 16px;
                    width: auto;
                    height: 2.5rem;
                    //z-index: 100;
                    display: flex;

                    .tab {
                        position: relative;
                        float: left;
                        height: 100%;
                        width: 9rem;
                        background-color: #d9d9d9;
                        border-bottom: 1px solid black;
                        border-top: 2px solid black;
                        border-right: 2px solid black;
                        border-left: 2px solid black;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 0.9rem;
                        font-weight: 700;
                        color: #1b1b1b;
                        cursor: pointer;

                        .fa-icon {
                            margin-left: 0.25rem;
                            background-color: black;
                            border: 1px solid rgba(0, 0, 0, 0.66);
                            border-radius: 50%;
                            color: rgb(170, 222, 255);
                        }

                        &.active {
                            border-bottom: none;
                            background-color: white;
                            font-size: 1rem;
                            //z-index: 1000;
                        }
                    }
                }

                .content-area {
                    position: absolute;
                    top: 0rem;
                    left: 0rem;
                    width: 100%;
                    height: 100%;
                    overflow-y: scroll;
                    overflow-x: hidden;

                    .note-row {
                        position: relative;
                        display: grid;
                        margin-top: 1rem;
                        left: 3.5rem;
                        width: calc(100% - 6rem);
                        min-height: 10rem;
                        height: auto;
                        border-bottom: 1px solid black;
                    }


                    .new-note-btn-txt-wrapper {
                        width: calc(100% - 3rem);
                        display: flex;
                        justify-content: space-between;

                        .new-note-btn {
                            position: relative;
                            margin-top: 2rem;
                            padding-top: 1.75rem;
                            padding-bottom: 1.75rem;
                            margin-bottom: 1.75rem;
                            left: 3.5rem;
                            width: 12rem;
                            background-color: #023c5b;
                            color: white;
                            font-size: 12px;

                            .plus-sign {
                                position: absolute;
                                margin: 0;
                                padding: 0;
                                padding-left: 1px;
                                top: -1rem;
                                left: -1rem;
                                width: 2rem;
                                height: 2rem;
                                border: 1px solid white;
                                border-radius: 50px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: white;
                                font-size: 3rem;
                                font-weight: 400;
                                background-color: #023c5b;
                            }

                            &.disabled {
                                opacity: 0.5;
                                cursor: not-allowed;
                            }
                        }

                        .new-script-instruction {
                            margin-top: 3.5rem;
                        }
                    }

                    //summary stuff:
                    h1 {
                        margin-top: 1rem;
                        margin-left: 1rem;
                        text-align: center;
                        font-weight: 700;
                    }

                    h2 {
                        margin-top: 1rem;
                        margin-left: 1rem;
                        font-weight: 700;
                    }

                    label {
                        margin-left: 1rem;
                        font-size: 1.2rem;
                        font-weight: 600;
                    }

                    .select-list-wrapper {
                        position: relative;
                        margin-left: 1rem;
                        margin-bottom: 1rem;
                        width: 33%;
                    }

                    .select-list-container {
                        display: flex;
                        align-items: center;
                    }

                    .loading-container {
                        margin-left: 10px; /* Adjust the margin as needed */
                    }

                    .summary-text-area {
                        position: relative;
                        margin-left: 1rem;
                        padding: 0.5rem;
                        width: 66%;
                        min-height: 7rem;
                        border: 1px solid black;
                        border-radius: 4px;
                    }

                    // &::-webkit-scrollbar-track {
                    //     border-left: 1px solid black;
                    //     border-right: 1px solid black;
                    //     background-color: #ffffff;
                    // }

                    // &::-webkit-scrollbar {
                    //     width: 16px;
                    //     background-color: #ffffff;
                    //     border-left: 1px solid black;
                    //     border-right: 1px solid black;
                    // }

                    // &::-webkit-scrollbar-thumb {
                    //     border-radius: 7px;
                    //     background-color: #666666;
                    //     border: 4px solid transparent;
                    //     background-clip: content-box;
                    // }

                    // &::-webkit-scrollbar-button {
                    //     border-style: solid;
                    //     width: 16px;
                    //     height: 10px;
                    // }

                    // &::-webkit-scrollbar-button:vertical:decrement {
                    //     border-width: 0 7px 14px 7px;
                    //     border-color: transparent transparent #9e9e9e transparent;
                    // }

                    // &::-webkit-scrollbar-button:vertical:increment {
                    //     border-width: 14px 7px 0 7px;
                    //     border-color: #9e9e9e transparent transparent transparent;
                    // }
                }
            }

            .rubric-section {
                position: relative;
                //display: inline-block;
                height: 100%;
                border-top: 1px solid var(--primary-blue);
                min-width: calc(var(--fixed-row-width) + 2rem);
                overflow-y: hidden;
                overflow-x: hidden;

                .drag-me {
                    position: absolute;
                    top: 0rem;
                    left: 0rem;
                    width: 16px;
                    height: 100%;
                    background-color: #d9d9d9;
                    border-left: 1px solid black;
                    border-right: 2px solid black;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: e-resize;
                    font-size: 1.25rem;
                    font-weight: 500;
                    color: var(--primary-blue);

                    &.no-drag {
                        cursor: default;
                    }

                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }

                .content-area {
                    position: absolute;
                    top: 0rem;
                    left: 16px;
                    height: 100%;
                    width: calc(100% - 16px);
                    overflow-y: hidden;
                    overflow-x: hidden;

                    &.self-reflection {
                        left: 0rem;
                        width: 100%;
                    }
                }
            }
        }

        .control-box-wrapper {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: right;
            height: 100%;
            background-color: var(--white);
            border-radius: 4px;
            padding-right: 4rem;
            padding-left: 2rem;

            .loading-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                gap: 1rem;
                font-size: 1.5rem;
                font-weight: 600;

            }

            .control-box {
                position: relative;
                display: flex;
                flex-direction: column;
                width: calc(100% - 2rem);
                background-color: var(--white);
                padding: 1.5rem;

                &-list {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 85%;
                }

                &.box-two-column {
                    max-width: 50%;
                }

                &.box-three-column {
                    max-width: 33%;
                }
            }
        }
    }
}


.evidence-dialogue {
    position: relative;
    width: 100%;
    height: auto;
    max-height: 40vh;
    overflow-x: hidden;
    overflow-y: auto;

    .indicator-label {
        position: relative;
        margin-bottom: 1rem;
        width: 100%;
        height: inherit;
        display: flex;
        justify-content: center;
        font-size: 2rem;
        font-weight: 700;

        span {
            padding-left: 0.5rem;
            font-weight: 400;
        }
    }

    .dialogue-section {
        position: relative;
        margin-bottom: 1rem;
        left: 5%;
        width: 90%;
        min-height: 3rem;
        border-bottom: 1px solid black;
        color: black;

        &.empty {
            color: var(--nietDarkBlue);
            text-align: center;
            width: 100%;
            padding-right: 3rem;
            font-size: 1.35rem;
            border-bottom: unset;

        }

        .time-and-title {
            position: relative;
            display: flex;
            margin-bottom: 0.5rem;

            .time-piece {
                position: relative;
                margin-right: 0.5rem;
            }

            .title-piece {
                position: relative;
                margin-left: 0.5rem;
            }
        }

        .tagged-text-evidence-area {
            position: relative;
            margin-bottom: 1rem;
            left: 1%;
            width: 96%;
            //display: inline-flex;

            .tagged-text-evidence {
                margin-right: 0.2rem;
            }
        }

        .tagged-media-area {
            position: relative;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            //background-color: pink;    

            .upload-media-pane {
                position: relative;
                margin-right: 0.5rem;

                &.focused {
                    z-index: 100;
                    background-color: white;
                    border: 1px solid black;
                    border-radius: 4px;
                    border-top-right-radius: 0;
                    border-top-left-radius: 0;
                }

                .tagmode {
                    position: absolute;
                    top: 0rem;
                    left: 0rem;
                    width: 100%;
                    height: 90%;
                    cursor: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBzdHlsZT0iZmlsbDogIzAxM2M1YiIgZD0iTTAgODBWMjI5LjVjMCAxNyA2LjcgMzMuMyAxOC43IDQ1LjNsMTc2IDE3NmMyNSAyNSA2NS41IDI1IDkwLjUgMEw0MTguNyAzMTcuM2MyNS0yNSAyNS02NS41IDAtOTAuNWwtMTc2LTE3NmMtMTItMTItMjguMy0xOC43LTQ1LjMtMTguN0g0OEMyMS41IDMyIDAgNTMuNSAwIDgwem0xMTIgMzJhMzIgMzIgMCAxIDEgMCA2NCAzMiAzMiAwIDEgMSAwLTY0eiIvPjwvc3ZnPg=='), auto;

                    &:hover {
                        border: 1px solid rgba(0, 0, 0, 0.527);
                        border-radius: 4px;
                    }
                }

                .control-panel {
                    position: relative;
                    top: -1rem;
                    height: 2rem;
                    width: 100%;

                    .control-btns {
                        position: relative;
                        float: left;

                        .fa-icon {
                            margin-right: 0.4rem;
                            cursor: pointer;

                            &:hover {
                                color: #006a90;
                                transform: scale(1.2);
                            }
                        }
                    }

                    .tag-abbr {
                        position: relative;
                        float: right;
                        font-size: 0.75rem;
                        font-weight: 700;
                        color: black;
                        line-height: 1.5rem;

                        &.active {
                            background-color: var(--hover-tagged-note-color);
                        }
                    }
                }
            }
        }
    }

}