.detail-table-wrapper {
    margin-top: .5rem;
    height: 100%;
    overflow-y: scroll;

    .__table {
        max-height: 50vh;
        width: 70%;
        display: flow-root;

        .__header {
            display: inline-flex;
        }

        .__head {
            top: 0;
            background: white;
        }

        .__name {
            width: 28%;
            margin-right: 1rem;
        }

        .__flex {
            &.added__date {
                padding-left: 1rem;
            }

            width: 28%;
        }

        .__icon {
            height: 3rem;
            padding-left: 3rem;
            align-items: center;


        }
    }
}

.btn-icon-fa {
    cursor: pointer;
}