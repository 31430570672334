.achievement-table {
    .achievement-weights-table {
        width: 100%;
        border-collapse: collapse;

        .row-header {
            font-weight: bold;
            font-size: 1rem;
        }
    }
    
    .achievement-weights-table th,
    .achievement-weights-table td {
        padding-bottom: .5rem;
        padding-right: 5rem;
        text-align: left;
    }
    
    @media (max-width: 600px) {
        .achievement-weights-table {
            font-size: 12px;
        }
    }
}