.share-dialog {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  min-height: 30rem;
  padding-bottom: 1rem;

  &-image {
    width: 40rem;
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 34rem;
    margin-top: .5rem;
  }

  .modalDescription {
    overflow: auto;
    height: 5rem;
    font-size: 1.24rem;
    line-height: 1.65rem;
    font-weight: 500;
    padding-bottom: 2rem;
  }

  .directions {
    padding-top: 1rem;
    font-size: 1em;
    width: 30rem;
    font-weight: bold;
    font-style: italic;
    color: var(--primary);
  }

  &-loader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 6rem;
    padding-top: 3.5rem;

  }
}

.placement-top {
  position: fixed !important;
  z-index: 100000;
  margin-top: -.5rem;
}