// Core

@import "core";

// Fonts

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";

html {
  font-smooth: always;
  font-size: 12px;
}

body {
  background: #fff !important;
}

body.loading {
  position: fixed;
}

.loader {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 10000;
  background: $nietDarkBlue !important;
  background: radial-gradient(circle, $nietDarkBlue 33%, #00548b 100%) !important;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  /*user-drag: none;*/
}

.body-content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

/*.content {
  position: absolute;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 100%;
}*/

/*************ACED START*************/

.loadingText {
  font-size: 4rem;
  font-weight: bold;
  color: #fff;
  font-family: 'Raleway';
}

.css-0 {
  transform: scale(4);
  margin: 4rem;
}

.app-header-logo {
  position: absolute;
}

.app-header-logo img {
  width: 38rem;
}

.app-header {
  background-color: #fff !important;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 7rem;
  z-index: 1000;
}

.app-header:hover {
  background-color: #fff !important;
}

.app-header--pane.mainNav {
  position: absolute;
  left: 3rem;
}

.app-header--pane {
  position: absolute;
  right: 3rem;
}

.app-header--pane .MuiButton-root {
  padding: 1.25rem;
  min-width: 10rem;
  font-size: 1.5rem;
  font-family: 'Raleway';
  font-weight: bold;
  color: $nietDarkBlue;
  letter-spacing: 0.2rem;
  border-radius: .58rem !important;
}

.customModal .MuiButton-root,
.surveyEdit.MuiButton-root,
.surveyDisplayFooter .MuiButton-root {
  padding: .85rem;
  min-width: 9rem;
  margin: 1rem;
  font-size: 1.25rem;
  font-family: 'Raleway';
  font-weight: bold;
  color: $nietDarkBlue;
  letter-spacing: 0.15rem;
  border-radius: .58rem !important;
}

.app-header--pane.mainNav .MuiButton-root,
.app-sidebar--header .MuiButton-root {
  padding: 1.25rem;
  min-width: 4rem;
  font-weight: bold;
  font-size: 1.5rem;
  font-family: 'Raleway';
  color: $nietDarkBlue;
  letter-spacing: 0.2rem;
  border-radius: .58rem !important;
}

.hamburger:hover,
.hamburger.is-active:hover {
  opacity: 1;
}

.hamburger-box {
  width: 3rem;
  height: 2.1rem;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 3rem;
  height: .3rem;
  border-radius: 0;
}

.hamburger-inner::after {
  bottom: -0.5rem;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: $nietDarkBlue;
}

.hamburger--elastic.collapsed.is-active .hamburger-inner::before,
.hamburger--elastic .hamburger-inner::before {
  top: .75rem;
}

.hamburger--elastic.collapsed.is-active .hamburger-inner::after,
.hamburger--elastic .hamburger-inner::after {
  top: 1.5rem;
}

.hamburger--elastic.is-active .hamburger-inner {
  top: 0.55rem;
}

.hamburger--elastic .hamburger-inner {
  top: 0.2rem;
}

.hamburger--elastic.is-active:not(.collapsed) .hamburger-inner::after,
.hamburger--elastic.active .hamburger-inner::after {
  transform: translate3d(0, -1.5rem, 0) rotate(-270deg);
}

// .app-sidebar {
//   background: #fff;
//   top: 0;
// }

// .app-sidebar--header {
//   height: 7rem;
//   margin-bottom: 1.25rem;
// }

// .singleOption {
//   margin-left: 2.5rem;
//   margin-bottom: 1.9rem;
// }

// .groupOption {
//   margin-left: 2.5rem;
//   margin-bottom: 0rem;
// }

// .groupSubOption {
//   height: 2rem !important;
//   margin-left: 5.25rem;
//   margin-bottom: 0.25rem;
// }

// .groupSubOption.last {
//   margin-bottom: 2.25rem;
// }

// .MuiButton-text {
//   padding: 1rem;
//   font-size: 1.25rem;
//   border-radius: 0.5rem;
// }

// .sidebar-navigation ul li a .menuOptions {
//   font-weight: bold;
//   font-size: 2rem;
// }

// .sidebar-navigation ul li a .menuSubOptions {
//   font-weight: 400;
//   font-size: 1.7rem;
// }

// .sidebar-navigation ul li>a .sidebar-icon-indicator {
//   position: relative;
//   right: 1.5rem;
//   opacity: 1;
// }

// .groupOption svg.MuiSvgIcon-root {
//   fill: $nietDarkBlue;
//   transform: rotate(90deg) scale(3.5);
// }

// .sidebar-navigation ul li>a.active .sidebar-icon-indicator {
//   transform: rotate(0);
// }

// a:not(.MuiButtonBase-root) {
//   color: $nietDarkBlue;
// }

// a:not(.MuiButtonBase-root):hover {
//   color: $nietDarkBlue;
// }

// .sidebar-navigation ul li>a.active,
// .sidebar-navigation ul li>a.active:hover,
// .sidebar-navigation ul li>a.active .sidebar-icon-indicator svg.MuiSvgIcon-root,
// .sidebar-navigation ul li>a.active:hover .sidebar-icon-indicator svg.MuiSvgIcon-root {
//   color: $nietOrange;
//   fill: $nietOrange;
// }

// .sidebar-navigation ul li>a:hover {
//   color: $nietDarkBlue;
//   transform: translateY(-4px);
//   transition: all 0.2s ease-in-out;
// }

.publicBG1::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(0, 84, 139, 0.45),
      rgba(0, 84, 139, 0.45)),
    url("../assets/images/NIET-stock/ACED-hero-image-min.jpg");
  background-color: $nietBlue;
  background-position: center 46.5%;
  background-repeat: no-repeat;
  background-size: cover;
  filter: contrast(1.2);
  opacity: 0.3;
}

.publicBG1 {
  position: relative;
  height: 100%;
  padding: 5rem 15rem 5.7rem 15rem;
  background-color: $nietDarkBlue;
}

.publicBG1.secure {
  padding: 8rem 0 3rem 0;
}

.publicBG1.secure .publicText.secure {
  padding: 0 15.5rem;
  margin-bottom: 4rem;
}

.publicHeadLine {
  position: relative;
  font-size: 4rem;
  margin-top: 1.5rem;
}

.publicHeadLine.secure {
  margin-top: 0rem;
}

.publicText {
  position: relative;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  margin: 0 auto 4rem auto;
}

.publicText.secure {
  padding: 0 4rem;
}

.publicBG1 .MuiButton-root {
  color: #fff;
  background: $nietOrange;
  padding: 1.2rem 3rem;
  min-width: 13rem;
  font-size: 1.65rem;
  font-family: 'Raleway';
  letter-spacing: 0.2rem;
  border-radius: .58rem !important;
}

.publicBG1 .MuiButton-root:hover,
.publicBG1 .MuiButton-root:active {
  background: $nietYellow !important;
}

.publicBody {
  width: 100%;
}

.publicBody h1,
.securedAbout h1 {
  color: $nietDarkBlue;
  font-size: 3rem;
  margin-bottom: 2.5rem;
}

.publicBG {
  position: relative;
  width: 50%;
  height: 39rem;
}

.publicBG.BG2::before,
.publicBG.BG3::before,
.publicBG.BG4::before,
.publicBG.BG5::before,
.publicBG.BG6::before,
.publicBG.BG7::before,
.publicBG.BG8::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 120%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.publicBG.BG2::before {
  background-image: url("../assets/images/NIET-stock/Gadsden-edited-min.jpg");
}

.publicBG.BG3::before {
  background-image: url("../assets/images/NIET-stock/Tangipahoa.jpg");
}

.publicBG.BG4::before {
  background-image: url("../assets/images/NIET-stock/Landry.jpg");
}

.publicBG.BG5::before {
  background-image: url("../assets/images/NIET-stock/South_Haven.jpg");
  //height: 101%;
  background-position: bottom;
  /*Remove this line if image changes in the future.*/
}

.publicBG.BG6::before {
  background-image: url("../assets/images/NIET-stock/Citizenship.jpg");
}

.publicBG.BG7::before {
  background-image: url("../assets/images/NIET-stock/Maximizing.jpg");
}

.publicBG.BG8::before {
  background-image: url("../assets/images/NIET-stock/Principal.jpg");
}

.cardRtSide {
  padding: 3rem;
  width: 50%;
}

.publicSlider {
  position: relative;
  width: 100%;
  height: fit-content;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  overflow: hidden;
}

.publicSlider .slick-slide {
  height: fit-content !important;
}

.publicSlider:hover {
  cursor: grab;
}

.publicSlider:active {
  cursor: grabbing;
}

.publicSlider .MuiContainer-root {
  padding: 0;
}

a:not(.MuiButtonBase-root) {
  font-weight: bold;
}

a:active,
a:focus,
a:hover,
.MuiLink-underlineHover:hover {
  text-decoration: none !important;
}

.mb-spacing-6-x2 {
  margin: 0;
}

.publicBodyCard {
  position: relative;
  color: $nietDarkBlue;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.publicBodyCard .publicText {
  width: 100%;
  margin: 0 0 4rem 0;
}

.publicBody .MuiButton-root {
  color: #fff;
  background: $nietOrange;
  padding: 1.2rem 1rem;
  min-width: 14rem;
  font-size: 1.65rem;
  font-family: 'Raleway';
  letter-spacing: 0.2rem;
  border-radius: .58rem !important;
}

.publicBody .MuiButton-root:hover,
.publicBody .MuiButton-root:active {
  background: $nietYellow !important;
  color: #fff;
}

.publicBody .slick-dots {
  bottom: 0;
  right: 0;
  position: absolute;
  left: unset;
  width: 100%;
  text-align: right;
  padding-right: 4rem;
  padding-bottom: 2.5%;

  li {
    button {
      height: .8rem;
      width: .8rem;
      border-radius: 10rem;
      background: $gray-600;
    }

    &.slick-active {
      button {
        width: .8rem;
        background: $primary;
      }
    }
  }
}

.slick-slider {
  max-width: 100%;
}

.slick-slide>div {
  padding-right: 0;
}

.MuiContainer-maxWidthLg {
  max-width: fit-content !important;
}

.securedAbout {
  background-color: $nietLightUI;
  padding: 5rem 0;
}

.secureModuleCardsContainer {
  padding: 3rem;
}

.secureModuleCards {
  padding: 1rem;
}

.secureModuleCards .MuiCard-root {
  padding: 3rem 1rem;
  color: $nietDarkBlue;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
}

.secureModuleCards .MuiButton-root {
  padding: 1.25rem;
  margin: 1.25rem 0 .75rem 0;
  width: 60%;
}

.secureModuleCards .MuiButton-root:hover,
.secureModuleCards .MuiButton-root:active {
  color: #fff;
}

.secureModuleIcons {
  height: 6rem;
}

.secureHeadLine {
  margin: 2.5rem 0 1.25rem 0;
  font-size: 1.85rem;
  letter-spacing: .2rem;
  font-weight: bold;
}

.secureModuleCards .publicText {
  padding: 0 1rem;
  font-size: 1.24rem;
  line-height: 1.65rem;
}

.secureModuleCards .publicText.secureCard {
  margin: 0 auto 2.5rem auto;
}

.securedAbout .publicBodyCard {
  padding: 2.25rem 8.5rem 4rem 7rem;
  color: $nietDarkBlue;
}

.securedAbout .publicBodyCard .publicText {
  width: 100%;
  margin: 0;
}

.orangeText {
  color: $nietOrange;
}

.borderRt {
  border-right: solid 1px $nietDarkBlue;
  border-bottom: none;
}

.contentFilter {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  min-height: 60rem;
  width: 20rem;
  z-index: 1;
  padding: 8.4rem 2rem 2rem 2rem;
  background-color: #fff; //$nietUtilityGray;

  input {
    padding: .25rem;
    border-radius: 0px;
    border: 0px;
    border-bottom: 2px solid $nietDarkBlue;
  }

  .filterTitle {
    font-size: 1.75rem;
    font-weight: bold;
    color: $nietDarkBlue;
    text-align: left;
    padding-bottom: 1rem;
  }

  .title-header {
    margin-bottom: .25rem;
  }

  .title {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    margin-bottom: 0.25rem;
    top: 0rem;
    left: 0rem;
    font-weight: bold;
    font-size: 1.25rem;
    padding-top: 1.1rem;
  }

  .filterSubTitle {
    font-size: 1.25rem;
    font-weight: bold;
    color: $nietDarkBlue;
    text-align: left;
  }

  &.surveyFilter {
    padding: 6.25rem 3rem 3rem;

    span.btn-wrapper--label {
      width: 6rem;
    }

  }

  .filterControl {
    margin: .25rem 0;
    display: flex;
    flex-direction: column;
    justify-content: left;
    position: relative;

    .img-wrapper {
      position: absolute;
      bottom: .5rem;
      right: -2rem;
      cursor: pointer;
    }

    .rs-picker-toggle-wrapper {
      width: 100%;
      max-width: 16rem;
    }

    .filter-control-input {
      width: 14.5rem;
    }

    &.btn-clear {
      width: 75%;
      padding-top: 1.25rem;
    }

    .MuiFormControlLabel-root {
      margin: 0 !important;
    }

    .MuiFormControlLabel-root .filterDatePicker {
      margin-top: 0;
      width: 100%;
    }
  }

  .filterControl.layoutSwitch {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    width: 12rem;
    margin-bottom: 4rem;
  }
}

.MuiSwitch-root {
  height: 32px !important;
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track,
.MuiSwitch-colorSecondary.Mui-checked {
  background-color: rgba(244, 126, 58, .8) !important;
}

.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
  background: $nietOrange;
  background: linear-gradient(180deg, $nietOrange, #e76b23);
}

.switchIcon {
  font-size: 2rem;
  color: $nietDarkBlue;
}

.MuiFormControlLabel-root .MuiSwitch-root {
  margin: 0;
}

.searchWidth {
  width: 14rem !important;
}

.searchWidthFilter {
  width: 16.6rem !important;
}

body .MuiInputBase-root,
body .MuiTablePagination-root {
  color: $nietDarkBlue;
}

body .MuiInput-underline:before {
  border-bottom-color: $nietDarkBlue;
}

body .MuiInput-underline:after {
  border-bottom-color: $nietOrange;
}

body .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid $nietOrange;
}

body .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid $nietOrange;
}

body .filterControl svg.MuiSvgIcon-root,
body .filterDatePicker svg.MuiSvgIcon-root {
  fill: $nietDarkBlue;
}

body .filterControl .Mui-checked svg.MuiSvgIcon-root {
  fill: $nietOrange;
}

span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  font-family: 'Raleway' !important;
  color: $nietDarkBlue !important;
  font-weight: 500 !important;
}

span.Mui-checked+span {
  color: $nietOrange !important;
}

.MuiFormControlLabel-label.Mui-disabled {
  opacity: 0.38;
}

.rs-picker-toggle-wrapper {
  margin-top: .25rem;
}

.rs-picker-default .rs-picker-toggle.rs-btn,
input.rs-picker-search-bar-input,
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  border-bottom: 1px solid $nietDarkBlue;
  border-top: 1px solid $nietDarkBlue;
  border-right: 1px solid $nietDarkBlue;
  border-left: 1px solid $nietDarkBlue;
  border-radius: .5rem;
  padding-left: 1rem;
  color: $nietDarkBlue;
}

.MuiInput-underline:before {
  border-bottom: 2px solid $nietDarkBlue !important;
}

.rs-picker-menu .rs-picker-search-bar {
  padding: .75rem;
}

.rs-picker-menu .rs-picker-search-bar:after {
  top: 20px;
  color: $nietDarkBlue;
}


.rs-picker-menu .rs-check-tree-node>.rs-check-tree-node-label .rs-check-tree-node-text-wrapper,
.rs-tree-node-label-content {
  color: $nietDarkBlue;
}

.rs-tree-node-drag-over,
.rs-tree-node>.rs-tree-node-label>.rs-tree-node-label-content:focus,
.rs-tree-node>.rs-tree-node-label>.rs-tree-node-label-content:hover,
.rs-tree-node:not(.rs-tree-node-disabled):focus>.rs-tree-node-label>.rs-tree-node-label-content,
.rs-tree-node:not(.rs-tree-node-disabled)>.rs-tree-node-label:focus>.rs-tree-node-label-content {
  background-color: #fff;
}

.rs-tree-node-active>.rs-tree-node-label>.rs-tree-node-label-content {
  color: $nietDarkBlue;
  background-color: #fff;
}

/*fix weird line flicker*/
.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active,
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus {
  border-bottom: solid 1px $nietDarkBlue;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover {
  border-bottom: solid 1px $nietDarkBlue;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: $nietDarkBlue;
}

.rs-picker-value-count {
  background: $nietOrange;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
  border-color: $nietOrange;
  background-color: $nietOrange;
}

.rs-checkbox-wrapper .rs-checkbox-inner:before,
.rs-checkbox-checker:hover .rs-checkbox-wrapper .rs-checkbox-inner:before {
  border: 2px solid $nietDarkBlue;
}

.rs-checkbox-checked .rs-checkbox-wrapper [type=checkbox]:focus~.rs-checkbox-inner:before,
:hover.rs-checkbox-wrapper [type=checkbox]:focus~.rs-checkbox-inner:before {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.rs-checkbox-checked .rs-checkbox-checker:hover .rs-checkbox-wrapper .rs-checkbox-inner:before,
.rs-checkbox-indeterminate .rs-checkbox-checker:hover .rs-checkbox-wrapper .rs-checkbox-inner:before {
  border-color: $nietOrange;
}

.rs-checkbox-wrapper:before {
  border-color: $nietOrange;
}

.rs-checkbox-checked .rs-check-tree-node-text-wrapper {
  color: $nietOrange !important;
  font-weight: 400;
}

.rs-check-item-focus,
.rs-check-item:not(.rs-checkbox-disabled):hover,
.rs-check-tree-node>.rs-check-tree-node-label:focus .rs-check-item .rs-checkbox-checker>label {
  background: none;
}

.rs-check-tree,
.rs-picker-menu {
  background-color: #fff; //$nietUtilityGray;
  z-index: 10000;
}

.rs-check-tree-node>.rs-check-tree-node-label .rs-check-item .rs-checkbox-checker>label:before {
  background-color: #fff //$nietUtilityGray;
}

.filterControl .MuiButton-root {
  color: #fff;
  background: #f47e3a;
  padding: 1.0rem 2rem;
  min-width: 10rem;
  font-size: 1.25rem;
  font-family: 'Raleway';
  letter-spacing: .2rem;
  border-radius: .58rem !important;
  margin-top: 1rem;
}

.filterControl .MuiButton-root:hover,
.filterControl .MuiButton-root:active {
  background: $nietYellow !important;
}

.app-content--inner__wrapper {
  position: relative;
  overflow-y: auto;
  height: 100%;
  margin: 0 0 6.35rem 0;
}

.app-content--inner__wrapper.homePage {
  margin: 0;
}

.contentDisplay {
  position: relative;
  width: calc(100% - 22rem);
  margin: 1.75rem 0 0 20rem;
  padding-bottom: 2rem;

  &-list {
    position: relative;
    padding-bottom: 2rem;
    height: calc(100% + var(--footer-height) - 6rem);
    margin-left: 17rem;
    width: unset;
  }

  .btn-icon {
    cursor: pointer;

    &.disabled {
      cursor: unset !important;

      path {
        fill: var(--darkgray-border);
      }
    }

    &-fa {
      cursor: pointer;
      margin-top: .25rem;
      height: 1.4rem;
      margin-right: .1rem;
    }
  }
}

.contentDisplay svg.MuiSvgIcon-root {
  fill: $nietDarkBlue;
  font-size: 1.75rem;
}

.contentDisplay svg.MuiTableSortLabel-icon {
  fill: #fff;
}

.categoryWrapper {
  margin-bottom: 2rem;
}

.categoryTitle {
  font-size: 1.75rem;
  font-weight: bold;
  color: $nietDarkBlue;
}

.itemTitle {
  position: absolute;
  width: 100%;
  padding: 1rem 1.2rem;
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
  overflow: hidden;
  /*white-space: nowrap;
  text-overflow: ellipsis;*/
  background: $nietDarkBlue;
  background: -moz-linear-gradient(to bottom, rgba(0, 60, 91, 1) 0%, rgba(0, 60, 91, 0.85) 50%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(to bottom, rgba(0, 60, 91, 1) 0%, rgba(0, 60, 91, 0.85) 50%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 60, 91, 1) 0%, rgba(0, 60, 91, 0.85) 50%, rgba(255, 255, 255, 0) 100%);
  z-index: 3;
  cursor: pointer;

  &:hover {
    color: var(--nietLightUI);
  }

}

.itemTime {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 600;
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  background: rgba(0, 0, 0, 0.70);
  z-index: 4;
}

.content__close svg.MuiSvgIcon-root {
  fill: white;
  font-size: 3rem;
}

.MuiButtonBase-root.content__close {
  width: 4rem;
  height: 4rem;
  background-color: $nietOrange;
}

.MuiButtonBase-root.content__close:hover,
.MuiButtonBase-root.content__close:active {
  background-color: $nietYellow;
}

.tableThumb {
  width: 75px;

  th {
    text-align: center;
  }
}

.actionButton button {
  background: none !important;
}

.actionButton button:focus {
  outline: none !important;
}

.actionButton .deleteIcon {
  margin-left: -0.4rem;
}

.MuiTableRow-root:nth-child(odd) {
  background: $nietLightUI;
}

.MuiTableRow-root:nth-child(even) {
  background: $nietDarkUI;
}

.contentViewerContainer {
  margin-top: 0;
  padding: 1rem 4rem 2rem 4rem;
}

.contentViewerHeader {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  font-size: 1.85rem;
  letter-spacing: .2rem;
  color: $nietDarkBlue;
  text-transform: uppercase;
  justify-content: space-between;

  h2 {
    font-weight: 700;
  }

  &-action {
    margin-top: 1rem;
  }
}

.contentViewerInfo {
  font-size: .9rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: $nietDarkBlue;
}

.contentViewerRunningTime {
  padding-top: 0.25rem;
}

button.viewerActionButtons {
  background: none;
  border-radius: .58rem !important;
}

.viewerActionButtons svg.MuiSvgIcon-root {
  fill: $nietDarkBlue;
  font-size: 2rem;
}

.viewerActionButtons.close svg.MuiSvgIcon-root {
  font-size: 3rem !important;
}

.contentViewerBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2rem 0;
  align-items: center;
  justify-content: center;

  .buttonFrame {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 1rem;
    width: 100%;
  }
}

.learningModuleFrame {
  width: 99rem;
  height: 42rem;
  border: none;
}

.resourceFrame {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding-bottom: .25rem;

  img {
    width: 100%;
    max-width: 75rem;
  }
}

.contentViewerAssociatedContent {
  max-width: 44rem;
  padding: 3rem 3rem 0 3rem;
  display: inline-block;
}

.innerAssociatedContent {
  position: relative;
  display: inline-block;
  width: 100%;

  .dl-btn {
    position: absolute;
    top: 0;
    right: .5rem;
  }

  .associated-content-buttons {
    display: flex;
    justify-content: right;
    margin-right: 5px;
    margin-top: 2rem;
  }

}

.contentHeader {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  justify-content: left;
  cursor: pointer;
  padding-top: .4rem;
  padding-left: .4rem;

  &:hover {
    //border: 1px solid var(--primary);
    box-shadow: 1px 1px 5px 1px var(--primary);
    margin-top: 1px;
    margin-left: 1px;

  }
}

.contentDetails {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: top;
  align-items: top;
  width: 14rem;
}

.contentHeader img {
  width: 6rem;
  margin: 0 1rem .5rem 0;
}

.contentSubTitle {
  font-size: 1.1rem;
  font-weight: bold;
  color: $nietDarkBlue;
  text-align: top;
  padding-bottom: .2rem;
}

.contentIcons {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
  font-family: 'Raleway';
  font-weight: 700;
  letter-spacing: .2rem;
  color: #fff;
}

.contentViewerBody .contentIcons,
.contentViewerHeader .contentIcons,
.innerAssociatedContent .contentIcons,
.filterControl .contentIcons {
  color: $nietDarkBlue;
}

.customModal {
  top: 18%;
  left: 31%;
  max-height: 32rem;
  width: 60rem;
  margin: 0;
}

.customModal.contactUs {
  top: 15%;
  left: 34%;
  width: 52rem;
}

.customModal.addSurveyModal {
  top: 21%;
  left: 36.5%;
  width: 42rem;
}

.customModal .modalHeader {
  font-size: 1.85rem;
  letter-spacing: .2rem;
  font-weight: 700;
  color: $nietDarkBlue;
  text-transform: uppercase;
}

.customModal .subHeader {
  font-size: 1rem;
  font-weight: 700;
  color: $nietDarkBlue;
}

.customModal .modalFooter {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  width: 100%;
}

.modalBody .filterSubTitle {
  font-size: 1.25rem;
  font-weight: bold;
  color: $nietDarkBlue;
}

.contactElement {
  -webkit-flex-direction: column;
  flex-direction: column;
}

.contactElement .MuiFormControlLabel-root {
  margin: 0;
  width: 100%;
}

.contactElement .MuiTextField-root,
.contactRequestOption,
.surveyWidth {
  width: 24rem;
}

.contactElement .MuiTextField-root.surveyDatePicker {
  width: 13rem;
}

.contactElement .MuiTextField-root.MuiFormControl-fullWidth {
  width: 100%;
}

.contactRequestOption .MuiSvgIcon-root {
  fill: $nietDarkBlue;
}

.content404 {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 9rem 30rem 0 30rem;
}

.ACEDLogoType {
  width: 37rem;
  margin: auto;
}

.surveyDisplay {
  padding: 4rem;
}

.surveyDisplayPrint {
  padding: 1rem;
}

.surveyDisplayHeader {
  padding: 2rem 0 1rem 0;
  width: 70%;
  min-width: 1000px;
  color: $nietDarkBlue;
}

.surveyDisplayBody .MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.surveyDisplayBody .MuiTableCell-root.MuiTableCell-body {
  font-size: 1rem;
}

.contentDisplay td.MuiTableCell-body {
  font-size: 1rem;
  font-weight: 500;
}

.surveyLeadTitle {
  font-size: 1.75rem;
  font-weight: bold;
  color: $nietDarkBlue;
}

.surveyTitle {
  font-size: 1.25rem;
  font-weight: bold;
  color: $nietDarkBlue;
}

td.MuiTableCell-root.MuiTableCell-body.surveyAnswerCell {
  width: 58.33%;
}

.surveyAnswers {
  width: 70% !important;
  min-width: 1000px;
}

.surveyComments {
  padding: 2rem 1rem 1rem 0;
  width: 70% !important;
  min-width: 1000px;
  color: $nietDarkBlue;
}

.surveyAnswers .MuiTableCell-head {
  background: $nietDarkBlue;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
}

.surveyAnswerCell .MuiInput-formControl {
  width: 100%;
}

.surveyComments .MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.rs-radio-checked .rs-radio-wrapper .rs-radio-inner:before,
.rs-radio-wrapper:before,
.rs-radio-checker:hover .rs-radio-wrapper .rs-radio-inner:before {
  border-color: $nietOrange;
}

.rs-radio-checked .rs-radio-wrapper .rs-radio-inner:before {
  background-color: $nietOrange;
}

.rs-radio-checked .rs-radio-wrapper [type=radio]:focus~.rs-radio-inner:before,
:hover.rs-radio-wrapper [type=radio]:focus~.rs-radio-inner:before {
  -webkit-box-shadow: 0 0 0 2px rgba(244, 126, 58, 0.3);
  box-shadow: 0 0 0 2px rgba(244, 126, 58, 0.3);
  ;
}

.surveyDisplayFooter {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  width: 70%;
  padding: 2rem 0 3rem 0;
}

.surveyEditWrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

/*.surveyEdit.MuiButton-root, .surveyDisplayFooter .MuiButton-root  {
  padding: 1.25rem;
  min-width: 10rem;
  font-weight: bold;
  font-size: 1.5rem;
  font-family: 'Raleway';
  color: $nietDarkBlue;
  letter-spacing: 0.2rem;
  border-radius: .58rem !important;
  transform: scale(.75);
}

.surveyEdit.MuiButton-root:hover, .surveyDisplayFooter .MuiButton-root:hover {
  transform: translateY(-2px) scale(.75) !important;
} */

/************ACED END**************/

.skMascot {
  width: 35vw;
  position: relative;
  top: -2vw;
}

.killScroll {
  overflow-y: hidden;
}

.skMascotGen {
  width: 72rem;
  position: absolute;
  top: -58rem;
  left: -85rem;
  -webkit-transform: rotateZ(16deg);
  transform: rotateZ(16deg);
  z-index: 0;
}

.skEventZ {
  z-index: 1000;
  position: relative;
}

.skLogoType {
  width: 35vw;
  position: relative;
  top: -5.5vw;
}

a.text-first:hover,
a.text-first:focus {
  color: #1d2b72 !important;
  text-decoration: none;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.truncate {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-nav-menu>ul>li .skLangsMenu.submenu-dropdown--sm {
  width: 220px;
}

.header-nav-menu>ul>li .submenu-dropdown--sm {
  width: 211px;
}

.noBullet {
  list-style-type: none;
  padding: 8px 0 !important;
  margin: 0;
}

.MuiList-root.nav-pills-rounded .MuiListItem-root.MuiListItem-button {
  border-radius: .5rem;
}

.skLangs {
  justify-content: flex-end;
  width: 100%;
}

.skLangs li {
  margin-right: 0 !important;
}

.skLangsMenu {
  right: 0;
  left: auto !important;
}

.skLangsMenu .MuiListItem-button:hover {
  background: rgba(0, 0, 0, .05) !important;
}

.skMainMenu {
  box-shadow: $box-shadow-sm-dark;
}

.live {
  color: #087144;
}

.skMainMenuFooter ul {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.btnMobile {
  padding: 8px 24px !important;
  margin: 2px 0 !important;
}

.skCardPadding {
  padding: .5rem 0;
}

.header-nav-wrapper .nav-collapsed-wrapper {
  right: 4rem !important;
  width: 13.3rem !important;
  left: auto !important;
}

.btnFomo {
  background-color: $skgold !important;
  color: $first !important;
}

.errorEmail {
  color: red;
}

.btnBuyNow {
  margin: -0.1rem 0 !important;
  width: 2.25rem;
  transform: translateZ(0);
}

.btnBuyNowFooter {
  margin: -0.1rem 0 !important;
  width: 2rem;
  transform: translateZ(0);
}

.btnLaunchIcon {
  margin: -0.1rem 0 -0.1rem 0.5rem !important;
}

.ACEDGifXY {
  position: absolute;
  right: -1rem;
  bottom: -0.5rem;
  width: 30rem;
}

.tokenomicsMargin {
  margin: 0 0 3rem 0;
}

.tokenomicsWidth {
  width: 100%;
}

.tokenomicsZ {
  position: relative;
  z-index: 8;
  margin-top: -2rem;
}

.o-50 {
  opacity: .5;
}

.o-75 {
  opacity: .75;
}

.tokenIcon {
  margin: 0 1rem 0 0 !important
}

.singleDigit {
  width: 2rem;
  text-align: center;
}

.coreMargin {
  margin: 3rem 0 6rem 0;
}

.skEventXY {
  margin: 5rem 0 0 0 !important;
}

.skEventPadding {
  padding: 2rem 0;
}

.skEventStatsContainer {
  width: 100%;
  position: relative;
  text-align: center;
}

.eventMainDivider {
  margin-right: auto;
  margin-left: auto;
}

.skClock {
  flex-wrap: nowrap !important;
  text-align: center;
  max-width: 25rem !important;
  margin: 1rem auto !important;
}

.skClock .MuiGrid-item {
  width: 6rem;
}

.skSwirveWidth {
  width: 100%;
}

.skEventWrapperHi {
  top: -1rem;
}

.skEventWrapperLo {
  bottom: -1rem;
}

.skTimeline {
  -webkit-justify-content: center;
  justify-content: center;
}

.miniSKLogo {
  width: 1.5rem;
  transform: translateZ(0);
}

.skDotBorder {
  border: none !important;
}

.skTimelineEnd {
  text-align: center;
  width: 100% !important;
}

.mb-7.MuiTimelineItem-missingOppositeContent:before {
  display: none;
}

.skContentMargin {
  margin-bottom: 3rem;
}

.footerFlip {
  transform: rotatez(180deg);
  /* W3C */
  -webkit-transform: rotatez(180deg);
  /* Safari & Chrome */
  -moz-transform: rotatez(180deg);
  /* Firefox */
  -ms-transform: rotatez(180deg);
  /* Internet Explorer */
  -o-transform: rotatez(180deg);
  /* Opera */
  top: -0.5rem;
}

.footerOverlap {
  margin-top: -8rem;
  z-index: 3;
  box-shadow: 0px 9px 0px -2px rgba(255, 255, 255, 1) inset;
  -webkit-box-shadow: 0px 9px 0px -2px rgba(255, 255, 255, 1) inset;
  -moz-box-shadow: 0px 9px 0px -2px rgba(255, 255, 255, 1) inset;
}

.footerLogo {
  width: 16rem;
  margin-top: 1rem;
}

.tiktokIcon:hover svg path {
  fill: white;
}

.tableTitle {
  padding: 0;
  margin-top: -1.25rem;
}

.tableCellSpan {
  font-weight: bold;
  font-size: 1rem;
  white-space: nowrap;
  color: #fff;
}

.skSavedIcon {
  padding: 0 !important;
}

.fullTableMarginTop {
  margin-top: -7.5rem;
}

.transSearch {
  max-width: 50rem;
}

.dwTitle {
  margin-top: -1.25rem !important;
}

.btnWallet {
  height: 3rem;
  width: 3rem;
  margin-top: .5rem !important;
}

.tableSelectContainerFooter {
  position: absolute;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  z-index: 1;
  width: 20rem;
}

.dynamicPadding {
  padding: 1rem;
}

.skIncrease {
  color: $second;
  background-color: rgba(73, 226, 135, .25);
  font-size: .75rem;
  vertical-align: middle;
}

.skDecrease {
  color: $red;
  background-color: rgba(255, 0, 0, .25);
  font-size: .75rem;
  vertical-align: middle;
}

.skOverviewChart {
  height: 25rem;
  margin: .5rem;
}

/*
@media only screen and (min-width: 1611px) and (max-width: 1775px) {
  .publicBG.BG5::before {
    height: 105%;
    background-position: bottom;
  }
}

@media only screen and (min-width: 1475px) and (max-width: 1610px) {
  .publicBG.BG5::before {
    height: 106%;
    background-position: bottom;
  }
}

@media only screen and (min-width: 1380px) and (max-width: 1474px) {
  .publicBG.BG5::before {
    height: 114%;
    background-position: bottom;
  }
}

@media only screen and (min-width: 1221px) and (max-width: 1304px) {
  .publicBG.BG5::before {
    height: 124%;
    background-position: bottom;
  }
}

@media only screen and (min-width: 1120px) and (max-width: 1220px) {
  .publicBG.BG5::before {
    height: 130%;
    background-position: bottom;
  }
}

@media only screen and (min-width: 1083px) and (max-width: 1120px) {
  .publicBG.BG5::before {
    height: 140%;
    background-position: bottom;
  }
}

@media only screen and (min-width: 1066px) and (max-width: 1082px) {
  .publicBG.BG5::before {
    height: 101%;
    background-position: bottom;
  }
}*/

@media only screen and (min-width: 1100px) and (max-width: 1379px) {
  .tokenomicsMargin {
    margin: -1rem 0 1rem 0;
  }

  .tokenomicsWidth {
    width: 67%;
    margin: auto !important;
  }

  .skEventPadding {
    padding: 5rem 0;
  }

  .fullTableMarginTop {
    margin-top: 0;
  }

  .dynamicPadding {
    padding: 0 0 1rem 0;
  }

  .card-tr-actions {
    right: 0 !important;
  }

  .publicBG.BG2::before,
  .publicBG.BG3::before,
  .publicBG.BG4::before,
  .publicBG.BG5::before,
  .publicBG.BG6::before,
  .publicBG.BG7::before,
  .publicBG.BG8::before {
    height: 130%;
  }

  .publicBG1.secure .publicText.secure {
    padding: 0 4rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1099px) {
  .skMascotGen {
    display: none;
  }

  .skHeroLt {
    width: 66.666666%;
    padding-left: 3rem !important;
  }

  .skHeroRt {
    width: 33.3333333%;
  }

  .ACEDGifXY {
    right: 3rem;
    bottom: 0;
    width: 17rem;
    margin: 0rem !important;
  }

  .skStats {
    margin: 0 5rem !important;
  }

  .tokenomicsZ {
    margin-top: -1rem;
  }

  .tokenomicsMargin {
    margin: -1.5rem 0 1rem 0;
  }

  .skEventPadding {
    padding: 5rem 0;
  }

  .skEventLt {
    width: 50% !important;
    padding-left: 3rem !important;
  }

  .skEventRt {
    width: 50% !important;
  }

  .mobileMargin {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .fullTableMarginTop {
    margin-top: 0;
  }

  .dynamicPadding {
    padding: 0 0 1rem 0;
  }

  .card-tr-actions {
    right: 0 !important;
  }

  .publicBodyCard {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .cardRtSide,
  .publicBG {
    width: 100%;
  }

  .publicBG.BG2::before,
  .publicBG.BG3::before,
  .publicBG.BG4::before,
  .publicBG.BG5::before,
  .publicBG.BG6::before,
  .publicBG.BG7::before,
  .publicBG.BG8::before {
    height: 100%;
  }

  .publicBG1.secure .publicText.secure {
    padding: 0 4rem;
  }

  .securedAbout {
    padding: 0 5rem;
  }

  .securedAbout .publicBodyCard {
    padding: 3.5rem 2.5rem;
  }

  .borderRt {
    border-right: none;
    border-bottom: solid 1px $nietDarkBlue;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skMascotGen {
    display: none;
  }

  .ACEDGifXY {
    position: inherit;
    right: auto;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 25rem;
  }

  .skStats {
    margin: 0 5rem !important;
  }

  .tokenomicsZ {
    margin-top: -1rem;
  }

  .tokenomicsMargin {
    margin: -1rem 0 1rem 0;
  }

  .skEventPadding {
    padding: 8rem 0;
  }

  .skEventXY {
    margin: 5rem auto 0 auto !important;
    width: 30rem !important;
  }

  .mobileMargin {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .fullTableMarginTop {
    margin-top: 0;
  }

  .dynamicPadding {
    padding: 0 0 1rem 0;
  }

  .card-tr-actions {
    right: 0 !important;
  }

  .publicBodyCard {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .cardRtSide,
  .publicBG {
    width: 100%;
  }

  .publicBG.BG2::before,
  .publicBG.BG3::before,
  .publicBG.BG4::before,
  .publicBG.BG5::before,
  .publicBG.BG6::before,
  .publicBG.BG7::before,
  .publicBG.BG8::before {
    height: 100%;
  }

  .publicBG.BG5::before {
    height: 100%;
    background-position: bottom;
  }

  .publicBG1.secure .publicText.secure {
    padding: 0 4rem;
  }

  .securedAbout {
    padding: 0 5rem;
  }

  .securedAbout .publicBodyCard {
    padding: 3.5rem 2.5rem;
  }

  .borderRt {
    border-right: none;
    border-bottom: solid 1px $nietDarkBlue;
  }

  .publicBody .slick-dots {
    padding-bottom: 4.5%;
  }
}

@media only screen and (max-width: 767px) {
  .skMascotGen {
    display: none;
  }

  .ACEDGifXY {
    position: inherit;
    right: auto;
    bottom: auto;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 21rem;
  }

  .btnLaunchIcon {
    display: none;
  }

  .skStats {
    margin: 0 2.5rem !important;
  }

  .tokenomicsZ {
    margin-top: -.5rem;
  }

  .skEventPadding {
    padding: 8rem 0;
  }

  .skEventXY {
    margin: 5rem auto 0 auto !important;
    width: 30rem !important;
  }

  .skEventXY .MuiCard-root {
    padding: 2rem !important;
  }

  .tokenomicsMargin {
    margin: -1rem 0 1rem 0;
  }

  .fullTableMarginTop {
    margin-top: 0;
  }

  .dynamicPadding {
    padding: 0 0 1rem 0;
  }

  .card-tr-actions {
    right: 0 !important;
  }

  .publicBodyCard {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .cardRtSide,
  .publicBG {
    width: 100%;
  }

  .publicBG.BG2::before,
  .publicBG.BG3::before,
  .publicBG.BG4::before,
  .publicBG.BG5::before,
  .publicBG.BG6::before,
  .publicBG.BG7::before,
  .publicBG.BG8::before {
    height: 100%;
  }

  .publicBG.BG5::before {
    height: 100%;
    background-position: bottom;
  }

  .publicBG1.secure .publicText.secure {
    padding: 0 4rem;
  }

  .securedAbout {
    padding: 0 5rem;
  }

  .securedAbout .publicBodyCard {
    padding: 3.5rem 2.5rem;
  }

  .borderRt {
    border-right: none;
    border-bottom: solid 1px $nietDarkBlue;
  }

  .publicBody .slick-dots {
    padding-bottom: 5.5%;
  }
}

@media only screen and (max-width: 524px) {
  .skMascotGen {
    display: none;
  }

  .skEventXY {
    margin: 5rem auto 0 auto !important;
    width: 20rem !important;
  }

  .shortClock {
    display: none;
  }

  .fullTableMarginTop {
    margin-top: 0;
  }

  .dynamicPadding {
    padding: 0 0 1rem 0;
  }

  .card-tr-actions {
    right: 0 !important;
  }

  .publicBodyCard {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .cardRtSide,
  .publicBG {
    width: 100%;
  }

  .publicBG.BG2::before,
  .publicBG.BG3::before,
  .publicBG.BG4::before,
  .publicBG.BG5::before,
  .publicBG.BG6::before,
  .publicBG.BG7::before,
  .publicBG.BG8::before {
    height: 100%;
  }

  .publicBG.BG5::before {
    height: 100%;
    background-position: bottom;
  }

  .publicBG1.secure .publicText.secure {
    padding: 0 4rem;
  }

  .securedAbout {
    padding: 0 5rem;
  }

  .securedAbout .publicBodyCard {
    padding: 3.5rem 2.5rem;
  }

  .borderRt {
    border-right: none;
    border-bottom: solid 1px $nietDarkBlue;
  }

  .publicBody .slick-dots {
    padding-bottom: 5.5%;
  }
}