.show-details-button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 300ms ease 100ms;
  border: 0;
  outline: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 60, 91, 0.35);

  span {
    display: block;
    width: 2rem;
    margin: 0 auto;
    color: white;
  }
}

.show-details-button:active, .show-details-button:focus {
  outline: none;
}