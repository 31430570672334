.init {
    &__blockUi {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: var(--white);
        z-index: 999999;
    }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        padding: 4rem;
        gap: 2rem;
    }
}

.four0four {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}