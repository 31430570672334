.print-modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.8em 1em;
  gap: 10px;

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto;
  margin-top: 0px !important;
  border: 1px solid;

  background: #FFFFFF;
  border: 3px solid #27517E;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: black;

    font-family: 'Times New Roman', Times, serif;
  }

  h6 {
    font-size: 9px;
  }

  h5 {
    font-size: 24px;
  }

  h3 {
    font-size: 24px;
  }


  .print-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    border: 3px solid #EFA348;

    .print-modal-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      .print-modal-header-descriptor {
        margin-bottom: 10px;
      }

      .lear-header-descriptor {
        color: var(--nietBlue);
      }


      h2 {
        font-size: 4vw;
        font-weight: 700;
        margin-bottom: 1rem;
      }
    }

    .print-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      .print-modal-body-descriptor {
        margin-bottom: 10px;
      }

      h2 {
        font-size: 5vw;
        font-weight: 600;
        margin: 0;
      }

      .print-modal-body-year {
        h2 {
          font-size: 60px;
          font-weight: 200;
          margin-bottom: 1rem;
        }
      }
    }



    .print-modal-bottom {
      margin-top: 1rem;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      .print-modal-bottom-descriptor {
        margin-bottom: 2rem;

        h5 {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
        }
      }

      img {
        max-width: 350px;
        margin-bottom: 2rem;
      }

      .print-modal-bottom-logos {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;

        img {
          max-width: 350px;
          flex-grow: 1;
        }

        .print-modal-bottom-logos-box {
          img {
            flex-grow: 1;
            max-width: 250px;
            margin-left: 1rem;
          }
        }
      }

      .print-modal-bottom-logos-lear {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;

        img {
          max-height: 13rem;
          max-width: 13rem;
          margin-bottom: 3rem;
          margin-top: 3rem;
          flex-grow: 1;
        }

        .print-modal-bottom-logos-box {
          img {
            flex-grow: 1;
            max-width: 250px;
            margin-left: 1rem;
          }
        }
      }

      .h2-lear {
        font-weight: bold;
        color: var(--nietBlue);
      }
      .h3-lear {
        font-weight: bold;
        color: var(--nietBlue);
      }

      .h3-lear-size {
        font-size: 27px;
        font-weight: bold;
        color: var-nietBlue;
      }
    }

    .lear-name {
      color: red;
      font-size: 80px;
    }

    .lear-body-descriptor {
      color: red;
    }

    .niet-blue {
      color: var(--nietBlue);
    }

    .niet-bold {
      font-weight: bold;
    }
    
    .laer-description {
      font-size: 18px;
    }

    .print-modal-footer-wrapper {
      width: 100%;

      .print-modal-footer {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin: 0 8em;

        .print-modal-footer-signature {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: column;
          height: 100%;

          img {
            position: relative;
            margin-top: -20px;
            bottom: -20px;
            z-index: 1;
          }

          .print-modal-footer-signline {
            width: 100%;
            height: 1px;
            background-color: transparent;
            margin: 10px 0;
            /* 
                  z-index: 2; // TODO get new signature?
                  background-color: black;
                */
          }

          h5 {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            margin: 0;
            z-index: 3;
          }
        }

        .print-modal-footer-date {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: column;
          height: 100%;

          h4 {
            font-size: 20px;
            font-weight: 700;
            margin: 0;
          }

          .print-modal-footer-signline {
            width: 200px;
            height: 1px;
            background-color: #000;
            margin: 10px 0 20px 0;
          }

          h5 {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            margin: 0;
          }
        }
      }
    }
  }
  .lear-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: unset;
    width: 100%;
    height: 100%;
    margin: 0;
    border: 20px solid var(--nietBlue);
  }

}

@media print and (orientation: landscape) {
 

  html,
  body {
    height: unset;
  }

  .print-wrapper-layout {
    zoom: 1;
  }
}