.rs-picker-menu {
    z-index: 100000 !important;
}

.error-wrapper-custom {
    position: relative;
    font-weight: 400;
    color: var(--error);
    height: 1.8rem;
    font-size: 1.1rem;
}

.details-phase {
    min-width: 60rem;

    h5 {
        font-size: 1.2rem
    }

    h4 {
        font-size: 1.5rem
    }

    h2 {
        font-weight: bold;
        font-size: 1.75rem;
        margin-top: 0rem;
        //global change required for below...
        line-height: 25px;
    }

    .center {
        display: flex;
        flex: row;
        justify-content: center;
        width: 100%;
    }

    .rs-picker-toggle-wrapper {
        width: 100%;
    }

    .field-input .generic-input-component .title {
        font-size: 1.1rem;
        font-weight: 600;
    }

    .spacer-line {
        width: 100%;
        border-bottom: 2px solid var(--primary);
        margin-bottom: 1rem;
    }

    .phase-two-header {
        margin-bottom: 1.25rem;
    }

    .column-field {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        .three {
            width: 33%;
        }

        .two {
            width: 50%;
        }

    }
}