.signature-dialog-footer {
    flex-direction: row-reverse !important;
}

.blockui {
    position: fixed;
    top: 0rem;
    left: 0rem;
    width: 100%;
    height: 100%;
    background-color: var(--blockui);
    z-index: 2000;
}

.rubric-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 30rem;
    width: clamp(40rem, 66%, 120rem);
    background-color: white;
    border: 1px solid black;
    border-radius: 4px;
    z-index: 2001;

    &.expanded-height {
        height: 50rem;
    }

    .rubric-modal-header {
        position: relative;
        height: 5rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .rubric-title {
            position: relative;
            font-size: 2.5rem;
            font-weight: 700;
        }

        .btn-ctrl-section {
            position: absolute;
            margin-right: 0.25rem;
            right: 0rem;
            width: auto;
            height: 2rem;
            font-size: 2rem;

            .fa-icon {
                margin-right: 0.75rem;
                cursor: pointer;
            }
        }
    }

    .rubric-section {
        position: relative;
        left: 1.25rem;
        width: calc(100% - 2.5rem);
        height: calc(100% - 6.25rem);
        border: 1px solid rgba(0, 0, 0, 0.5);
    }
}

.signature-dialog {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.1rem;
    font-weight: 300;
    position: relative;

    .signature-dialog-sign {
        display: flex;
        flex-direction: column;
        align-items: center;



        .signature-fields {
            display: flex;
            flex-direction: row;
            gap: 2rem;
            max-width: 1000px;
            width: 90%;
            align-items: center;

            h4 {
                padding-top: 2rem;
                padding-bottom: 2rem;
                width: 100%;
                text-align: center;
            }

            .confirm-field {
                white-space: nowrap;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding-top: .75rem;
                text-align: center;
                width: 20rem;
                gap: .75rem;

                .confirm-label {
                    line-height: 0rem;
                    padding-bottom: .4rem;
                    font-weight: 600;
                }
            }
        }

    }

    .refresh-icon {
        width: 1.8rem;
        position: absolute;
        top: -5.25rem;
        left: -4px;
        cursor: pointer;
    }
}

.content-section-review {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    min-width: 1024px;
    width: 80%;
    position: relative;
    top: 10rem;
    padding: 2rem 2rem 0 2rem;

    margin-top: 1rem;


    .sub-section {
        width: 100%;
        max-width: 120rem;
        z-index: 0;



        &.info {
            display: flex;
            flex-direction: row;
            gap: 8rem;

            .info-section {
                width: 50%;
                display: flex;
                flex-direction: column;
                gap: .5rem;

                .info-header {
                    font-weight: 700;
                    font-size: 1.5rem
                }

                .info-sub-header {
                    font-weight: 600;
                    font-size: 1.25rem
                }

                .info-label-header {
                    font-weight: 600;
                    font-size: 1.1rem
                }

                .info-label {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: .5rem;

                    .info-label-header {
                        font-weight: 600;
                        font-size: 1.1rem
                    }
                }

                textarea {
                    width: 100%;
                    height: 8rem;
                    cursor: default;
                    padding: .5rem;
                }
            }

        }

        .review-btn-row {
            display: flex;
            flex-direction: row;
            gap: 3rem;
            justify-content: left;
            align-items: center;
            font-size: 1.25rem;
            font-weight: 500;
        }

        .review-grid {
            border: 1px solid var(--darkgray-1);

            .review-header {
                background-color: var(--nietDarkBlue);
                color: white;
                display: flex;
                flex-direction: row;

                .name {

                    font-weight: 600;
                    font-size: 2rem;
                    padding: .5rem 1rem .25rem 1rem;
                    width: 64%;


                }

                .rating {
                    font-weight: 600;
                    font-size: 1rem;
                    color: white;
                    padding: .5rem 1rem .25rem 1rem;
                    text-align: center;
                    width: 18%;
                    border-left: 1px solid var(--darkgray-1);

                    &.noSelf {
                        padding: 1.25rem 1rem .25rem 1rem;
                        width: 36%;
                    }
                }


            }


            .review-body {
                .review-row {
                    background-color: white;
                    color: var(--nietDarkBlue);
                    display: flex;
                    flex-direction: row;
                    border-top: 1px solid var(--darkgray-1);

                    .name {
                        font-weight: 700;
                        font-size: 1.2rem;
                        padding: 1.2rem 1rem 1.2rem 1rem;
                        width: 64%;
                        text-decoration: underline;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .show-evidence {
                            padding-right: 3rem;
                            cursor: pointer;
                        }
                    }

                    .rating {
                        font-weight: 700;
                        font-size: 1.2rem;
                        color: var(--nietDarkBlue);
                        padding: 1.2rem 1rem 1.2rem 1rem;
                        text-align: center;
                        width: 18%;
                        border-left: 1px solid var(--darkgray-1);

                        &.noSelf {

                            width: 36%;
                        }
                    }
                }
            }
        }

    }
}

.resend-selfreflection-email {
    max-width: 26rem;
}