.analytic-create-dialog-outer {
  min-width: 70rem;
  max-height: unset !important;
  overflow-y: unset !important;

  .analytic-create-dialog {
    display: flex;
    gap: 3rem;

    .analytic-left-col {
      width: 60%;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .chart-preview-container {

        flex: 1;

        &-center {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .btn_control {
          width: 20rem;
        }


        .chart-preview-header-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .chart-update-btn {
            display: flex;
            align-items: center;
            cursor: pointer;
            background-color: var(--nietDarkBlue);
            color: white;
            padding: 0 .5rem 0 .5rem;
            border-radius: 6px;
            font-size: 1.2rem;

            &-disable {
              background-color: var(--lightgray-2);
              cursor: not-allowed;
              border: 3px solid var(--darkgray-border-2);
              color: var(--lightgray-1);

              img {
                color: black;
              }
            }

            img {
              width: 1.3rem;
              height: 1.3rem;
              margin-right: .5rem;
            }
          }
        }

        .chart-preview-chart-wrapper {
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          position: relative;

          .field-input {
            position: absolute;
            left: 50%;
            top: 2rem;
            transform: translateX(-50%);
            max-width: 20rem;
            margin: auto;

            z-index: 100;

            .generic-input-component {
              .child {
                display: flex;
                justify-content: center;

                // #field_sl_preview-chart {
                //   max-width: 20rem;
                // }
              }
            }
          }
        }

        .center-text {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          font-size: 2rem;
        }

      }

      .size-selectors {
        display: flex;
        gap: 2rem;

        .size-1,
        .size-2,
        .size-3 {
          cursor: pointer;
        }
      }
    }

    .analytic-right-col {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      width: 40%;

      .chart-config-row {
        padding-bottom: .5rem;
        border-bottom: 1px solid black;

        &:last-child {
          border-bottom: unset;
        }

        h3 {
          margin-bottom: 1rem;
          font-weight: 700;
        }

        .field-input {
          width: 25rem;
          margin-bottom: 1rem;

          .cbx_control {
            position: relative;
            height: 1.5rem;



            input[type=checkbox] {
              accent-color: var(--nietDarkBlue);
            }

            input {
              width: 1.5rem;
              height: 1.5rem;
            }
          }

          .cluster-checkbox {
            &::after {
              position: absolute;
              content: 'Restrict to specific cluster(s)';
              font-weight: 500;
              top: 50%;
              left: 3rem;
              transform: translateY(-50%);
            }
          }

          .date-checkbox {
            &::after {
              position: absolute;
              content: 'Restrict to specific date range';
              font-weight: 500;
              top: 50%;
              left: 3rem;
              transform: translateY(-50%);
            }
          }
        }

        .date-wrapper {
          display: flex;
          gap: 2rem;

          .field-input {
            width: 13rem;
          }
        }

        .group-btn-wrapper {
          display: flex;
          gap: 1rem;
          justify-content: space-around;
          margin-bottom: 1rem;

          .group-btn {
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: .25rem .5rem .25rem .5rem;
            border-radius: .5rem;
            border: 1px solid var(--nietDarkBlue);

            img {
              margin-right: .5rem;
              width: 16px;
              height: 16px;
            }
          }

          .group-btn-active {
            background-color: var(--nietDarkBlue);
            color: white;
          }

        }
      }
    }
  }

  .analytic-default-dialog {
    margin-bottom: 2rem;

    .field-input {
      width: 15rem;
    }
  }
}


.analytics {
  position: absolute;
  top: 6rem;
  left: 0rem;
  height: calc(100% - 5rem - var(--footer-height) - 0.3rem);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .screen-wrapper {
    position: unset;
    max-width: 100rem;
    margin: auto;

    .landing-btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5rem;
      height: calc(100vh - 13rem)
    }

    .analytics-header-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      margin-bottom: 3rem;
      padding-top: 1rem;
      min-height: 7rem;

      .categoryTitle {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4rem;
      }

      .left-arrow-icon {
        cursor: pointer;
      }

      h3 {
        font-size: 2.3rem;

        // .field-input {
        //   font-size: 1rem;
        // }
      }

      .button-wrapper {
        display: flex;
        gap: 3rem;
      }
    }
  }


  .analytics-grid-container {
    display: grid;
    gap: 3rem;
    width: 100%;
    position: relative;
  }

  .analytic-instructions {
    font-size: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20rem;
    padding: 4rem;
  }

  .analytics-item {
    transition: all 250ms ease-in;
    padding: .5rem;
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    z-index: 1;

    &-dropdown-active {
      z-index: 2;
      transition: unset;
    }

    &.size-1 {
      border: 1px solid var(--darkgray-border);
    }

    &.size-2 {
      border: 1px solid var(--darkgray-border);
    }

    &.size-3 {
      border: 1px solid var(--darkgray-border);
    }

    .analytics-item-charts-wrapper {
      height: 100%;
      flex-wrap: wrap;
      display: flex;


    }

    .draggable-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50px;
      height: 50px;
      color: white;
      cursor: grab;
      z-index: 1;
    }



    .analytics-item-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: .5rem;

      h4 {
        font-size: 1.2rem;
      }

      .analytics-item-title {
        font-weight: 700;
        margin-bottom: unset;
      }

      .analytics-item-actions {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;

        .analytics-item-icon-button {

          cursor: pointer;
          height: .5rem;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            height: 2rem;
            color: white;

          }


        }
      }

      .analytics-item-actions-editing {
        .analytics-item-icon-button {
          svg {
            color: var(--nietDarkBlue);
          }
        }
      }
    }

    .analytics-item-groups {
      .analytics-item-group-top-container {
        display: flex;
        gap: 1rem;
        padding-bottom: .5rem;
      }
    }

    .analytics-item-group {
      display: flex;
      gap: .7rem;

      .analytics-item-group-title {
        display: flex;
        align-items: center;
      }
    }



    .analytics-item-chart {
      flex: 1;
      position: relative;

      &-header {
        width: 100%;
        display: flex;
        justify-content: center;
        font-weight: bold;
        color: var(--nietDarkBlue);
        font-size: 1.66rem;
        position: absolute;
        top: 10%;
      }
    }

    .chart-error {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      font-weight: 700;
      height: 100%;
    }

    .field-input {
      position: absolute;
      top: 1rem;
      left: 50%;
      transform: translateX(-50%);
      max-width: 20rem;
      z-index: 2;
    }

    .analytics-item-dropdown {
      display: none;

      &.active {
        display: block;
      }

      position: absolute;
      top: 100%;
      left: 0;
      width: 200px;
      background-color: #fff;
      border: 1px solid #ccc;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      padding: 8px;
      z-index: 1;

      .analytics-item-dropdown-item {
        padding: 4px 8px;
        cursor: pointer;

        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }


  .draggable {
    &::after {
      cursor: grab;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: black;
      opacity: .5;
      top: 0;
      left: 0;
    }

    .analytics-item-chart {
      .field-input {
        z-index: 0;
      }
    }
  }

}