.videoPlayerFrame {
    width: 100%;
    height: 100%;
    min-width: 720px;
    min-height: 480px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .video-player {
        padding-bottom: 0 !important;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        max-width: 1000px;
        min-width: 720px;
        min-height: 480px;

        .azuremediaplayer {
            width: 100%;
            height: 100%;
            border: 2px solid white;
            max-width: 1024px;
            min-width: 720px;
            min-height: 480px;
        }

        .vjs-big-play-button {
            font-size: 120vh;
        }

        #player-wrapper {
            width: 100%;
        }

        .bitmovinplayer-container video, .bitmovinplayer-ad-container video {
            object-fit: fill;
        }
    }

    .bitmovinplayer-poster {
        display: none !important;
    }

    // .bmpui-ui-errormessage-overlay.bmpui-hidden {
    //     display: block;
    // }
    .bmpui-ui-watermark {
        display: none;
    }

    .bmpui-ui-errormessage-overlay .bmpui-ui-tvnoisecanvas {
        filter: blur(20px);
        background: black;
    }
    .bmpui-ui-errormessage-overlay .bmpui-ui-errormessage-label {
        color: black;
    }

    .bmpui-ui-titlebar {
        background: linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.4));
    }

    .bmpui-seekbar-playbackposition-marker {
        border: white;
    }
    .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition-marker, .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition-marker {
        border: .1875em solid  white;
    }

    .bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-playbackposition, .bmpui-ui-volumeslider .bmpui-seekbar .bmpui-seekbar-playbackposition {
        background-color: var(--nietBlue);
    }

}

.content {
    margin-bottom: 10em;
}

#player {
    width: 100%;
    margin: auto;
    -webkit-box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
}

@media screen and (max-width: 1280px) {
    .videoPlayerFrame {
        width: 100%;
        height: 100%;
        min-width: 720px;
        min-height: 450px;

        .video-player {
            max-width: 800px;
            max-height: 450px;
            min-height: 455px;

            .azuremediaplayer {
                min-height: 453px;
            } 
        }
    }
}