$mediaBreakpoint: 84em;

.profile {
  .screen {
    background: #d0d0d0;
  }

  .screen__content .screen-wrapper {
    background: transparent;

    .control-box-wrapper {
      background: transparent;
      display: block;

      @media (min-width: $mediaBreakpoint) {
        display: flex;
      }

      .control-box {
        background: transparent;
        padding: unset;

        @media (min-width: $mediaBreakpoint) {
          padding: 1.5em;
        }

        &.box-two-column {
          max-width: unset;

          @media (min-width: $mediaBreakpoint) {
            max-width: 50%;
          }

          &.first-column {
            align-items: center;
            padding-right: 0;

            @media (min-width: $mediaBreakpoint) {
              align-items: flex-end;
              padding-right: 4em;
            }
          }

          &.second-column {
            align-items: center;
            padding-bottom: 2em;
            padding-left: 0;
            margin-top: 3em;

            @media (min-width: $mediaBreakpoint) {
              align-items: flex-start;
              padding-bottom: 2em;
              padding-left: 4em;
              margin-top: unset;
            }
          }
        }
      }
    }
  }
}

.profile {
  .btn_control__standard {
    background-color: var(--nietBlue);
  }
}


.profile__details,
.account-settings__details {
  width: 600px;
  padding: 3em;
  padding-left: 5rem;
  padding-right: 5rem;
  background-color: var(--nietUtilityGray);
  border: solid 2px var(--nietDarkBlue);
  border-radius: 10px;

  min-height: 50rem;
}