.general-survey-tree-picker {
  width: 47rem;
}

.screen__content {
  .survey-wrapper {
    display: unset;
  }
}

//DIALOG CSS
.survey-properties-bottom-container {
  display: flex;
  gap: 2rem;
}

.survey-personnel-bottom-container {
  display: flex;
  gap: 2rem;
}

.survey-screen-header {
  margin-left: 2rem;
  padding-right: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    margin-bottom: unset;
  }

  .survey-header-wrapper {
    display: flex;

    .survey-status {
      color: black;
      margin: auto 1.75rem;
      border: 1px solid black;
      border-radius: 4px;
      padding: 4px 8px 4px 8px;
    }
  }

  .survey-subheader-wrapper {
    display: flex;
    margin-top: 1rem;
    font-size: 1.25rem;

    .survey-subheader-left {
      width: 12rem;
      font-weight: bold;
    }

    .survey-subheader-right {
    }
  }

  .survey-header-button-wrapper {
    display: flex;
    gap: 3rem;
  }
}

.survey-container {
  width: 100%;
  margin-top: 3rem;
  font-weight: bold;
  font-size: 1.25rem;
  padding-left: 2rem;
  padding-right: 2rem;

  .survey-deactivated {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 20rem;
  }

  .survey-desc {
    max-width: 100rem;
    margin: auto;
    margin-bottom: 2rem;

    div {
      margin: auto;
      max-width: 75%;
    }

    &.center-text {
      text-align: center;
      font-size: 1.8rem;
    }
  }

  .survey,
  .rubric {
    margin: 0 auto;
    margin-top: 3rem;
    min-width: 80rem;
    max-width: 120rem;
    height: fit-content;
    width: 100%;

    .top-item-wrapper {
      position: sticky;
      top: 0px;
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: white;

      .top-item-row {
        display: flex;
        justify-content: flex-end;
        padding: 1rem 0;
        gap: 2rem;
        border-bottom: 1px solid var(--darkgray-border-2);

        .item-value-wrapper {
          display: flex;
          width: 55%;

          .item-value {
            display: flex;
            justify-content: center;
            text-align: center;
          }
        }
      }
    }

    .item-row {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
      border-bottom: 1px solid var(--darkgray-border-2);
      gap: 2rem;

      .item-question {
        max-width: 40%;
      }

      .item-value-wrapper {
        display: flex;
        width: 55%;

        .item-value {
          display: flex;
          justify-content: center;
        }

        .char-cnt,
        .error-wrapper {
          display: none;
        }
      }
    }

    .review-header {
      background-color: var(--nietDarkBlue);
      color: white;
      display: flex;
      flex-direction: row;

      .name {
        font-weight: 600;
        font-size: 2.3rem;
        padding-left: 1rem;
        width: 64%;
        border-right: 1px solid var(--darkgray-1);
      }

      .rating {
        font-weight: 600;
        font-size: 1rem;
        color: white;
        padding-left: 1rem;
        margin: auto 0;
        width: 36%;
      }

      .rubric-name {
        width: 42%;
      }

      .rubric-rating {
        border-right: 1px solid var(--darkgray-1);
        display: flex;
        align-items: center;
        margin: unset;
        width: 40%;

        ul {
          padding-left: 1rem;
        }

        &:last-child {
          border-right: unset;
          padding-left: unset;
          display: flex;
          justify-content: center;
          width: 18%;
        }
      }
    }

    .review-row {
      background-color: white;
      color: var(--nietDarkBlue);
      display: flex;
      flex-direction: row;
      border-top: 1px solid var(--darkgray-1);

      &:last-child {
        border-bottom: 1px solid var(--darkgray-1);
      }

      .name {
        font-weight: 700;
        font-size: 1.15rem;
        padding: 1.2rem 1rem 1.2rem 1rem;
        border-right: 1px solid var(--darkgray-1);
        border-left: 1px solid var(--darkgray-1);
        width: 64%;
        text-decoration: underline;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .rating {
        font-weight: 700;
        color: var(--nietDarkBlue);
        padding: 1.2rem 1rem 1.2rem 1rem;
        border-right: 1px solid var(--darkgray-1);
        width: 36%;
        height: auto;

        .upload-area > .file-upload-image {
          background-color: unset;
        }

        .img-wrapper {
          width: 400px;
          margin: unset;

          img {
            width: 100%;
          }
        }
      }

      .rubric-name {
        width: 42%;
      }

      .rubric-rating {
        width: 40%;
        border-right: 1px solid var(--darkgray-1);

        &:last-child {
          padding-left: unset;
          display: flex;
          justify-content: center;
          width: 18%;
        }
      }
    }
  }

  .rubric {
    .review-row {
      &:nth-child(odd) {
        background-color: var(--row-odd);
      }

      &:nth-child(even) {
        background-color: var(--row-even);
      }
    }
  }

  .observation-table-wrapper {
    margin: auto;
    width: 100%;
    overflow-x: auto;
    overflow-y: unset;
    height: unset;
  }

  .loading-wrapper {
    margin: 10rem 0;
    display: flex;
    justify-content: center;
    font-size: 3rem;
  }
}

.survey-footer {
  display: flex;
  justify-content: flex-end;
  gap: 3rem;
  padding: 5rem 3rem 3rem 0;

  .btn_control {
    min-width: 12rem;
  }
}

.print-page {
  display: none;
}

@media print {
  .print-page {
    position: absolute;
    display: block;
    width: 100%;

    .print-content {
      margin-top: 5rem;

      .survey-screen-header {
        margin-left: unset;

        .survey-header-row {
          width: 100%;

          .survey-subheader-wrapper {
            .survey-subheader-left {
              width: fit-content;
            }

            .survey-subheader-right {
              margin-left: 1.5rem;
              width: fit-content;
            }
          }
        }
      }

      .survey-container {
        .survey,
        .rubric {
          width: 100%;
          max-width: unset;

          .review-row {
            .rating {
              .img-wrapper {
                width: 275px;
              }
            }
          }
        }
      }
    }
  }

  .survey-print {
    zoom: 1;
  }

  .screen {
    display: none;
  }

  .header {
    display: none;
  }

  .footer {
    display: none;
  }
}
