.reports .contentDisplay {
  width: calc(100% - 40rem);
}

.reports-title {
  margin-left: 20rem;
}

.reports h1 {
  font-weight: bold;
}

.reports h2 {
  font-weight: bold;
}

.report {
  margin-left: 2rem;
}

.report-title {
  text-decoration: underline;
  cursor: pointer;
}

.report-description {
  margin-left: 3rem;
}

.report-type {
  margin-left: 3rem;
}

.report-type h4 {
  font-weight: bold;
}

.report-type h5 {
  font-size: 1.4rem;
  line-height: 1.5;
}

.maintenance-banner {
  background-color: #FFEB3B;
  color: #FF0000;
  padding: 20px;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 20px; 
  max-width: 800px; 
  margin-left: auto; 
  margin-right: auto; 
  word-wrap: break-word;
}