.preferences {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem;
    font-size: 1.4rem;
    max-height: 18rem;

    .list {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        gap: .8rem;
        width: 50%;
        padding: .4rem;

        &-text {
            font-weight: bold;
        }
    }

    &-loading {
        display: flex;
        flex: row;
        justify-content: center;
        align-items: center;
        padding: 1rem;
    }
}