.access-deny {
    text-align: center;
}

.screen {
    position: fixed;
    width: 100%;
    height: calc(100% - var(--header-height) - var(--footer-height) - .5rem);
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    margin-top: calc(var(--header-height) + 1.25rem);
    min-width: 60rem;
    padding-left: 1rem;
    padding-right: 1rem;
    overflow-x: hidden;

    .screen-header {
        margin-left: 3.75rem;

        &-filter {
            margin-left: 19rem;
        }
    }

    &__content {
        position: absolute;
        padding-bottom: 1rem;
        width: 100%;
        height: calc(100% - 1rem);
        margin-top: 1rem;

        & {
            -ms-overflow-style: none;
            /* Internet Explorer 10+ */
            scrollbar-width: none;
            /* Firefox */
        }

        &::-webkit-scrollbar {
            display: none;
            /* Safari and Chrome */
        }

        .screen-wrapper {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            width: 100%;
            height: 100%; //calc(100% - var(--footer-height));

            //padding-top: 1.5rem;

            .admin-table-wrapper {
                height: 55rem;
            }


            &-filter {
                margin-left: 19rem;
            }

            &-indicator {
                text-align: center;
                margin-top: -40px;
            }
        }

        .screen-footer {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: calc(100% - 5rem);
            padding-bottom: 1rem;


            &.list {
                bottom: 0rem;
                justify-content: left;
                width: calc(100% - 37rem);
                padding-left: 1rem;
                margin-right: 20rem;
            }

            &__btn {
                width: 10rem;
            }

            &.right {
                justify-content: right;
            }
        }

        .screen-footer-right {
            justify-content: right;
        }

        .screen-footer-left {
            justify-content: unset;
        }

        &.error-box {
            display: flex;
            flex-direction: column;
            margin-top: 2.5rem;
            text-align: center;

            h1 {
                color: var(--error);
            }

            div {
                margin-top: 1rem;
                font-size: 1.1rem;
                max-width: 55%;
            }
        }

        .control-box-wrapper {
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: right;
            height: calc(100% - var(--footer-height));
            background-color: var(--white);
            border-radius: 4px;
            padding-right: 4rem;
            padding-left: 2rem;

            .large-text-box {
                height: 40rem;
            }

            .medium-text-box {
                height: 20rem;
            }

            .small-text-box {
                height: 10rem;
            }

            .loading-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                font-size: 1.5rem;
                font-weight: 600;
            }

            .control-box {
                position: relative;
                display: flex;
                flex-direction: column;
                width: calc(100% - 2rem);
                background-color: var(--white);
                padding: 1.5rem;

                &-list {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    margin-bottom: 2.5rem
                }

                &.box-two-column {
                    max-width: 50%;

                    .rs-picker-toggle-wrapper {
                        width: 100%;
                    }
                }

                &.box-three-column {
                    max-width: 33%;

                    .rs-picker-toggle-wrapper {
                        width: 100%;
                    }
                }

                &.box-twothirds-column {
                    max-width: 67%;

                    .rs-picker-toggle-wrapper {
                        width: 100%;
                    }
                }

                &.box-four-column {
                    max-width: 25%;

                    .rs-picker-toggle-wrapper {
                        width: 100%;
                    }
                }


            }
        }
    }
}