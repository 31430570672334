.recom-dialog {
  display: flex;
  flex-direction: row;
  gap: 0rem;
  padding-bottom: 1rem;

  &-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-left: 1rem;
    justify-content: center;
    align-items: middle;
    height: 15rem;
    gap: .5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &-loader {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-left: 1rem;
    justify-content: center;
    align-items: center;
    height: 15rem;
    gap: .5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}