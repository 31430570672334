.admin-observer-table {
    .admin-observer-weights-table {
        width: 100%;
        border-collapse: collapse;

        .row-header {
            font-weight: bold;
            font-size: 1rem;
            width: 50%;
        }

        thead {
            th {
                text-align: center;
            }
        }

        input {
            text-align: center;
            padding-left: 0;
        }
    }

    .admin-observer-weights-table th,
    .admin-observer-weights-table td {
        padding-bottom: .5rem;
        padding-right: 5rem;
        text-align: left;
    }

    .align-center {
        text-align: center !important;
    }

    @media (max-width: 600px) {
        .admin-observer-weights-table {
            font-size: 12px;
        }
    }
}

.allocation-table {
    th {
        padding: .5rem;
        width: 10rem;
        text-align: center;
    }

    .input-text-wrapper {
        text-align: center;
    }

    .blank-header {
        width: 5rem;
    }

    input {
        max-width: 8rem;
    }

    td {
        padding: .3rem;
    }
}

.admin-metric-table {
    padding-bottom: 2rem;

    .description-cell {
        width: 50%;
    }
}

.metric-header-table {
    width: 100%;
}

.metric-ratio-table {
    table {

        th {
            text-align: center;
        }

        input {
            text-align: center;
            padding-left: 0 !important;
        }

        td {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }

        .assist-row {
            padding-top: 1rem;
        }

        .percent-row {
            width: 5%;
            padding-right: 1rem;
        }
    }
}

.threshold-header {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--nietDarkBlue);
    padding-top: 3.5rem;
    padding-bottom: 2rem;
}

.metric-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.metric-trashcan {
    height: 1.5rem;

    &:hover {
        cursor: pointer;
    }
}

.create-metric-btn {
    width: 15rem;
}

.custom-metric-name {
    width: 50%;
}

.inactive-metric {
    display: flex;
    justify-content: center;

    span {
        cursor: pointer;
        text-decoration: underline;
    }
}