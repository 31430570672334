.school-panel {
    .rs-panel-header {
        padding-top: 0;
    }

    .rs-panel-title {
        font-size: 1.2rem;
    }
}

.school-achievement-pay-ratios-table {
    .school-achievement-pay-ratios-table {
        width: 100%;
        border-collapse: collapse;

        .row-header {
            font-weight: bold;
            font-size: 1rem;
            text-align: center;
        }
    }
    
    .school-achievement-pay-ratios-table th,
    .school-achievement-pay-ratios-table td {
        padding-bottom: .5rem;
        padding-right: 2rem;
        text-align: center;
    }
    
    @media (max-width: 600px) {
        .school-achievement-pay-ratios-table {
            font-size: 12px;
        }
    }

    .delete-row-btn-container {
        .delete-row-btn {
            height: 2rem;
            padding: 1.1rem 1rem 1.1rem 1rem;
            line-height: 0rem;
            background-color: var(--nietDarkBlue);
            box-shadow: 2px 2px 1px var(--lightgray-2);
            border: 2px solid var(--darkgray-border-2);
            border-radius: 6px;
            color: var(--white);
            border: 3px solid var(--darkgray-border-2);
            border-radius: 10px;
        }
    }

    .btn-container {
        display: flex;
        padding-top: 1rem;
    }

    .add-row-btn-container {
        .add-row-btn {
            height: 2rem;
            padding: 1.1rem 1rem 1.1rem 1rem;
            margin-right: 1rem;
            line-height: 0rem;
            background-color: var(--nietDarkBlue);
            box-shadow: 2px 2px 1px var(--lightgray-2);
            border: 2px solid var(--darkgray-border-2);
            border-radius: 6px;
            color: var(--white);
            border: 3px solid var(--darkgray-border-2);
            border-radius: 10px;
        }
    }
}