.tooltip-container {
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
}

.tooltip {
  position: absolute;
  background-color: #f47e3a;
  color: #fff;
  padding: 4px 8px;
  border-radius: 3px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  top: 0;
  right: 0;
  left: 100%;
  height: 2.2rem;
  width: 3rem;
  text-align: center;
  z-index: 9999;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.min-obs-indicator {
  flex: 1;
  font-size: 1.1rem;
  font-weight: bold;
}

.loading-container-payout {
  margin: 19.30%;
  text-align: center; 
}