.btn-wrapper {
    margin-top: 1rem;

    &-left {
        margin-right: .5rem;
    }

    &-right {
        margin-left: .5rem;
    }
}

.btn_control {
    padding: 1.5rem 1rem 1.5rem 1rem;
    font-weight: 700;
    border-radius: .5rem;
    line-height: 1rem;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
    max-width: 25rem;
    text-transform: uppercase;
    letter-spacing: .18rem;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &__standard {
        height: 2rem;
        padding: 1.5rem 1rem 1.5rem 1rem;
        text-align: center;
        line-height: 0rem;
        background-color: var(--second);
        box-shadow: 2px 2px 1px var(--lightgray-2);
        color: var(--white);
        border: 3px solid var(--darkgray-border-2);
        border-radius: 10px;

        &-small {
            height: 1.5rem;
            padding: 1rem 2rem 1rem 2rem;
        }
    }

    &__cancel {
        height: 2rem;
        padding: 1.5rem 1rem 1.5rem 1rem;
        line-height: 0rem;
        background-color: var(--white);
        box-shadow: 2px 2px 1px var(--lightgray-2);
        border: 3px solid var(--primary-blue);
        border-radius: 6px;
        color: var(--primary-blue);

        .loading__spinner_2 {
            border: 4px solid var(--primary-blue);
            border-top: 4px solid var(--white);
        }

    }

    &__sub-action {
        height: 1rem;
        padding: 1.25rem 1rem 1.25rem 1rem;
        line-height: 0rem;
        background-color: var(--white);
        box-shadow: 2px 2px 1px var(--lightgray-2);
        border: 3px solid var(--primary-blue);
        border-radius: 6px;
        color: var(--primary-blue);

        .loading__spinner_2 {
            border: 4px solid var(--primary-blue);
            border-top: 4px solid var(--white);
        }

    }

    &__okay {
        height: 2rem;
        padding: 1.5rem 1rem 1.5rem 1rem;
        line-height: 0rem;
        background-color: var(--nietDarkBlue);
        box-shadow: 2px 2px 1px var(--lightgray-2);
        border: 2px solid var(--darkgray-border-2);
        border-radius: 6px;
        color: var(--white);
        border: 3px solid var(--darkgray-border-2);
        border-radius: 10px;

        .loading__spinner_2 {
            border: 4px solid var(--white);
            border-top: 4px solid var(--primary-blue);
        }

    }

    &__create,
    &__play {
        position: relative;
        height: 2rem;
        padding: 1.5rem 1rem 1.5rem 1rem;
        line-height: 0rem;
        background-color: var(--nietDarkBlue);
        box-shadow: 2px 2px 1px var(--lightgray-2);
        border: 3px solid var(--darkgray-border-2);
        border-radius: 10px;
        color: var(--white);

        .loading__spinner_2 {
            border: 4px solid var(--white);
            border-top: 4px solid var(--primary-blue);
        }

        &::after {
            position: absolute;
            content: '';
            width: 1.5rem;
            height: 1.5rem;
            color: inherit;
            background-image: url('../../content/icons/circle-plus.svg');
            left: -1rem;
            top: -1rem;
            //z-index: 2000;
        }
    }

    &__play {
        &::after {
            width: 1.7rem;
            height: 1.7rem;
            background-image: url('../../content/icons/button-play.svg');
        }
    }

    &__create-disabled,
    &__play-disabled {
        position: relative;
        height: 2rem;
        padding: 1.5rem 1rem 1.5rem 1rem;
        line-height: 0rem;
        background-color: var(--nietDarkBlue);
        box-shadow: 2px 2px 1px var(--lightgray-2);
        border: 3px solid var(--darkgray-border-2);
        border-radius: 10px;
        color: var(--white);

        .loading__spinner_2 {
            border: 4px solid var(--white);
            border-top: 4px solid var(--primary-blue);
        }

        &::after {
            display: none;
        }
    }


    &__footer {
        height: 2rem;
        padding: 1.5rem 1rem 1.5rem 1rem;
        line-height: 0rem;
        background-color: var(--nietDarkBlue);
        box-shadow: 2px 2px 1px var(--lightgray-2);
        border: 2px solid var(--white);
        border-radius: 6px;
        color: var(--white);

        .loading__spinner_2 {
            border: 4px solid var(--white);
            border-top: 4px solid var(--nietDarkBlue);
        }
    }





    &.disabled {
        cursor: default;
        background-color: var(--lightgray-2);
        color: var(--lightgray-1);
        border-color: var(--lightgray-2);
        box-shadow: unset;

        &:active {
            transform: none;
        }

        &::after {
            display: none;
        }
    }

    .inner_loading_text {
        visibility: hidden;
        margin-top: -1.5rem;
    }

    .inner_loading {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    &:active {
        transform: scale(1.03);
    }
}

.cbx_control {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding-left: .1rem;

    div {
        input[type=checkbox] {
            width: 1.5rem;
            border-radius: 3px;
            accent-color: var(--primary-blue);
            box-shadow: none;

            &:focus-visible {
                outline: none;
            }
        }
    }
}

.sl_control {
    width: 100%;
    /* Reset */
    appearance: none;
    border: 1px solid var(--lightgray-1);
    outline: 0;
    font: inherit;
    /* Personalize */
    height: 2rem;
    padding: 0 4em 0 1em;
    background: url(../../content/icons/selectarrow.svg) no-repeat right .2rem center / 1.4rem;
    background-color: var(--white);
    //color: var(--darkgray-1);
    color: black;
    font-weight: 400;
    border-radius: 3px;
    box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;

    white-space: nowrap;
    text-overflow: ellipsis;

    /* <option> colors */




    /* Remove focus outline */
    &:focus {
        border: 2px solid var(--darkgray-1);
    }

    &:focus-visible {
        border: 2px solid var(--darkgray-1);
    }

    &:active {
        border: 2px solid var(--darkgray-1);
    }



    /* Remove IE arrow */
    &::-ms-expand {
        display: none;
    }
}

.default-label {
    height: 2rem;
    font-size: 1.25rem;
    font-weight: 500;
}

.configureHeaderLabel {
    display: inline-block;
}

.rating {
    color: rgba(0, 0, 0, 0.1);
    width: 100%;

    &.sl_control {
        padding: 0 1rem 0 1rem !important;
        width: 100%;
        width: 16.5rem;
    }
}

.rs-picker-menu.rs-picker-select-menu,
.rs-picker-menu.rs-picker-check-menu {
    z-index: 20003;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: var(--nietDarkBlue) !important;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
    border-color: var(--nietDarkBlue) !important;
    border-bottom: none;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover {
    border-bottom: 1px solid #003c5b;
}