.footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 2;
    width: 100%;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.publicFooter {
    width: 100%;
    padding: 1.25rem 2rem 1rem;
    padding-left: 2rem;
    background-color: var(--nietDarkBlue);
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;

    &-right {
        display: flex;
        -ms-flex-direction: row;
        -webkit-flex-direction: row;
        flex-direction: row;
        gap: 2rem;
    }
}

.publicFooter img {
    width: 23rem;
}

.copyrightText {
    font-size: 1.2rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.printNiet {
    color: var(--nietDarkBlue);
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.publicFooter .MuiButton-root {
    background: var(--nietDarkBlue);
    color: #fff;
    padding: 1rem;
    min-width: 10rem;
    font-size: 1rem;
    font-family: 'Raleway';
    letter-spacing: 0.2rem;
    border: #fff solid 2px;
    border-radius: .58rem !important;
}

.publicFooter .MuiButton-root:hover {
    color: #fff !important;
}

.publicFooter.slim {
    position: fixed;
    bottom: 0;
    z-index: 1000;
}