.table-wrapper-indicator {
    --header-height: 3.375rem;
    --data-row-height: 5.562rem;
    --footer-height: 3.375rem;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    position: relative;
    width: 60%;
    height: 100%;
    //border: 1px solid 
    margin-left: 10px;
    border: .5px solid;
    padding-top: 1em;
    padding-bottom: 1em;

    .header-row-indicator {
        position: relative;
        display: flex;
        height: var(--header-height);
        justify-content: space-between;
        align-items: center;
        background-color: #fdc146;
        padding-left: 1rem;
        height: 3.25rem;

        .col {
            font-size: 1.05rem;
            font-weight: 700;
            color: var(--nietLightUI);
            text-align: center;
            width: auto;
            cursor: pointer;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            //border: 1px solid white;

            &:hover>.arrow {
                opacity: 0.66;
            }

            .arrow {
                padding-left: 0.5rem;
                opacity: 0;
                transition: opacity 0.15s ease-out;

                &.active {
                    opacity: 1;
                }
            }
        }

        .header-indicator {
            font-weight: 800 !important;
            justify-content: center;
        }

        .col-left {
            display: flex;
            justify-content: left;
        }
        .col-header-left {
            text-align: left;
        }
        .col-wrap {
            white-space: pre-wrap;
            display: flex;
        }
    }

    .custom-header {
        padding-left: 0;
        height: 40px;
    }

    .row-area {
        position: relative;
        height: calc(100% - var(--header-height));
        overflow-y: auto;

        .data-row-indicator {
            position: relative;
            display: flex;
            height: var(--data-row-height);
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid var(--row-border-bottom);
            padding-left: 1rem;
            height: 3.25rem;

            &:nth-child(odd) {
                background-color: var(--row-odd);
            }

            &:nth-child(even) {
                background-color: var(--row-even);
            }

            .col {
                color: var(--nietDarkBlue);
                //width: auto;
                font-size: 13px;
                font-weight: 600;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                text-align: left;
                padding-left: 1rem;
                // border: 1px solid red;
                //border-right: 1px solid gray;

                .text-center {
                    text-align: center;
                }

                .text-right {
                    text-align: right;
                }

                &.actions {
                    display: flex;
                    gap: 1rem;
                    justify-content: center;

                    .faIcon {
                        font-size: 1.5rem;
                        font-weight: 400;
                        cursor: pointer;
                    }

                    svg {
                        cursor: pointer;
                    }
                }

                .img-wrapper {
                    position: relative;
                    top: 15%;
                    left: 10%;
                    width: 65%;
                    max-width: 10rem;
                    min-width: 4rem;
                    //background-color: pink;
                }
            }

            .col-left {
                display: flex;
                justify-content: normal;
                margin-left: 1rem;
                max-width: unset;
            }
            .col-center {
                text-align: center;
                overflow: inherit;
            }
            .col-header-left {
                text-align: left;
                padding-left: 0;
            }

            .col-wrap {
                white-space: pre-wrap;
                display: flex;
                text-align-last: end;
            }

            .custom-cell {
                overflow-y: auto !important;
            }

            .pencil {
                position: absolute;
                bottom: 0;
                padding-bottom: 5px;

                &:hover {
                    cursor: pointer;
                }
            }

            .disableAction {
                opacity: .3;
                cursor: not-allowed;
            }
        }

        .data-row-border {
            height: 3.5rem;
            margin-bottom: 0.25rem;
            border-right: 1px solid var(--row-border-bottom);
            border-left: 1px solid var(--row-border-bottom);
            justify-content: normal;
        }

        .order-by-indicator {
            padding-right: 1rem;

            &:hover {
                cursor: pointer;
            }
        }

        .custom-cell {
            height: 16rem;
            padding-left: 0;
            padding-right: 0;
            justify-content: space-around;
            border-style: solid;
            border-width: 0.5px;
        }
    }

    .paging-footer {
        position: relative;
        width: 100%;
        height: var(--footer-height);
        display: flex;
        justify-content: right;
        align-items: center;
        color: var(--nietDarkBlue);

        .row-count-selection {
            position: relative;
            top: 0rem;
            left: 0rem;
            font-weight: 600;
            cursor: pointer;

            .caret-down {
                padding-left: 0.5rem;
            }

            .page-selection {
                position: absolute;
                left: 2.2rem;
                top: -3.3rem;
                height: 8rem;
                width: 3rem;
                background-color: var(--white);
                box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, .50), 0 0.126rem 0.225rem rgba(123, 124, 153, .30);
                z-index: 1000;
                overflow-x: hidden;
                overflow-y: auto;

                .page-selection-item {
                    position: relative;
                    height: 2rem;
                    text-align: center;
                    line-height: 2rem;
                    background-color: var(--white);

                    &.active {
                        color: #f47e3a;
                        background-color: rgba(244, 126, 58, .1);
                    }
                }
            }
        }

        .page-range {
            position: relative;
            padding-left: 2.5rem;
            font-weight: 600;
        }

        .page-toggle {
            position: relative;
            padding-left: 1rem;
            padding-right: 1rem;
            display: flex;

            .angle {
                padding-left: 1.5rem;
                cursor: pointer;

                &.inactive {
                    opacity: 0.33;
                    cursor: unset;
                }
            }
        }
    }
}