.recipientTable {

    .wrapper {
        --header-height: 3.375rem;
        --data-row-height: 5.562rem;
        --footer-height: 3.375rem;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        user-select: none;
        position: relative;
        width: 100%;
        height: 100%;
    }

    .wrapper .header-row {
        position: relative;
        display: flex;
        height: var(--header-height);
        justify-content: space-evenly;
        align-items: center;
        background-color: var(--nietDarkBlue);
        margin-right: 1.5rem;
    }

    .wrapper .header-row .col {
        display: flex;
        align-items: center;
        font-size: 1.05rem;
        font-weight: 700;
        color: var(--nietLightUI);
        width: 100%;
        height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        border-right: 2px solid var(--darkgray-border);
        justify-content: center;
        text-align: center;
        line-height: 1rem;
        padding-left: .05rem;
        padding-right: .05rem;

        &:last-child {
            border-right: unset;
        }
    }

    .wrapper .header-row .col.left-side {
        justify-content: left;
        padding-left: 1rem;
    }

    .wrapper .row-area {
        position: relative;
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 98%;
    }

    .wrapper .row-area .data-row {
        position: relative;
        display: flex;
        height: 3.5rem;
        justify-content: space-evenly;
        border-bottom: 1px solid var(--row-border-bottom);
    }

    .wrapper .row-area .data-row {
        background-color: var(--row-odd);
    }

    .wrapper .row-area .data-row .col {
        position: relative;
        display: flex;
        align-items: center;
        color: var(--nietDarkBlue);
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        padding-left: .8rem;
        padding-right: .8rem;
        border-right: 2px solid var(--darkgray-border);


        .col-name {
            text-overflow: ellipsis;
            overflow: hidden;

            .red-text {
                color: red;
                margin-left: .25rem;
                font-size: 1.75rem;
                font-weight: bold;
            }
        }

        &:last-child {
            border-right: unset;
        }

        .rs-stack {
            padding-right: 1.5rem;
        }

        .title-header {
            margin-bottom: unset;
        }
    }

    .center-input {
        justify-content: center;
        input{
            max-width:5rem;
        }
    }

    .wrapper .row-area .data-row .col.bold-text {
        font-weight: bold;
    }

    .btn-wrapper-left {
        width: unset;
    }

    .last-col {
        margin-right: 0 !important;
    }

    .center-item {
        justify-content: center;
        padding-right: 1.5rem;
    }

    .rs-picker-toggle-wrapper {
        min-width: 0;
    }

    .rs-btn-close {
        display: none;
    }

    .rs-picker-toggle-value {
        font-family: "Raleway", sans-serif;
        font-size: 1rem;
    }

    .rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
        padding-right: 0;
    }

    .field-input .generic-input-component input {
        border-radius: 0.4rem;
        box-shadow: inset 0px 0px 0.5px 0.5px rgba(156, 156, 156, 0.5);
    }

    .center-input {
        .field-input .generic-input-component input {
            text-align: center;
            padding-left: 0;
        }
    }
}

.payout-loading--wrapper {
    height: 100%;
}

.payout-page-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 100%;
}

.payout-teacher-table {

    h2,
    h5 {
        font-weight: 650;
    }

    .payout-recipients-title {
        padding-left: 10rem;
        padding-bottom: 0.5rem;
    }

    .payout-recipients-headers {
        font-weight: 650;
    }

    .button-row {
        display: flex;
        justify-content: right;
        align-items: center;
        padding-top: 2.5rem;

        .button-wrapper {
            display: flex;

        }

        .button-container .btn-wrapper-left {
            margin-right: 1rem;
        }

        .red-text {
            color: red;
            font-weight: bold;
        }

        .large-font {
            font-size: 2rem;
        }
        
    }

    .admin-payout-button-row {
        justify-content: space-between;
    }


    .control-box-wrapper {
        padding-right: 12rem;
        padding-left: 10rem;
    }

    .col-name {
        display: flex;
        align-items: center;
    }

    .flag-icon {
        margin-right: 5px;
        margin-left: -0.3rem;
    }

    .obs-icon {
        margin-left: .25rem;
    }

    .is-changed-checkbox-icon {
        margin-bottom: 2rem;
        margin-left: -1.16rem;
    }

    .is-changed-input-icon {
        position: absolute;
        right: 1px;
        top: 1px;
        height: 0.85rem;
    }

    .dropdown-is-changed {
        .rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
            border: 1px solid orangered;
        }
    }

    // .rs-picker-default .rs-picker-toggle.rs-btn, input.rs-picker-search-bar-input, .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
    //     border: 1px solid orangered;
    // }
    @media screen and (max-width: 1440px) {

        .recipientTable .wrapper .header-row .col {
            font-size: .9rem;
        }
    }

    @media screen and (max-width: 1280px) {
        .control-box-wrapper {
            padding-right: 3rem;
            padding-left: 1rem;
        }

        .payout-recipients-title {
            padding-left: 1rem;
        }

        .recipientTable .wrapper .header-row .col {
            font-size: .85rem;
        }
    }

}