.configureRubric-list {
    .table-wrapper {
        .col {
            //min-width: 12rem;
            justify-content: center;

            &:first-child {
                min-width: unset;
                justify-content: unset;
            }
        }

        .header-row {
            margin-right: unset;

            .col {
                padding-left: unset;
            }

            .col-left {
                padding-left: 1rem;
            }
        }

        .row-area {
            overflow: hidden;

            .order-by {
                &:last-child {
                    padding-right: unset;
                }
            }

            .data-row-border {
                margin-bottom: unset;
            }

            .col {
                padding-left: unset;
                padding-right: unset;
            }

            .col-left {
                padding-left: 1rem;
            }

            .data-row {

                overflow: auto;
                max-height: 15rem;
                padding: unset;
            }

        }
    }
}