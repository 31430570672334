.print-frame {
  display: block;
  visibility: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
}

.print-page {
  height: 100%;
  background-color: #fff;

  .print-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 0.75in;

    .print-banner {
      display: flex;
      flex-direction: row;
      justify-content: center;

      img {
        width: 5in;
      }
    }
  }

  .print-content {
    width: 100%;
    height: 100%;
  }
}

@media screen {
  div.divFooter {
    display: none;
  }
}

@media only print {
  @page {
    /* DIN A4 standard, Europe */
    // margin-right: ;
    //size: 8.5in 11in; this was prventing orientation option from showing in print preview. we want landscape as an option in some cases (rubrics)
    margin: 0.25in;
    margin-right: 0.125in;
    margin-bottom: 0.35in;

    div.divFooter {
      position: fixed;
      display: block;
    }
  }

  html,
  body {
    width: 8.25in;
    height: 11.75in;
    font-size: 12px;
    background: #fff;
    overflow: visible;
  }

  body {
    margin: 0in;
    -webkit-print-color-adjust: exact !important;

    .break-after-block {
      display: block;
      text-align: center;
      width: 100%;
    }

    .break-after {
      page-break-after: always;
      page-break-inside: avoid;
      display: block;
      break-after: always;
      break-inside: avoid;
    }

    .break-before {
      display: block;
      page-break-before: always;
      break-before: always;
    }
  }
  // .landscape {
  //     @page {
  //         size: 11in 8.5in !important; /* Swap width and height for landscape */
  //     }

  //     html, body {
  //         width: 11.75in;
  //         height: 8.25in;
  //     }

  //     .print-page {
  //         transform: rotate(270deg) !important;
  //         transform-origin: left top;
  //         position: absolute;
  //         top: 100%;
  //         left: 0;
  //         width: 100%;
  //         height: auto;
  //     }

  //     .print-header {
  //         height: .75in; /* Adjust if necessary */
  //     }

  //     .print-content {
  //         height: calc(100% - .75in); /* Adjust height for content */
  //     }
  // }
}
