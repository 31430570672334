.role-perm-editor__wrapper {
    height: 70%;
    max-height: 40rem;

    .role-perm-editor__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        h3 {
            align-self: flex-end;
        }
    }

    &__search {
        margin-bottom: 5px;
    }

    .role-perm-editor {
        height: 100%;
        overflow-y: scroll;
    }

    .loading-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 2rem;
    }

    .__table {
        width: 100%;
        display: flow-root;
        margin-top: 5px;


        .__header {
            display: inline-flex;
        }

        .__head {
            position: sticky;
            top: 0;
            background: white;
            z-index: 99;
        }

        .__name {
            .displayName{
                text-overflow: ellipsis;
                overflow: hidden;
                padding-right:.25rem;
                width:100%;
            }
            
            padding-right: 15px;
            width: 20%;
            display: flex;
            flex-direction: row;
            gap: .5rem;
            width: 28rem;
            max-height: 2rem;
            white-space: nowrap;
        }


        .__flex {
            width: 15%;
            white-space: nowrap;
            min-width: 4.5rem;
        }

        .__type {
            width: 5%;
            white-space: nowrap;
            min-width: 3rem;
        }

        .__icon {
            padding-right: 5px;
            margin-right: 2rem;
            display: flex;
            height: 3rem;
            justify-content: flex-end;
            align-items: center;


            .hardDeleteIcon {
                margin-left: 7px;
                color: red;
            }
        }


    }


}

.tag-picker {
    max-width: 50rem;

    input {
        box-shadow: none !important;
    }

    .rs-picker-tag-wrapper {
        padding-bottom: 0px !important;
    }

    .rs-btn {
        display: flex;
    }

    .rs-stack-item svg {
        top: 7px !important;
    }

    .rs-picker-toggle-clean {
        top: 7px !important;
    }
}