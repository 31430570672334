li[aria-label="Navigate backward"] {
  display: none !important;
}

li[aria-label="Navigate forward"] {
  display: none !important;
}

li[aria-label="Send an email"] {
  display: none !important;
}

li[aria-label="Toggle document map"] {
  display: none !important;
}

li[aria-label="Toggle parameters area"] {
  display: none !important;
}

li[aria-label="Toggle FullPage/PageWidth"] {
  display: none !important;
}

li[aria-label="Search in report contents"] {
  display: none !important;
}

.btn-run-report {
  margin-left: 3.5rem;
  z-index: 100;
}

.btn-copy-share-link {
  margin-left: 1rem;
  z-index: 100;
}

.backbtn {
  justify-content: flex-end;
  margin-left: 1rem;
  width: 11rem;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  left: 19rem;
  width: 6em;
  padding: 0.2em 0;
  margin-left: 0.5em;
  color: white;
  background-color: black;
  text-align: center;
  border-radius: 6px;
  z-index: 100;
}

.report-message-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width:100%;
  height:100%;

  .report-message {
    
    margin-left: 19rem;
    padding-top: 1.5rem;
    
  }
}