.loading {
    &-icon-wrapper {
        width: 7rem;
    }

    &.table-spinner {
        margin-top: .4rem;
    }

    &__spinner_1 {
        .svg-spinner {
            -webkit-transition-property: -webkit-transform;
            -webkit-transition-duration: 1.2s;
            -webkit-animation-name: rotate;
            -webkit-animation-iteration-count: infinite;
            -webkit-animation-timing-function: linear;

            -moz-transition-property: -moz-transform;
            -moz-animation-name: rotate;
            -moz-animation-duration: 1.2s;
            -moz-animation-iteration-count: infinite;
            -moz-animation-timing-function: linear;

            transition-property: transform;
            animation-name: rotate;
            animation-duration: 1.2s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }

    &__spinner_2 {
        width: 40px;
        height: 40px;
        border: 6px solid var(--primary-blue);
        border-top: 6px solid var(--lightgray-1);
        border-radius: 50%;

        -webkit-transition-property: -webkit-transform;
        -webkit-transition-duration: 1.2s;
        -webkit-animation-name: rotate;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;

        -moz-transition-property: -moz-transform;
        -moz-animation-name: rotate;
        -moz-animation-duration: 1.2s;
        -moz-animation-iteration-count: infinite;
        -moz-animation-timing-function: linear;

        transition-property: transform;
        animation-name: rotate;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes rotate {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}