.trending-content {

    .trending-content-header {
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
        padding-top: 1rem;
    }

    .loading-icon-container {
        display: flex;
        justify-content: center;
        padding-top: 1rem;
    }

    .trending-img-container {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        justify-content: center;
    }

    @media (max-width: 1600px) {
        .trending-img-container {
            zoom: 0.85;
        }
    }

    @media (max-width: 1400px) {
        .trending-img-container {
            zoom: 0.75;
        }
    }



    .trending-img-title {
        padding-top: 2rem;
        text-align: center;
        border-radius: 0.5rem;
    }

    .img-div-header {
        position: relative;
        margin-right: 1rem;
        margin-left: 1rem;
        padding-top: 2rem
    }

    .img-div-header img {
        display: block;
        border: 0.1rem solid black;
        height: 16rem;
        width: 23rem;
        object-fit: cover;
    }

    .img-div-header div {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: linear-gradient(to bottom, transparent, var(--nietBlue));
        color: #fff;
        padding: 0.75rem;
        margin: 0;
        font-weight: 600;
        font-size: 1.3rem;
    }
}