.observation-note-wrapper {
    position: relative;
    top: 0rem;
    left: 0rem;
    width: 100%;

    .tagmode-message {
        padding-right: 1rem;
        white-space: nowrap;
        font-weight: 600;
        font-size: 1.15rem;
    }

    .top-banner {
        position: relative;
        margin-bottom: 0.05rem;
        top: 0rem;
        left: 0rem;
        width: 100%;
        min-height: 3rem;
        //background-color: rgba(255, 192, 203, 0.34);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .time-and-title {
            font-weight: 400;
            font-size: 14px;
            line-height: 1rem;
            display: flex;

            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &.expand-width {
                width: 95%;
            }

            .time-input {
                padding-left: 0.25rem;
                height: 2rem;
                width: 6rem;
                border-radius: 1px;
                border: 1px solid black;
            }

            .title-input {
                padding-left: 0.25rem;
                height: 2rem;
                min-width: 6rem;
                width: 95%;
                //background-color: palegoldenrod;
                border-radius: 1px;
                border: 1px solid black;
                box-sizing: border-box;
            }
        }

        .control-btn-area {
            //width: 10rem;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .fa-icon {
                margin-left: 0.75rem;
                margin-right: 0.75rem;
                cursor: pointer;
                font-size: 1.5rem;

                &.disable {
                    cursor: not-allowed;
                    opacity: 0.5;
                }
            }

            .control-btn {
                margin-left: 0.5rem;
                margin-right: 0.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 2rem;
                min-width: 5.5rem;
                text-align: center;
                font-weight: 700;
                font-size: 12px;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                border: 1px solid black;
                border-radius: 6px;
                color: #3B3E66;
                cursor: pointer;

                &.able-to-save {
                    background-color: #023c5b;
                    color: white;
                }

                &.disabled {
                    cursor: not-allowed;
                }
            }
        }
    }

    .note-text {
        white-space: pre-wrap;
        word-wrap: break-word;
        position: relative;
        width: 98%;
        padding-bottom: 0.25rem;
        margin-bottom: 0.05rem;
        font-size: 1.083rem;

        &.is-edit {
            width: 100%;
        }

        &.is-tagging-active {
            z-index: 101;
        }

        &.no-select {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .edit-text-area {
            padding: 0.75rem;
            border: 1px solid black;
            border-radius: 4px;
            background-color: white;
            //cursor: url("../../../../content/icons/highlighter.svg") 8 8, text;

            &.different-selection-highlight {
                cursor: crosshair;

                &::selection {
                    font-weight: 800;
                    background-color: rgba(246, 246, 48, 0.8);
                }

                &::-moz-selection {
                    font-weight: 800;
                    background-color: rgba(246, 246, 48, 0.8);
                }
            }

            &.disable-selection-highlight {
                &::selection {
                    background-color: rgba(246, 246, 48, 0.0);
                }

                &::-moz-selection {
                    background-color: rgba(246, 246, 48, 0.0);
                }
            }


            span {
                &::selection {
                    background: transparent;
                }

                &::-moz-selection {
                    background: transparent;
                }

                cursor: pointer;
            }

            sub {
                &::selection {
                    background: transparent;
                }

                &::-moz-selection {
                    background: transparent;
                }

                cursor: pointer;
            }

            .tag-menu-select {
                position: fixed;
                // position: absolute;
                // top: -15rem;
                // right: 0rem;
                width: 27rem;
                height: 17rem;
                background-color: white;
                border: 1px solid black;
                border-radius: 4px;
                z-index: 101;

                .search-bar-wrapper {
                    position: relative;
                    top: 0rem;
                    left: 1.5rem;
                    height: 3.25rem;
                    width: calc(100% - 3rem);
                    border-bottom: 1px solid black;
                    display: flex;

                    input {
                        position: relative;
                        top: 1px;
                        left: 0rem;
                        height: calc(3.25rem - 2px);
                        width: 85%;
                        font-size: 1.25rem;
                        border: none;

                        &::placeholder {
                            opacity: 0.75;
                        }
                    }

                    .mag-icon {
                        float: right;
                        position: relative;
                        width: 15%;
                        height: 1.75rem;
                        align-self: center;
                    }
                }

                .search-items-wrapper {
                    position: relative;
                    top: 0.5rem;
                    left: 1.5rem;
                    width: calc(100% - 3rem);
                    height: calc(100% - 6.5rem);
                    background-color: white;
                    overflow-x: hidden;
                    overflow-y: scroll;

                    .item {
                        position: relative;
                        height: 2rem;
                        width: 100%;
                        background-color: white;
                        display: flex;
                        justify-content: left;
                        align-items: center;
                        cursor: pointer;

                        .checkbox {
                            position: relative;
                            margin-left: 0.5rem;
                            width: 1rem;
                            height: 1rem;
                            border: 1px solid black;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .text {
                            position: relative;
                            top: 1px;
                            margin-left: 0.5rem;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            width: 20rem;
                        }
                    }

                    // &::-webkit-scrollbar-track {
                    //     border-left: 1px solid black;
                    //     border-right: 1px solid black;
                    //     background-color: #ffffff;
                    // }

                    // &::-webkit-scrollbar {
                    //     width: 16px;
                    //     background-color: #ffffff;
                    //     border-left: 1px solid black;
                    //     border-right: 1px solid black;
                    // }

                    // &::-webkit-scrollbar-thumb {
                    //     border-radius: 7px;
                    //     background-color: #666666;
                    //     border: 4px solid transparent;
                    //     background-clip: content-box;
                    // }

                    // &::-webkit-scrollbar-button {
                    //     border-style: solid;
                    //     width: 16px;
                    //     height: 10px;
                    // }

                    // &::-webkit-scrollbar-button:vertical:decrement {
                    //     border-width: 0 7px 14px 7px;
                    //     border-color: transparent transparent #9e9e9e transparent;
                    //     border-top: 1px solid black;
                    // }

                    // &::-webkit-scrollbar-button:vertical:increment {
                    //     border-width: 14px 7px 0 7px;
                    //     border-color: #9e9e9e transparent transparent transparent;
                    //     border-bottom: 1px solid black;
                    // }
                }

                .tag-menu-footer {
                    position: relative;
                    top: 0.7rem;
                    height: 2rem;
                    left: 1.5rem;
                    width: calc(100% - 3rem);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .clear-all {
                        margin-left: 0.5rem;
                        cursor: pointer;
                    }

                    .btns-wrapper {
                        justify-self: right;
                        display: flex;

                        .control-btn {
                            margin-left: 0.5rem;
                            margin-right: 0.5rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 2rem;
                            min-width: 5.5rem;
                            text-align: center;
                            font-weight: 700;
                            font-size: 12px;
                            padding-left: 0.5rem;
                            padding-right: 0.5rem;
                            border: 1px solid black;
                            border-radius: 6px;
                            color: #3B3E66;
                            cursor: pointer;

                            &.able-to-save {
                                background-color: #023c5b;
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }

    .locked-message {
        position: relative;
        font-size: 11px;
        font-style: italic;
        margin-bottom: 0.05rem;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .fa-lock-style {
            padding-right: 0.5rem;
        }
    }

    .tag-token-area {
        position: relative;
        width: 100%;
        height: auto;
        padding-top: 1rem;
        display: flex;
        flex-wrap: wrap;

        .tag-token {
            margin-right: 1rem;
            margin-bottom: 1rem;
            padding: 0.25rem 1rem 0.25rem 1rem;
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            border: 1px solid black;
            border-radius: 4px;
            color: black;
            cursor: pointer;

            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &.apply-cursor {
                cursor: pointer;
            }

            &.remove-cursor {
                cursor: default;
            }

            &.active {
                background-color: var(--hover-tagged-note-color);
            }
        }
    }

    .media-area {
        position: relative;
        width: 100%;
        //background-color: blue;
        display: flex;
        flex-wrap: wrap;
        z-index: 0;

        .upload-media-btn {
            position: relative;
            padding: 1.1rem 0rem 1.1rem 0rem;
            width: 11rem;
            min-height: 1rem;
            margin: 1rem 0 1rem 0;
            background-color: white;
            color: rgb(0, 60, 91);
            border: 1px solid black;
            border-radius: 6px;
            font-size: 11px;

            &.disable {
                cursor: not-allowed;
                opacity: .5;
            }

            &::after {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 0.2rem;
                content: '+';
                width: 1.5rem;
                height: 1.5rem;
                background-color: rgb(0, 60, 91);
                color: white;
                font-size: 2rem;
                font-weight: 400;
                border-radius: 50%;
                left: -0.75rem;
                top: -0.75rem;
                //z-index: 2000;
            }
        }

        .upload-media-pane {
            position: relative;
            margin-right: 0.5rem;

            &.focused {
                z-index: 100;
                // background-color: white;
                // border: 1px solid black;
                // border-radius: 4px;
                // border-top-right-radius: 0;
                // border-top-left-radius: 0;

                >.tagmode {
                    border: 2px solid #fcb34c;
                    border-top-right-radius: 4px;
                    border-top-left-radius: 4px;
                }
            }

            .tagmode {
                position: absolute;
                top: 0rem;
                left: 0rem;
                width: 100%;
                height: 93%;
                cursor: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBzdHlsZT0iZmlsbDogIzAxM2M1YiIgZD0iTTAgODBWMjI5LjVjMCAxNyA2LjcgMzMuMyAxOC43IDQ1LjNsMTc2IDE3NmMyNSAyNSA2NS41IDI1IDkwLjUgMEw0MTguNyAzMTcuM2MyNS0yNSAyNS02NS41IDAtOTAuNWwtMTc2LTE3NmMtMTItMTItMjguMy0xOC43LTQ1LjMtMTguN0g0OEMyMS41IDMyIDAgNTMuNSAwIDgwem0xMTIgMzJhMzIgMzIgMCAxIDEgMCA2NCAzMiAzMiAwIDEgMSAwLTY0eiIvPjwvc3ZnPg=='), auto;



                &.without-cursor {
                    cursor: default;

                    &:hover {
                        z-index: unset;
                    }
                }

                &:hover {
                    //border: 1px solid rgba(0, 0, 0, 0.527);
                    border: 2px solid #fcb34c;
                    border-top-right-radius: 4px;
                    border-top-left-radius: 4px;
                    z-index: 1001;
                }
            }

            .control-panel {
                position: relative;
                top: -1rem;
                height: 2rem;
                width: 100%;
                background-color: #f4f1ed;

                &.active {
                    border-left: 2px solid #fcb34c;
                    border-right: 2px solid #fcb34c;
                }

                .control-btns {
                    position: relative;
                    float: left;

                    padding-top: .25rem;
                    padding-left: .25rem;

                    a {
                        color: #013c5b;
                    }

                    .fa-icon {
                        margin-right: 0.4rem;
                        cursor: pointer;

                        &:hover {
                            color: #006a90;
                            transform: scale(1.2);
                        }

                        &.disable {
                            cursor: not-allowed;
                            opacity: .5;

                            &:hover {
                                color: rgb(0, 60, 91);
                                transform: scale(1);
                            }
                        }
                    }
                }

                .tag-abbr {
                    position: relative;
                    float: right;
                    font-size: 0.75rem;
                    font-weight: 700;
                    color: black;
                    line-height: 1.5rem;
                    padding-top: .25rem;
                    padding-right: .25rem;

                    &.active {
                        background-color: var(--hover-tagged-note-color);
                    }
                }
            }
        }
    }

    //exists independently outside of nested tree structure
    .tagged {
        background-color: var(--tagged-note-color);

        &:hover {
            background: var(--hover-tagged-note-color);
            box-shadow: inset 0 0 1px #cb9446;
        }

        &.active {
            background: var(--hover-tagged-note-color);
            box-shadow: inset 0 0 1px #cb9446;
        }

        sub {
            padding-left: 0.25rem;
            font-size: 0.66rem;
            font-weight: 800;
            color: black;
        }
    }

    .tagged-no-focus {
        background-color: var(--tagged-note-color);

        &:hover {
            background: var(--tagged-note-color);
        }

        &.active {
            background: var(--tagged-note-color);
        }

        sub {
            padding-left: 0.25rem;
            font-size: 0.66rem;
            font-weight: 800;
            color: black;
        }
    }

    .highlighted {
        background-color: rgba(246, 246, 48, 0.8);

        &:hover {
            background-color: rgba(246, 246, 48, 0.8);
        }
    }

    .tagged-and-focused {
        background-color: var(--hover-tagged-note-color);
        font-weight: 600;

        &:hover {
            background: var(--hover-tagged-note-color);
            box-shadow: inset 0 0 1px #cb9446;
        }

        &.active {
            background: var(--hover-tagged-note-color);
            box-shadow: inset 0 0 1px #cb9446;
        }

        sub {
            padding-left: 0.25rem;
            font-size: 0.66rem;
            font-weight: 800;
            color: black;
        }
    }

    .dialogue-section {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.media-dialogue-wrapper {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-height: 30rem;
    max-width: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
        position: relative;
        width: 100%;
        height: 100%;
    }
}