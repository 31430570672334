.observer-table {
    .observer-weights-table {
        width: 100%;
        border-collapse: collapse;

        .row-header {
            font-weight: bold;
            font-size: 1rem;
            width: 19%;
            min-width: 8.5rem;
            padding-top: .6rem;
        }
    }

    .observer-weights-table th,
    .observer-weights-table td {
        padding-bottom: .5rem;
    }

    .observer-weights-table th {
        text-align: center;
    }

    .observer-weights-table td {
        display: flex;
        text-align: left;
        width: 12%;
    }

    tr {
        display: flex;

        .total-weight {
            font-weight: bold;
        }

        .isDisabled {
            background-color: var(--darkgray-border-2);
            border-radius: .5rem;
        }
    }

    .group-by-picker {
        padding-right: 2rem;
    }

    .rs-picker-toggle-wrapper {
        width: 50%;
        min-width: 0rem;
    }

    .rs-picker-toggle-clean {
        display: none;
    }

    .rs-picker-menu.rs-picker-select-menu,
    .rs-picker-menu.rs-picker-check-menu {
        width: 4rem;
    }

    .main-header {
        th {
            width: 20%;
            font-size: 1.2rem;
            border-bottom: 1px solid black;
            margin-right: 3rem;
            margin-bottom: 1rem;
        }

        .blank-header {
            width: 19%;
            margin-right: 0;
            border-bottom: 0px;
        }
    }

    .sub-header {
        margin-left: 0;

        th {
            width: 100%;
            max-width: 8.5rem;
        }

        .blank-header-titles {
            width: 10rem;
            margin-right: 6.5rem;
        }
    }

    @media (max-width: 600px) {
        .observer-weights-table {
            font-size: 12px;
        }
    }
}