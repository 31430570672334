.observation-domain-weights-table {
    .observation-domain-weights-table {
        width: 100%;
        border-collapse: collapse;

        .row-header {
            font-weight: bold;
            font-size: 1rem;
            width: 25%;
        }

        thead {
            th {
                width: 20%;
                text-align: center;
            }
        }

        input {
            text-align: center;
            padding-left: 0; 
        }

        .input-area {
            //width: 35%;
        }
    }
    
    .observation-domain-weights-table th,
    .observation-domain-weights-table td {
        padding-bottom: .5rem;
        padding-right: 5rem;
        text-align: left;
    }

    .align-center {
        text-align: center !important;
        //width: 35%;
    }
    
    @media (max-width: 600px) {
        .observation-domain-weights-table {
            font-size: 12px;
        }
    }

    @media (max-width: 1380px) {
        .align-center {
            width: 35%;
        }
    }
}