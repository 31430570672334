.reset-file-btn {
    display: flex;
    flex-direction: row;
    height: 3rem;
    gap: 1rem;
    margin-bottom: .5rem;

    .btn_control {
        border: unset;
        padding: 1rem 1rem 1rem 1rem;
        width: 9rem;
        font-size: .9rem;

    }
}