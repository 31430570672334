.content {
  position: relative;
  height: 37vw;
  margin-top: -36px;

  &__background,
  &__background__shadow,
  &__background__image,
  &__area {
    position: absolute;
    top: 0;
    bottom: 0;
  }

  &__background {
    left: 0;
    right: 0;

    &__shadow {
      left: 0;
      background: #003c5b;
      width: 30%;
      z-index: 2;

      &:before {
        content: '';
        position: absolute;
        z-index: 10;
        background: #003c5b;
        background: -moz-linear-gradient(to right, rgba(0, 60, 91, 1) 0%, rgba(0, 60, 91, 0.85) 50%, rgba(255, 255, 255, 0) 100%);
        background: -webkit-linear-gradient(to right, rgba(0, 60, 91, 1) 0%, rgba(0, 60, 91, 0.85) 50%, rgba(255, 255, 255, 0) 100%);
        background: linear-gradient(to right, rgba(0, 60, 91, 1) 0%, rgba(0, 60, 91, 0.85) 50%, rgba(255, 255, 255, 0) 100%);
        top: 0;
        bottom: 0;
        left: 100%;
        width: 275px;
      }
    }

    &__image {
      right: 0;
      width: 70%;
      height: 100%;
      background-position: center 10%;
      background-size: cover;
      z-index: 1;
    }
  }

  &__area {
    left: 0;
    right: 0;
    height: 100%;
    z-index: 3;

    &__container {
      padding: 30px 70px;
      color: wheat;
    }
  }

  &__titleHeader {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }

  &__title {
    font-size: 3rem;
    line-height: 4rem;
    color: #fff;
    font-weight: 700;
    max-width: 42rem;
    /*display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;*/
    padding-top: 1rem;
  }

  &__titleHeader .viewerActionButtons svg.MuiSvgIcon-root {
    fill: white;
    font-size: 3rem;
  }

  .content__titleHeader button.viewerActionButtons:hover,
  .content__titleHeader button.viewerActionButtons:active {
    transform: translateY(0px) !important;
    background: transparent;
    cursor: inherit;
  }

  &__description {
    font-size: 1.25rem;
    color: #fff;
    margin-top: 20px;
    max-width: 500px;
  }

  &__description .viewerActionButtons svg.MuiSvgIcon-root {
    fill: white;
    font-size: 3rem;
  }

  &__descriptionText {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 12;
    margin-bottom: 2rem;
  }

  &__close {
    color: #003c5b;
    width: 40px;
    height: 40px;
    background: transparent;
    outline: none;
    border: none;
    position: absolute !important;
    top: 30px;
    right: 30px;
  }
}