$mediaBreakpoint: 84em;

.dashboard {

  .title {
    font-size: 1.15rem;
    color: var(--nietDarkBlue);
  }

  .icon-darkblue {
    fill: var(--nietDarkBlue);
  }

  .icon-orange {
    path {
      fill: var(--nietOrange);
    }
  }

  .icon-red {
    path {
      fill: #C61616;
    }
  }

  .title-and-icon {
    display: flex;
    align-items: center;
    width: 100%;

    >div {
      flex-grow: 1;
    }

    >h6 {
      color: var(--nietBlue);
      margin-right: 1rem;
    }


    >svg {
      cursor: pointer;

      path {
        fill: var(--nietDarkBlue);
      }

      margin-left: 6px;
      position: relative;
      top: 5%;

      &.disabled {
        path {
          fill: var(--lightgray-1);
        }
      }
    }

    >.query-stats-icon {
      height: auto;
      width: 1.5rem;
    }
  }

  .status {
    padding-top: 0.5rem;
    margin: 0;
    color: black;
    font-weight: 500;
    text-align: right;
    position: absolute;
    right: 0;
    width: 50%;

    &.padding-right {
      padding-right: 3rem;
    }

    &.margin-right {
      margin-right: 3rem;
    }
  }

  .vl {
    border-left: 1px solid black;
    height: 500px;
    position: absolute;
    left: -0.5px;
    top: 0;
  }

  .dashboard-wrapper {
    height: 100%;
  }

  .dashboard-profile {
    position: absolute;
    padding-left: 2rem;
    display: flex;

    .profile-image {
      width: 60px;
      height: 60px;
      margin-top: 10px;
      border-radius: 50%;
      border: 1px solid var(--nietBlue);
      box-shadow: 0 0 0 2px var(--nietBlue);
      background-color: var(--nietLightGray);

      >img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }

      >svg {
        width: 60%;
        height: 60%;
        position: relative;
        top: 20%;
        left: 20%;

        >path {
          fill: var(--nietBlue);
        }
      }
    }

    .profile-info {
      padding-left: 2rem;

      >h1 {
        color: var(--nietDarkBlue);
        font-weight: bold;
      }

      .subtitle-and-icon {
        display: flex;
        align-items: center;

        >h6 {
          color: var(--nietDarkBlue);
          margin-right: 1rem;
        }

        >svg {
          width: 1rem;
          height: 1rem;
          position: relative;
          top: -0.3rem;
          margin-right: 0.6rem;

          >path {
            fill: var(--nietDarkBlue);
          }
        }
      }
    }
  }

  .screen__content {
    padding: 0;

    .screen-wrapper {
      background: transparent;
      position: relative;
      padding: 0;

      .control-box-wrapper {
        height: calc(100% - var(--footer-height) / 2);
        padding-top: 100px;
        background: transparent;
        flex-wrap: nowrap;

        .control-box {
          overflow-y: auto;
          overflow-x: clip;
          height: 100%;
          background: transparent;
          padding: 0 1rem;

          &::-webkit-scrollbar {
            width: 18px;
          }

          &::-webkit-scrollbar-thumb {
            border: 6px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
            border-radius: 10px;
            background-color: var(--lightgray-1);
          }

          &.no-padding {
            padding: 0;
          }

          &.flex-d-inherit {
            flex-direction: inherit;
          }

          &.padding-double {
            padding: 0 3rem;
          }

          &.padding-left {
            padding-right: 0;
            padding-left: 1.5rem;
          }

          &.padding-right {
            padding-right: 3;
            padding-left: 0rem;
          }

          &.no-margin {
            margin: 0;
          }

          &.margin-double {
            margin: 0 3rem;
          }

          &.margin-left {
            margin-right: 0;
            margin-left: 3rem;
          }

          &.margin-right {
            margin-right: 3rem;
            margin-left: 0rem;
          }


        }


        .box-flex {
          //max-width: 50%;
          min-width: 25rem;
          width: 100%;

          .observation-box {
            padding-left: 1.70rem;
            padding-right: .5rem;
            overflow-y: hidden;
          }
        }

        .dashboard-section-wrapper {
          display: flex;
          flex-direction: column;
          margin-bottom: 2rem;

          &.chart-wrapper {
            align-items: center;
            flex-direction: column;
            padding-right: 1.5rem;
            overflow-y: auto;
            overflow-x: hidden;
          }

          .charts {
            width: 100%;
            height: 150px;
          }
        }
      }
    }

    .no-records {
      width: 100%;
      display: flex;
      text-align: left;
      justify-content: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
      color: var(--nietDarkBlue);
    }
  }



  .notification-list {
    margin-bottom: 0;
    overflow-y: auto;
    max-width: 60rem;

    .loading-wrapper {
      display: flex;
      justify-content: center;
      padding-top: 2rem;
    }

    &::-webkit-scrollbar {
      width: 18px;
    }

    &::-webkit-scrollbar-thumb {
      border: 6px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 10px;
      background-color: var(--lightgray-1);
    }

    .notification-list__item {
      background: #ffffff;
      border-radius: 0.4rem;
      display: flex;
      flex-direction: column;
      margin-bottom: 1.2rem;
      padding: 0.8rem 1.2rem;
      position: relative;
      width: 100%;
      border: var(--nietLightGray) 2px solid;

      .remove-list__remove__icon {
        position: absolute;
        cursor: pointer;
        right: 0.9rem;
        top: 0.9rem;

        svg {
          height: 1.2rem;
        }
      }

      .notification-list__item__header {
        display: flex;
        margin-bottom: 0.8rem;

        .notification-list__item__header__icon {
          svg {
            position: relative;
            top: -0.1rem;
            left: -0.5rem;
            height: 3rem;
          }

          .message>path {
            fill: var(--nietBlue);
          }
        }

        .notification-list__item__header__title {
          font-size: 12px;
          font-weight: bold;
          margin: 0;
          color: var(--nietBlue);
        }

        .notification-list__item__header__date {
          font-size: 10px;
          margin: 0;
          color: var(--lightgray-1);
        }
      }

      .disabled-notification {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          cursor: pointer;
        }
      }

      .notification-list__item__content {
        font-size: 12px;
        font-weight: 400;
        font-family: Arial, Helvetica, sans-serif;
        color: var(--nietBlue);
        margin: 0;

        >p {
          margin: 0;
        }

        ul {
          margin-left: 1rem;
        }
      }
    }
  }

  .dashboard-table {

    .custom-header {
      height: 2rem;

      .custom-header-cell {
        height: 100%;
        border-right: none;
        font-size: 10px;
        padding-left: 5px;
      }

      :nth-child(1) {
        padding-left: 1rem;
      }
    }

    .table-wrapper {
      --footer-height: 0;
      height: unset;

      .header-row .col-header-actions {
        min-width: 0%;
        font-size: 10px;
      }

      .row-area {
        .custom-cell {
          height: 2rem;
          padding-top: 0.2rem;
        }

        .data-row .col {
          font-size: 10px;
          padding-left: 5px;
          border-right: 0;

          >svg {
            fill: var(--lightgray-1);
          }

        }

        .data-row .col:nth-child(1) {
          padding-left: 1rem;
        }
      }

      .data-row-border {
        border: 0;
      }
    }



    .paging-footer {
      --footer-height: 2rem;
      justify-content: left;
      padding-top: 0.5rem;
      font-size: 12px;
      position: absolute;

      .row-count-selection {
        display: none;
      }

      .page-range {
        padding-left: 0;
        color: var(--nietBlue);
      }

      .page-toggle {
        padding: 0;

        .angle {
          padding-left: 1rem;

          &.active {
            color: var(--nietBlue);
          }
        }
      }
    }
  }

  .content-dashboard {
    scrollbar-gutter: stable;

    &::-webkit-scrollbar {
      width: 18px;
    }

    &::-webkit-scrollbar-thumb {
      border: 6px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 10px;
      background-color: var(--lightgray-1);
    }

    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: auto;

    >.contentDisplay {
      position: relative;
      width: calc(100% - 2rem);
      margin: 0;
    }

    .categoryWrapper {
      .categoryTitle {
        font-size: 14px;
      }

      .slider-wrapper {
        zoom: .75;
        padding: 2rem 0;
      }
    }

    .loading-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}