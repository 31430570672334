* {
  padding: 0rem;
  margin: 0rem;
}


:root {
  --nietBlue: #00548b; //0, 84, 139
  --primary-blue: #00548b;
  --nietLightBlue: #9ad7d8; //154, 215, 216 
  --nietDarkBlue: #003c5b; //0, 60, 91
  --nietLightGray: #e5e2da; //229, 226, 218
  --nietYellow: #fcb34c; //252, 179, 76
  --nietOrange: #f47e3a; //244, 126, 58

  --nietUtilityGray: #fafafa;
  --nietLightUI: #f4f1ed;
  --nietDarkUI: #eae4db;
  --darkgray-1: #e5e2daa9;
  --darkgray-2: #e5e2da54;
  --lightgray-1: #8d8d8dee;
  --lightgray-2: #e5e2da54;
  --blockui: #00000069;
  --darkgray-border: #D5D2CF;
  --darkgray-border-2: #9E9E9E;
  --row-border-bottom: #aabac0;
  --row-odd: #f4f1ed;
  --row-even: #eae4db;

  --first: $nietDarkBlue;
  /*#181e33;*/
  --second: $nietOrange;
  /*$black;*/
  --blue: $nietBlue;
  --darkBlue: #003c5b;
  --indigo: #7420ff;
  --purple: #793de6;
  --pink: #fc26a4;
  --red: #f83245;
  --error: var(--red);
  --orange: $nietOrange; //#f4772e;
  --yellow: $nietYellow; //#EAA934;
  --green: #49E287;
  --teal: #18e1a5;
  --cyan: #11c5db;

  --header-height: 5rem;
  --footer-height: 7rem;
}

html {
  font-size: 12px !important;
  overflow-x: hidden;
  overflow-y: hidden;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--nietDarkBlue)
  }

}

@media (max-height: 650px) {
  html {
    font-size: 8px !important;
  }
}

.App {
  color: var(--nietDarkBlue);
}

ul.menu {
  list-style: none;
}

ul.menu li {
  display: inline;
  padding: 12px;
}

ul.menu a {
  color: #ffffff;
}



.warning {
  width: 800px;
  margin: 24px auto;
  border: 5px solid #ff0000;
  border-radius: 15px;
}

.warning h1 {
  margin-top: 0;
  color: white;
  font-size: 48px;
  line-height: 96px;
  background-color: #ff0000;
  text-transform: uppercase;
}

.warning h2 {
  font-size: 36px;
  text-transform: uppercase;
}


.login-form {
  display: inline-block;
  width: 20em;
}

.full-width-input {
  display: block;
  text-align: right;
}

.full-width-input input {
  width: 12em;
  padding: 4px 8px;
  margin: 4px 8px;
  border: 1px solid #aaaaaa;
  border-radius: 0;
}

.error {
  color: var(--red);

  &.columns {
    display: flex;
    flex-direction: column;
    gap: .25rem;
  }
}

.warn {
  color: var(--orange);

  &.columns {
    display: flex;
    flex-direction: column;
    gap: .25rem;
  }
}

@font-face {
  font-family: 'Cedarville Cursive';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/cedarvillecursive/v17/yYL00g_a2veiudhUmxjo5VKkoqA-B_nuIrpw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}