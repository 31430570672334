.survey-builder-detail-wrappers {
    .control-box {
        .detail-field {
            margin-bottom: 1.8rem;
        }

        .loading-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 9rem;
        }
    }
}

.custom-dialog {
    .dialog__footer {
        justify-content: center;
    }
}

.survey-template-dialog-content {
    .dialog-content {
        margin-bottom: 2.25rem;

        .table-wrapper {
            .header-row {
                .col-sub-header {
                    display: flex;
                    padding-top: unset;

                }

                .col-sub-header>div {
                    margin-left: .5rem;
                    font-weight: 400;

                }
            }

            .row-area {
                .data-row {
                    height: 3rem;

                    .col {
                        display: flex;


                        &:first-child {
                            justify-content: center;
                            padding: unset;
                        }

                        .field-input {
                            height: 100%;

                            .grid-inputfield {
                                &:first-child {
                                    display: none;
                                }
                            }

                            .generic-input-component {
                                height: 100%;

                                .input-text-wrapper {
                                    height: 100%;
                                    padding: .25rem;
                                    background-color: white;

                                    input {
                                        height: 100%;
                                        border: none;
                                        box-shadow: unset;

                                        &:focus {
                                            border: 1px solid black;
                                        }
                                    }
                                }
                            }

                            .error-wrapper,
                            .title-header {
                                display: none;
                            }
                        }
                    }

                    .col-inputfield {
                        padding: unset;
                    }

                    .actions {
                        .disableAction {
                            opacity: .3;
                            cursor: not-allowed;
                        }
                    }

                }
            }
        }
    }

    .dialog-footer-wrapper {
        display: flex;

        .btn_control {
            display: flex;
            align-items: center;
            margin-right: 5rem;
            padding: 0 1rem 0 1rem;
            height: 3.5rem;
            line-height: unset;
        }

        .checkbox-wrapper {
            .field-input {
                width: unset;
            }

            .title-header {
                margin-bottom: unset;
            }

            .error-wrapper {
                display: none;
            }

            .cbx_control {
                width: unset;
                align-items: center;
                padding: unset;

                div {
                    height: 1.25rem;

                    input {
                        height: 100%;
                    }
                }
            }

            gap: 2rem;
            display: flex;
            align-items: center;
        }

        .disable {
            opacity: .6;
        }
    }
}

.survey-field-table {
    .detail-table-wrapper {
        table {

            tbody,
            thead {
                tr {
                    th {
                        padding: .5rem;
                    }

                    td {
                        padding: .5rem;

                    }
                }
            }

        }

        .__table {
            width: 90%;

            .__name {
                min-width: 18rem;
            }

            .__updown {


                svg {
                    cursor: pointer;
                    width: 1rem;
                    height: 1.5rem;

                }

                svg.disabled {
                    path {
                        fill: gray;
                    }

                    cursor: default !important;
                }
            }


            .__action {
                display: flex;
                flex-direction: row;
                gap: 1rem;

                svg {
                    cursor: pointer;
                }


            }
        }
    }
}