.no-height {
    &.screen-wrapper {
        height: unset;
    }

    .observation-config-container {
        width: 66%;
        padding: 1.5rem;

        &.fullscreen {
            width: 100%;
            padding-right: 3.5rem;
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        .tab-container {
            display: flex;

            .tab {
                padding: .5rem 1rem .5rem 1rem;
                position: relative;
                width: 10rem;
                text-align: center;
                background-color: white;
                border-radius: 10px 10px 0 0;
                border-bottom: 1px solid black;
                cursor: pointer;
            }

            .active {
                border-top: 1px solid black;
                border-left: 1px solid black;
                border-right: 1px solid black;
                border-bottom: unset;
                border-bottom-left-radius: unset;

                &::after {
                    content: '';
                    position: absolute;
                    right: -10px;
                    bottom: 0;
                    width: 10px;
                    height: 10px;
                    border: 1px solid white;
                    z-index: 3;
                }

                span {
                    &::after {
                        content: '';
                        position: absolute;
                        right: -10px;
                        bottom: 0;
                        width: 10px;
                        height: 10px;
                        border-left: 1px solid black;
                        border-bottom: 1px solid black;
                        border-bottom-left-radius: 10px;
                        z-index: 4;
                    }
                }
            }

            .teacher-tab {
                &::before {
                    content: '';
                    position: absolute;
                    left: -10px;
                    bottom: 0;
                    width: 10px;
                    height: 10px;
                    border: 1px solid white;
                    z-index: 3;
                }

                span {
                    &::before {
                        content: '';
                        position: absolute;
                        left: -10px;
                        bottom: 0;
                        width: 10px;
                        height: 10px;
                        border-right: 1px solid black;
                        border-bottom: 1px solid black;
                        border-bottom-right-radius: 10px;
                        z-index: 4;
                    }
                }
            }

            .hidden-tab {
                flex-grow: 1;
                cursor: unset;
                display: flex;
                justify-content: flex-end;

                .toggle-wrapper {
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    font-size: 1.25rem;

                    .toggle-checkbox {
                        position: relative;
                        width: 3.5rem;
                        height: 1.7rem;
                        margin-left: .5rem;
                        margin-right: .5rem;

                        input {
                            opacity: 0;
                            width: 0rem;
                            height: 0rem
                        }

                        .switch-circle {
                            position: absolute;
                            cursor: pointer;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: var(--nietLightGray);
                            -webkit-transition: .4s;
                            transition: .4s;
                            border-radius: 25rem;
                        }

                        .switch-circle:before {
                            position: absolute;
                            content: "";
                            width: 1.70rem;
                            height: 1.70rem;
                            left: 0;
                            background-color: var(--nietDarkBlue);
                            -webkit-transition: .4s;
                            transition: .4s;
                            border-radius: 25rem;
                            transition: all .3s ease-in;
                        }

                        input:checked+.switch-circle {
                            background-color: var(--nietLightBlue);
                            transition: all .3s ease-in;
                        }

                        input:checked+.switch-circle:before {
                            left: 1.8rem;
                            transition: all .3s ease-in;
                        }
                    }
                }
            }
        }

        .observation-config {
            border-left: 1px solid black;
            border-right: 1px solid black;
            border-bottom: 1px solid black;
            padding: 1rem;
            padding-left: 2rem;

            &.disabled {
                opacity: 0.5;
                pointer-events: none;
            }

            .observation-component {
                display: flex;
                gap: 5rem;

                .config-wrapper {
                    width: 50%;

                    .observation-config-subheader {
                        font-weight: bold;
                        font-size: 1.25rem;
                        margin-bottom: 1rem;
                    }

                    .select-loading-wrapper {
                        height: 2.3rem;
                        display: flex;
                        align-items: center;
                    }
                }

                .display-config-wrapper {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;

                    .user-position-row {
                        width: 50%;



                        &:nth-child(even) {
                            padding-left: 2.5rem;
                        }

                        &:nth-child(odd) {
                            padding-right: 2.5rem;
                        }
                    }
                }
            }

            .observation-component-wrapper {
                width: 100%;



                .admin-cert-config-wrapper {
                    display: flex;
                    gap: 5rem;

                    .rs-stack {
                        height: 100%;

                        .rs-stack-item {
                            .rs-btn-close {
                                top: 8px;
                            }

                            svg {
                                top: 8px;
                            }
                        }
                    }

                    .rs-picker-search-input {
                        input {
                            box-shadow: none;
                        }
                    }

                    .admin-cert-config-left-col {
                        width: 50%;
                    }

                    .admin-cert-config-right-col {
                        width: 50%;
                    }
                }
            }
        }

        .loading-wrapper {
            margin-top: 10rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }

    }

}