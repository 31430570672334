.item {
  flex: 0 0 19.7%;
  transition: transform 300ms ease 100ms;
  margin: 0 2px;
  position: relative;
  outline: none;
  background-color: #cccccc;
  height: 15rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-width: 25rem;

  img {
    width: 100%;
  }

  .title-icon {
    width: 1.5rem;
    height: 1.5rem;
    float: right;
    margin-left: .5rem;

    path {
      fill: #FFF;
    }
  }


  .item:focus,
  .item:active {
    outline: none;
  }

}