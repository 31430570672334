.circle-check-wrapper {
    padding-right: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;


    .circle-check {
        font-size: 1.25rem;
    }

    .exclude-score-tooltip {
        position: relative;
        left: 0.25rem;
        bottom: 0.75rem;
        cursor: help;
    }
}

.show-tooltip-wrapper {
    padding-left: 1.03rem;
}

.table-long-text-box {
    input {
        width: 24.5rem !important;
    }
}

.table-wrapper {
    --header-height: 3.375rem;
    --data-row-height: 5.562rem;
    --footer-height: 3.375rem;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    position: relative;
    width: 100%;
    height: 100%;
    //border: 1px solid rgba(0, 0, 0, 0.072);

    .row-area-wrapper {
        max-height: 98%;
        position: relative;
    }

    .no-records {
        width: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        margin-top: 2rem;
        margin-bottom: 2rem;

    }

    .header-row {
        position: relative;
        display: flex;
        height: var(--header-height);
        justify-content: space-evenly;
        align-items: center;
        background-color: var(--nietDarkBlue);
        margin-right: 1.25rem;

        .col {
            display: flex;
            align-items: center;
            font-size: 1.05rem;
            font-weight: 700;
            color: var(--nietLightUI);
            width: auto;
            height: 100%;
            cursor: pointer;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding-left: 1rem;
            border-right: 2px solid var(--darkgray-border);
            //border: 1px solid white;

            &:hover>.arrow {
                opacity: 0.66;
            }

            .arrow {
                padding-left: 0.5rem;
                opacity: 0;
                transition: opacity 0.15s ease-out;

                &.active {
                    opacity: 1;
                }
            }


        }

        .col-indicator-header {
            justify-content: center;
            white-space: pre-wrap;
            text-align-last: center;
            font-weight: 600;
            text-align: center;
            padding-right: 1em;
            padding-left: 1em;
        }

        .col-header-actions {
            text-align: center;
            justify-content: center;
            border-right: unset;
            padding-left: unset;
            position: relative;

            .select-all {
                position: absolute;
                font-size: .75rem;
                text-decoration: underline;
                bottom: 0;
                cursor: pointer;
            }
        }

        .col-left {
            display: flex;
            justify-content: left;
        }

        .col-header-left {
            text-align: left;
        }

        .col-wrap {
            white-space: pre-wrap;
            display: flex;
            text-align: center;
            padding: unset;
        }

        .col-sub-header {
            display: block;
            padding-top: .8em;
        }

        .remove-cursor {
            cursor: default !important;
        }
    }

    .custom-header {
        padding-left: 0;
        height: 5.5em;
    }

    .row-area {
        position: relative;
        overflow-y: scroll;
        max-height: 98%;

        .data-row {
            position: relative;
            display: flex;
            height: var(--data-row-height);
            justify-content: space-evenly;
            border-bottom: 1px solid var(--row-border-bottom);

            &:nth-child(odd) {
                background-color: var(--row-odd);
            }

            &:nth-child(even) {
                background-color: var(--row-even);
            }


            .col {
                display: flex;
                align-items: center;
                color: var(--nietDarkBlue);
                font-weight: 500;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                text-align: left;
                padding-left: 1rem;
                border-right: 2px solid var(--darkgray-border);

                .text-wrap {
                    white-space: unset;
                }

                .text-center {
                    text-align: center;
                }

                .text-right {
                    text-align: right;
                }

                .disableAction {
                    opacity: .3;
                    cursor: not-allowed;
                }

                .text-wrapper {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                &.actions {
                    display: flex;
                    gap: 1rem;
                    justify-content: center;
                    border-right: unset;
                    padding-left: unset;

                    .faIcon {
                        font-size: 1.5rem;
                        font-weight: 400;
                        cursor: pointer;
                    }

                    svg,
                    .action {
                        cursor: pointer;
                        height: 1.4rem;

                        img {
                            height: 100%
                        }
                    }

                    .disableAction {
                        opacity: .3;
                        cursor: not-allowed;

                        svg {
                            cursor: not-allowed;
                        }
                    }
                }

                .img-wrapper {
                    position: relative;
                    width: 100%;
                    padding-right: .5rem;
                    //background-color: pink;
                }

                .img-modal-wrapper {
                    position: relative;
                    width: 100%;
                    padding-right: .5rem;
                    cursor: pointer;

                    img {
                        filter: brightness(1);
                        transition: filter 0.15s ease-out;
                    }

                    &:hover {
                        img {
                            filter: brightness(0.6);
                        }

                        .img-placeholder-icon {
                            opacity: 0.5;
                        }

                        .img-middle-icon {
                            opacity: 1;
                        }
                    }

                    .img-middle-icon {
                        position: absolute;
                        transition: opacity 0.15s ease-out;
                        filter: drop-shadow(1px 1px 8px rgba(255, 255, 255, 0.7));
                        opacity: 0;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 1;

                        path {
                            fill: var(--nietDarkBlue);
                        }
                    }

                    .img-placeholder-icon {
                        position: absolute;
                        transition: opacity 0.15s ease-out;
                        opacity: 1;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 1;

                        path {
                            fill: var(--nietDarkBlue);
                        }
                    }
                }

                .html-wrapper {
                    overflow-y: scroll;
                    // overflow: auto;
                    height: 100%;
                    width: 100%;
                }

                .icon-wrapper {
                    position: relative;
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .table-spinner {
                        margin-top: unset;
                    }

                    .icon {
                        path {
                            fill: var(--nietDarkBlue);
                        }
                    }
                }
            }

            .col-left {
                display: flex;
                justify-content: normal;
                max-width: unset;
            }

            .col-center {
                text-align: center !important;
            }

            .col-header-left {
                text-align: left;
            }

            .scroll-col {
                overflow-y: auto;
                white-space: pre-wrap;
                padding-top: 1rem;
                padding-bottom: .25rem;

            }

            .col-wrap {
                white-space: pre-wrap;
                display: flex;
                text-align-last: end;
            }

            .custom-cell {
                overflow-y: auto !important;
                align-items: normal;
            }

            .pencil-outer-container {
                position: absolute;
                bottom: 0;
                height: 1.6em;
                width: calc(100%/5 - 1.5em);
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
            }

            .pencil-indicator {

                &:hover {
                    cursor: pointer;
                }
            }

            .pencil {
                position: absolute;
                bottom: 0;
                padding-bottom: 5px;

                &:hover {
                    cursor: pointer;
                }
            }

            .pencil-outer-container-text-present {
                padding-right: 0.6em;
            }

            .angle-down {
                padding-left: 10px;

                &:hover {
                    cursor: pointer;
                }
            }

            .arrow-up-right-square {



                &:hover {
                    cursor: pointer;
                }
            }

            .sl_control {
                color: var(--nietDarkBlue);
            }

            .sl_control option {
                color: var(--nietDarkBlue);
            }


            .domain-input {
                flex-direction: inherit;
            }

            .checkbox-icon {
                font-size: large;
            }

            .is-pointer {
                &:hover {
                    cursor: pointer;
                }
            }

            .check-center {
                place-content: center;
            }

            .center-cell {
                justify-content: center;
                padding-right: 1.5em;
            }

            .indicator-cell-text-area {
                padding-top: 5px;
                white-space: pre-wrap;
                font-size: small;
                padding-right: 0.6em;
                overflow-y: auto;

                li {
                    list-style-position: inside;
                }
            }
        }

        .data-row-border {
            height: 3.5rem;
            margin-bottom: 0.25rem;
            border-right: 1px solid var(--row-border-bottom);
            border-left: 1px solid var(--row-border-bottom);
            justify-content: normal;
        }

        .user-list-row {
            height: 5em;
        }

        .table-row-cell {
            height: auto !important;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .order-by {
            padding-right: 1rem;

            &:hover {
                cursor: pointer;
            }
        }

        .custom-cell {
            height: 16rem;
        }

        .hide-row {
            display: none;
        }

        .indicator-table-row {
            height: auto;
            background-color: var(--nietDarkBlue) !important;
        }
    }


}

.paging-footer {
    position: relative;
    width: 100%;
    height: var(--footer-height);
    display: flex;
    justify-content: right;
    align-items: center;
    padding-top: 1.5rem;
    color: var(--nietDarkBlue);

    .row-count-selection {
        position: relative;
        top: 0rem;
        left: 0rem;
        font-weight: 600;
        cursor: pointer;

        .caret-down {
            padding-left: 0.5rem;
        }

        .page-selection {
            position: absolute;
            left: 2.2rem;
            top: -3.3rem;
            height: 8rem;
            width: 3rem;
            background-color: var(--white);
            box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, .5), 0 0.126rem 0.225rem rgba(123, 124, 153, .3);
            z-index: 1000;
            overflow-x: hidden;
            overflow-y: auto;

            .page-selection-item {
                position: relative;
                height: 2rem;
                text-align: center;
                line-height: 2rem;
                background-color: var(--white);

                &.active {
                    color: #f47e3a;
                    background-color: rgba(244, 126, 58, .1);
                }
            }
        }
    }

    .page-range {
        position: relative;
        padding-left: 2.5rem;
        font-weight: 600;
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Old versions of Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
    }

    .page-toggle {
        position: relative;
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;

        .angle {
            padding-left: 1.5rem;
            cursor: pointer;

            &.inactive {
                opacity: 0.33;
                cursor: unset;
            }
        }
    }
}


.icon {
    &.disabled {
        cursor: default !important;

        path {
            fill: var(--darkgray-border);
        }
    }
}