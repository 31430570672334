.backdrop {
    position: fixed;
    top: 0rem;
    left: 0rem;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.21);
    z-index: 6000;
}

.cr-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70rem;
    height: 45rem;
    background-color: white;
    border-radius: 6px;
    border: 2px solid var(--primary-blue);
    z-index: 6001;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .close-btn {
        position: absolute;
        top: 0.25rem;
        right: 0.75rem;
        font-size: 2rem;
        z-index: 2;
        cursor: pointer;
    }

    .cr-header {
        position: relative;
        width: 100%;
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-size: 2.5rem;
        font-weight: 700;
    }

    .cr-header-subtxt {
        position: relative;
        left: 5rem;
        width: calc(100% - 10rem);
        height: 2rem;
        text-align: center;
        margin-bottom: 2rem;
        font-size: 1.2rem;

        span {
            font-weight: 700;

            &::before {
                content: ' ';
                white-space: pre;
            }
        }
    }

    .expanded-content {
        --title-height: 23px;

        position: absolute;
        top: 1rem;
        left: 1rem;
        width: calc(100% - 2rem);
        height: calc(100% - 2rem);

        margin-bottom: 1rem;

        background-color: #edefff;
        background-repeat: no-repeat;
        background-size: cover;

        .back {
            position: absolute;
            top: 0.25rem;
            right: 0.75rem;

            .fa-icon {
                color: white;
                font-size: 2rem;
                cursor: pointer;
            }
        }
    }

    .content-pane-wrapper {
        --title-height: 23px;

        position: relative;
        top: 10%;
        left: 5rem;
        margin-bottom: 1rem;
        width: calc(100% - 10rem);
        height: calc(calc(100% - 17rem) / 2);

        &.expanded-height {
            height: calc(100% - 17rem);
        }

        .title {
            font-weight: 400;
            height: var(--title-height);

            span {
                font-weight: 700;
            }
        }

        .expanded-content {
            --title-height: 23px;

            position: relative;
            top: 0rem;
            left: 0rem;
            margin-bottom: 1rem;
            width: 100%;
            height: calc(100% - var(--title-height));

            background-color: #edefff;
            background-repeat: no-repeat;
            background-size: cover;

            .back {
                position: absolute;
                top: 0.25rem;
                right: 0.75rem;

                .fa-icon {
                    color: white;
                    font-size: 2rem;
                    cursor: pointer;
                }
            }
        }

        .pane-container {
            position: relative;
            height: calc(100% - var(--title-height));
            width: 100%;
            display: flex;
            gap: 0.5rem;
            flex-wrap: wrap;

            .cr-card {
                position: relative;
                height: 100%;
                width: 32.5%;
                background-color: #edefff;
                background-repeat: no-repeat;
                background-size: cover;
                border: 1px solid black;
                cursor: pointer;

                .cr-title {
                    color: white;
                    font-size: 1.5rem;
                    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
                    font-weight: 700;
                    margin-top: 0.5rem;
                    margin-left: 0.75rem;
                }

                &:hover {
                    transform: scale(1.025);
                }

                .checkbox {
                    position: absolute;
                    right: 0.75rem;
                    top: 0.5rem;
                    width: 2rem;
                    height: 2rem;
                    border: 2px solid var(--primary-blue);
                    border-radius: 2px;
                    background-color: white;

                    .fa-icon {
                        width: 100%;
                        height: 100%;
                        color: white;
                        background-color: var(--primary-blue);
                    }
                }

                .fa-icon-play {
                    position: absolute;
                    top: calc(50% - 1.5rem);
                    left: 50%;
                    transform: translate(-50%);
                    color: white;
                    font-size: 3rem;
                    cursor: pointer;

                }

                .video-length {
                    position: absolute;
                    bottom: 0rem;
                    right: 0.25rem;
                    color: white;
                    font-size: 1.25rem;
                    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
                }
            }
        }
    }

    .cr-btn-area {
        position: absolute;
        bottom: 0rem;
        left: 3rem;
        width: calc(100% - 6rem);
        height: 6rem;
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 1rem;

        .cr-sub-action {
            height: 3rem;
            width: 9rem;
        }
    }
}