.report-filter .contentFilter {
  height: calc(100% - var(--header-height) - 3rem);
  display: flex;
  flex-direction: column;
}

.report-filter-scroll {
  width: 18.5rem;
  height: 100%;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  overflow-x: hidden;
  overflow-y: auto;
}

.report-filter .field-input {
  width: 100%;
  max-width: 16rem;
}

.report-filter .contentFilter .filterControl .filter-control-input {
  width: 100%;
  max-width: 16rem;
}