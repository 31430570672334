.contentSync-data-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: .5rem;

    .toggle-text {
        font-weight: 600;
        padding: .5rem;
    }
}


ul.content-sync-messages {
    margin-left: 2rem;
    font-weight: 500;

    .message {
        display: flex;
        flex-direction: row;

        .fa-filter {
            cursor: pointer;
        }
    }
}

.contentSync-table-wrapper {
    overflow-y: auto;
    height: 100%;

    .contentSync-table {
        width: 150rem;


        .content-link {
            cursor: pointer;
        }
    }


}

.contentSync-btn-clear {
    width: 25%;

    .clear-btn {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}