.observation-table-wrapper {

    --table-header-blue: #023c5b;
    --table-row-bk-color: #eae4db;
    --lightning-color: #fcb34c;
    --tagged-note-color: #ffe9ca;
    --hover-tagged-note-color: #ffc778;
    --fixed-row-width: 9rem;


    width: 100%;
    height: 100%;
    overflow: auto;

    .observation-table {
        position: relative;
        min-width: 100%;
        height: 100%;
        display: table;
        font-size: 1.18rem;

        .table-header {
            height: 5rem;
            min-width: 100%;
            width: fit-content;
            background-color: var(--table-header-blue);
            display: table-row;
            position: sticky;
            top: 0;
            z-index: 2;

            .row {
                height: 5rem;
                border-bottom: 2px solid rgba(0, 0, 0, 0.8);
                display: flex;

                .col {
                    position: relative;
                    height: 5rem;
                    border-right: 2px solid black;
                    color: white;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    font-family: 'Raleway', sans-serif;
                    padding-left: 1rem;
                    min-width: 300px;

                    .actual-content {
                        position: absolute;
                        top: 0rem;
                        left: 0rem;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                    }

                    &.fixed-width {
                        width: var(--fixed-row-width);
                        min-width: var(--fixed-row-width);
                        align-items: left;

                        .fa-icon {
                            margin-left: 0.25rem;
                            color: rgb(170, 222, 255);
                        }
                    }

                    .progress-track {
                        height: 1px;
                        width: 90%;
                        background-color: rgba(255, 255, 255, 0.5);

                        .progress-bar {
                            position: relative;
                            top: -1px;
                            height: 3px;
                            background-color: lightskyblue;
                        }
                    }

                    &:last-child {
                        border-right: none;
                    }
                }

                .text-align-left {
                    align-items: left;
                }

                .text-align-center {
                    align-items: center;
                }
            }
        }

        .table-row {
            border-bottom: 2px solid rgba(0, 0, 0, 0.8);
            display: table-row;
            min-width: 100%;
            width: fit-content;
            background-color: var(--table-row-bk-color);
            display: flex;

            &:nth-child(even) {
                background-color: #f4f1ed;
            }

            &:last-child {
                border-bottom: 2px solid rgba(0, 0, 0, 0.8)
            }

            >.survey-rubric-cell:last-child {
                .actual-content {
                    padding-right: 1.5rem;
                }
            }

            .survey-rubric-cell {
                position: relative;
                border-right: 2px solid black;
                color: black;
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 14px;
                font-weight: 600;
                line-height: 16px;
                min-width: 300px;
                justify-content: flex-start;

                ul {
                    padding-left: 1rem;
                }

                .actual-content {
                    padding: 1rem;
                    top: 0rem;
                    left: 0rem;
                    min-height: 19rem;
                    height: auto;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    //justify-content: center;
                    //align-items: center;

                    li {
                        padding-top: .35rem;
                        padding-bottom: .35rem;
                    }
                }

                &.fixed-width {
                    width: var(--fixed-row-width);
                    min-width: var(--fixed-row-width);
                    padding: 0;

                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;

                    .indicators-wrapper {
                        position: relative;
                        top: 0rem;
                        left: 0rem;
                        width: 100%;
                        height: 100%;

                        .indicator {
                            position: relative;
                            top: 0rem;
                            left: 0rem;
                            width: 100%;
                            height: 70%;

                            .title-icon-wrapper {
                                position: relative;
                                float: left;
                                padding-top: 2rem;
                                padding-left: 0.5rem;
                                width: 70%;
                                height: 100%;
                                font-size: 14px;
                            }

                            .lighting-icon-wrapper {
                                position: relative;
                                float: right;
                                width: 30%;
                                height: 100%;

                                .lighting {
                                    padding-top: 0.5rem;
                                    padding-left: 0.9rem;
                                    font-size: 1.5rem;

                                    &.active {
                                        color: #fcb34c;
                                        cursor: pointer;

                                        &.focused {
                                            color: rgb(255, 132, 0);
                                            transform: scale(1.1);
                                        }
                                    }
                                }

                                .icon-subscript-number {
                                    position: absolute;
                                    top: 1.3rem;
                                    left: 1.75rem;
                                    font-size: 9px;
                                }
                            }
                        }

                        .rating-btn-wrapper {
                            position: relative;
                            width: 100%;
                            height: 30%;

                            .evidence-btn {
                                position: relative;
                                left: 0.3rem;
                                width: calc(100% - 0.6rem);
                                margin: 0;
                                padding: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 10px;
                                background-color: #fff;
                                color: var(--primary-blue);
                                border: 1px solid black;
                                border-radius: 4px;
                            }

                            .rating-btn {
                                position: relative;
                                top: 0.3rem;
                                left: 0.25rem;
                                width: calc(100% - 0.6rem);
                                height: 2rem;
                                margin: 0;
                                padding: 0;
                                padding-left: 0.75rem;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                font-size: 10px;
                                background-color: #3b3e66;
                                color: white;
                                border: 1px solid black;
                                border-radius: 4px;
                                cursor: pointer;

                                &.disable {
                                    cursor: not-allowed;
                                    opacity: .5;

                                    &:active {
                                        transform: scale(1);
                                    }
                                }

                                &.selected {
                                    background-color: white;
                                    color: #3b3e66;
                                    border: 1px solid #3b3e66;
                                }

                                letter-spacing: .18rem;

                                .rating-caret {
                                    position: relative;
                                    padding-right: 0.5rem;
                                    font-size: 1.25rem;

                                    &.flip {
                                        left: -0.4rem;
                                        transform: rotate(180deg);
                                    }
                                }

                                .invisible-overlay {
                                    position: absolute;
                                    width: calc(100% - 0.6rem);
                                    height: 100%;
                                    background-color: rgba(255, 192, 203, 0);
                                }

                                &:active {
                                    transform: scale(1.03);
                                }

                                &.expanded {
                                    border-bottom-left-radius: 0;
                                    border-bottom-right-radius: 0;
                                }

                                .rating-loading {
                                    margin-top: .4rem;
                                    margin-left: -0.6rem;

                                }

                                .magnitude {
                                    position: relative;
                                    top: -0.1rem;
                                    left: -0.1rem;
                                    color: #3b3e66;
                                    font-size: 1.25rem;
                                    font-weight: 700;
                                }

                                .rating-container {
                                    position: relative;
                                    left: -0.1rem;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 0.25rem;

                                    .data-rating {
                                        height: 0.6rem;
                                        width: 0.6rem;
                                        border-radius: 50%;
                                        border: 1px solid #3b3e66;
                                        background-color: #d9d9d9;

                                        &.active {
                                            background-color: #3b3e66;
                                            border: 1px solid black;
                                        }
                                    }
                                }
                            }

                            .rating-list {
                                position: relative;
                                top: 0.5rem;
                                left: 0.25rem;
                                width: calc(100% - 0.6rem);
                                height: auto;

                                .rating-option {
                                    z-index: 100;
                                    position: relative;
                                    height: 2rem;
                                    width: 100%;
                                    background-color: white;
                                    color: var(--primary-blue);
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-size: 1.2rem;
                                    border-left: 1px solid black;
                                    border-right: 1px solid black;
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
                                    cursor: pointer;

                                    &:hover {
                                        background-color: rgb(250, 250, 250);
                                    }
                                }

                                &>.rating-option:last-child {
                                    border-bottom-left-radius: 4px;
                                    border-bottom-right-radius: 4px;
                                }


                                .data-rating-option {
                                    z-index: 100;
                                    position: relative;
                                    height: 2rem;
                                    width: 100%;
                                    background-color: white;
                                    color: var(--primary-blue);
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-size: 1.2rem;
                                    border-left: 1px solid black;
                                    border-right: 1px solid black;
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
                                    cursor: pointer;

                                    &:hover {
                                        background-color: rgb(250, 250, 250);
                                    }

                                    .magnitude {
                                        position: relative;
                                        top: -0.1rem;
                                        left: -1rem;
                                        color: #3b3e66;
                                        font-size: 1.25rem;
                                        font-weight: 700;
                                    }

                                    .rating-container {
                                        position: relative;
                                        left: -0.45rem;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        gap: 0.25rem;

                                        .data-rating {
                                            height: 0.6rem;
                                            width: 0.6rem;
                                            border-radius: 50%;
                                            border: 1px solid #3b3e66;
                                            background-color: #d9d9d9;

                                            &.active {
                                                background-color: #3b3e66;
                                                border: 1px solid black;
                                            }
                                        }
                                    }
                                }

                                &>.data-rating-option:last-child {
                                    border-bottom-left-radius: 4px;
                                    border-bottom-right-radius: 4px;
                                }
                            }

                        }
                    }
                }

                &:last-child {
                    border-right: none;
                }
            }

            .text-align-left {
                align-items: left;
            }

            .text-align-center {
                align-items: center;
            }
        }

        &::-webkit-scrollbar-track {
            border-left: 1px solid black;
            border-right: 1px solid black;
            background-color: #ffffff;
        }

        &::-webkit-scrollbar {
            width: 16px;
            background-color: #ffffff;
            border-left: 1px solid black;
            border-right: 1px solid black;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 7px;
            background-color: #666666;
            border: 4px solid transparent;
            background-clip: content-box;
        }

        &::-webkit-scrollbar-button {
            border-style: solid;
            width: 16px;
            height: 10px;
        }

        &::-webkit-scrollbar-button:vertical:decrement {
            border-width: 0 7px 14px 7px;
            border-color: transparent transparent #9e9e9e transparent;
        }

        &::-webkit-scrollbar-button:vertical:increment {
            border-width: 14px 7px 0 7px;
            border-color: #9e9e9e transparent transparent transparent;
        }
    }
}

.table-padding {
    padding-bottom: 6.5rem;
}