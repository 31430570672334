// .dialog-control .dialog__inner {
//     width: 100%;
//     margin-left: 0;
//     margin-right: 0;
// }

.sub-title {
    font-weight: normal;
}

.performance-title {
    justify-content: center;
    margin-top: -10px;
    margin-bottom: 5px;
    font-size: inherit;
}

.quill {
    max-width: 562px;
}

.rq-editing-area {
    height: 25em;
}