.certification-rubric-page {
    position: absolute;
    top: 6rem;
    left: 0rem;
    height: calc(100% - 6rem - var(--footer-height) - 0.3rem);
    width: 100%;


    .screen-header {
        padding: 1rem 5rem;

    }

    .screen-wrapper {
        display: flex;
        height: 100%;
        flex-direction: column;
    }



    .video-rubric-wrapper {
        width: 100%;
        display: flex;
        position: relative;
        height: calc(100% - 6rem - var(--footer-height) - 0.3rem);

        .video-wrapper {
            width: 50%;
            min-height: 480px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            border-right: 2px solid var(--primary-blue);

            .video-player {
                padding-bottom: 0 !important;
                width: 100%;
                max-width: 1024px;
                min-height: 480px;

                .azuremediaplayer {
                    width: 100%;
                    height: 100%;
                    max-width: 1024px;
                    min-height: 480px;
                }

                .vjs-big-play-button {
                    font-size: 120vh;
                }
            }
        }

        .observation-table-wrapper {
            width: 50%;
            right: 0rem;
            left: unset;
        }
    }

    .button-row {
        display: flex;
        justify-content: flex-end;
        padding: 2rem 5rem;
        gap: 2rem;

        .btn_control {
            width: 10rem;
        }
    }
}

.dialog-control {
    .certification-custom-content-dialog {
        .dialog__inner {
            .video-player {
                margin-top: 3rem;
                margin-bottom: 3rem;
                width: 100%;

                .azuremediaplayer {
                    width: 100%;
                    height: 25rem;

                }
            }
        }
    }
}

.certification-page {
    position: absolute;
    top: 6rem;
    left: 0rem;
    height: calc(100% - 5rem - var(--footer-height) - 0.3rem);
    width: 100%;
    background-color: #D9D9D9;


    .screen-wrapper {
        position: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .dialog__inner {
            display: flex;
            justify-content: center;
            max-width: 75rem;
            padding: 3rem 5rem 2rem 5rem;

            img {
                width: 100%;
            }
        }
    }

    h1 {
        font-weight: 400;
        letter-spacing: .4rem;
        padding-bottom: 1rem;
        width: fit-content;
    }

    h2 {
        font-weight: bold;
    }

    h3 {
        font-weight: bold;
        display: flex;
        align-items: center;

        img {
            align-items: center;
            margin-right: .75rem;
        }

        span {
            margin-left: .5rem;
            font-weight: 400;
        }
    }

    .certification {
        border: 2px solid var(--primary-blue);
        border-radius: 8px;
        background-color: white;
        width: 60%;
        min-width: 65rem;
        padding: 3rem;

        h1 {
            border-bottom: 1px solid var(--darkgray-border-2);
        }

        .certification-header-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

        }

        &-content {
            display: flex;
            margin-top: 2rem;

            .certification-para-wrapper {
                margin: auto 0;
                margin-right: 5rem;
            }

            .certification-image-wrapper {
                max-width: 40%;

                .certification-image {
                    width: 100%;
                }
            }
        }

        &-double-column {
            display: flex;
            gap: 5rem;

            .double-column-left {
                width: 50%;

                .row {
                    display: flex;
                    align-items: center;
                    padding: 6px 4px 6px 4px;
                    border-bottom: 1px solid var(--darkgray-border-2);
                    min-height: 2.25rem;

                    .col {

                        &:first-child {
                            width: 10%;
                            padding-left: .25rem;
                        }

                        &:nth-child(2) {
                            width: 45%;
                        }

                        &:nth-child(3) {
                            width: 25%;
                        }

                        &:last-child {
                            width: 20%;
                        }

                        .btn_control {

                            font-size: .8rem;
                            display: flex;
                            padding: .6rem;
                            align-items: center;
                            justify-content: center;
                            min-width: 6rem;
                            width: fit-content;
                            position: relative;
                            border-radius: 6px;


                            .inner_loading {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }

                    &-header {
                        display: flex;
                        border-bottom: 1px solid var(--darkgray-border-2);

                        .col {

                            &:first-child {
                                width: 10%;
                                padding-left: .25rem;
                            }

                            &:nth-child(2) {
                                width: 45%;
                            }

                            &:nth-child(3) {
                                width: 25%;
                            }

                            &:last-child {
                                width: 20%;
                            }
                        }
                    }

                }
            }

            .double-column-right {
                width: 50%;

                .row-header {
                    display: flex;
                    align-items: center;
                    padding: 6px, 4px, 6px, 4px;
                    border-bottom: 1px solid var(--darkgray-border-2);

                    .col {
                        width: 50%;

                        &:first-child {
                            padding-left: .25rem;
                        }
                    }
                }

                .row {
                    display: flex;
                    min-height: 2.25rem;
                    padding: 6px 4px 6px 4px;

                    .col {
                        width: 50%;
                        padding-left: .25rem;

                        .fa-icon-disabled {
                            opacity: .5;
                        }

                        svg {
                            cursor: pointer;
                        }

                        &:last-child {
                            margin-left: 4.75rem;
                        }
                    }
                }
            }
        }

        .phase-two {
            h1 {
                border: unset;
            }

            .certification-subheader-wrapper {
                display: flex;
                justify-content: space-between;

                .view-rubric-btn {
                    background-color: unset;
                    border: 1px solid var(--darkgray-border-2);
                    border-radius: 8px;
                    padding-left: 6px;
                    padding-right: 6px;

                    img {
                        width: 2rem;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }

            .certification-content {
                gap: 2rem;

                .certification-para-wrapper {
                    margin: unset;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    flex-grow: 1;
                    font-size: 1.2rem;



                    .attempt-text-wrapper {
                        display: flex;

                        p {
                            margin-left: 2rem;
                            margin-bottom: unset;
                            font-size: .95rem;
                        }
                    }

                    .btn-wrapper {
                        margin: unset;
                        display: flex;
                        justify-content: flex-end;
                    }

                    .btn_control {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-grow: 1;
                        max-width: 10rem;
                    }
                }
            }
        }

        &-question-wrapper {
            margin-top: 3rem;

            p {
                margin-top: 2rem;
            }
        }

        .two-columns {
            display: flex;
            justify-content: space-between;

            .file-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;

                .file-img {
                    max-width: 10rem;
                }

                .view-file {
                    text-decoration: underline;
                    cursor: pointer;
                }

                .video-player {
                    .azuremediaplayer {
                        height: 200px;
                        max-width: 200px;

                        .amp-controlbaricons-middle {
                            display: none;
                        }

                        .amp-controlbaricons-right {

                            .amp-quality-control {
                                display: none;
                            }
                        }
                    }

                    .vjs-fullscreen {
                        .amp-controlbaricons-middle {
                            display: unset;
                        }

                        .amp-controlbaricons-right {
                            white-space: nowrap;

                            .amp-quality-control {
                                display: unset;
                            }
                        }

                    }
                }
            }
        }

        &-answer-wrapper {
            h3 {
                font-size: 800;
            }

            .answer-options-wrapper {
                margin-top: 1rem;

                .answer-options {
                    display: flex;
                    gap: 3rem;
                    border-bottom: 1px solid var(--darkgray-border-2);
                    padding-top: .5rem;
                    padding-bottom: .5rem;

                    &:last-child {
                        border-bottom: unset;
                    }

                    .option {
                        width: 3rem;
                        text-align: center;
                        margin: auto 0;
                        display: flex;
                        align-items: center;

                        //REACT QUILL CSS
                        .rq {
                            max-width: calc(100% - 9rem);

                            .ql-container {
                                padding: unset;



                                .ql-editor {
                                    padding: unset;
                                }

                                .ql-tooltip {
                                    display: none;
                                }
                            }
                        }

                        p {
                            margin: unset;
                        }

                        &:nth-child(3) {
                            flex-grow: 1;
                            text-align: left;
                        }

                    }

                    .option-video {
                        margin: auto 0;
                        margin-left: auto;
                        padding-right: 1rem;
                        min-width: 7rem;

                        a {
                            display: block;
                            width: 4rem;

                            .document-icon {
                                width: 4rem;
                                margin-right: 2.25rem;
                            }
                        }

                        img {
                            cursor: pointer;
                        }
                    }
                }
            }


        }
    }

    .btn-wrapper {
        display: flex;
        justify-content: flex-end;

        .btn_control {
            flex-grow: 1;
            max-width: 10rem;
        }
    }

    .phase-three,
    .phase-four {
        position: absolute;
        border-top: 2px solid var(--primary-blue);
        top: 0%;
        left: 0%;
        height: 100%;
        width: 100%;
        display: flex;
        background-color: white;


        h1 {
            margin-top: 1.5rem;
            margin-left: 2rem;
        }

        &-subheader {
            padding: 2rem 2rem 0 2rem;
            display: flex;
            justify-content: space-between;

            .right {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .button-wrapper {
                    display: flex;
                    min-width: 10rem;
                    gap: 2rem;
                }

                .btn_control {
                    margin-bottom: .25rem;
                }
            }
        }

        .video-section {
            position: relative;
            border-top: 1px solid var(--primary-blue);
            display: flex;
            flex-direction: column;

            .video-wrapper {
                padding: 0 2rem;
                margin-top: 5rem;
                margin-bottom: 5rem;
                flex-grow: 1;

                .video-player {
                    width: 100%;
                    height: 100%;
                    max-width: 1024px;
                    min-height: 480px;
                    margin: auto;

                    .azuremediaplayer {
                        width: 100%;
                        height: 100%;
                        max-width: 1024px;
                        min-height: 480px;
                    }
                }
            }

            .content-icons {
                font-family: "Raleway";
                font-weight: 700;
                letter-spacing: 0.2rem;
                color: var(--nietDarkBlue);
                margin-bottom: -6rem;
            }

            .bitmovinplayer-poster {
                display: none !important;
            }
        
            // .bmpui-ui-errormessage-overlay.bmpui-hidden {
            //     display: block;
            // }
            .bmpui-ui-watermark {
                display: none;
            }
        
            .bmpui-ui-errormessage-overlay .bmpui-ui-tvnoisecanvas {
                filter: blur(20px);
                background: black;
            }
            .bmpui-ui-errormessage-overlay .bmpui-ui-errormessage-label {
                color: black;
            }
        
            .bmpui-ui-titlebar {
                background: linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.4));
            }
        }

        .rubric-section {
            position: relative;
            height: 100%;
            border-top: 1px solid var(--primary-blue);
            min-width: 11rem;
            overflow-y: hidden;
            overflow-x: hidden;

            .drag-me {
                position: absolute;
                top: 0rem;
                left: 0rem;
                width: 16px;
                height: 100%;
                background-color: #d9d9d9;
                border-left: 1px solid black;
                border-right: 2px solid black;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: e-resize;
                font-size: 1.25rem;
                font-weight: 500;
                color: var(--primary-blue);



                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }

            .content-area {
                position: absolute;
                top: 0rem;
                left: 16px;
                height: 100%;
                width: calc(100% - 16px);
                overflow-y: hidden;
                overflow-x: hidden;

                .loading-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10rem;
                }
            }
        }
    }

    .phase-four {
        display: flex;
        flex-direction: column;
        align-items: center;

        .container {
            min-width: 70%;

            .rubric-wrapper {
                height: 52.5rem;
                margin: auto;
                max-width: 1200px;

                .observation-table-wrapper {
                    overflow: overlay;

                    .observation-table .table-row .survey-rubric-cell.fixed-width .indicators-wrapper .rating-btn-wrapper .rating-btn {
                        position: absolute;
                    }

                    .observation-table .table-row .survey-rubric-cell.fixed-width .indicators-wrapper .rating-btn-wrapper .rating-list {
                        top: 2.3rem;
                    }
                }

            }

            .no-borders {
                border: unset;
                display: flex;
                margin-top: 7rem;
                justify-content: center;
            }
        }
    }
}

@media print {

    .print-page {
        position: absolute;
        display: block;

        .print-content {
            margin-top: 2rem;
            height: unset;

            .observation-table-wrapper {

                .row {
                    border-bottom: 2px solid rgba(0, 0, 0, .3);

                    .col {
                        border-right: 2px solid rgba(0, 0, 0, .3);
                    }
                }

                .table-row:last-child {
                    border-bottom: 2px solid rgba(0, 0, 0, .3);
                }

                .survey-rubric-cell {
                    border-right: 2px solid rgba(0, 0, 0, .3);

                    &:first-child {
                        border-left: 2px solid rgba(0, 0, 0, .3);
                    }

                    &:last-child {
                        border-right: 2px solid rgba(0, 0, 0, .3);
                    }
                }
            }
        }
    }

    .certification-page {
        background-color: unset;

        .screen-wrapper {
            .rubric-modal {
                display: none;
            }

            .certification {
                display: none;
            }

            .blockui {
                display: none;
            }
        }
    }

    .observation-page {
        display: none;
    }

    .header {
        display: none;
    }

    .footer {
        display: none;
    }
}