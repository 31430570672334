.info-grid {
    display: flex;
    flex-direction: row;

    .info-wrapper {
        position: relative;
        background-color: var(--white);
        color: var(--nietDarkBlue);
        border: 1px solid var(--drakgray-1);
        border-radius: .25rem;
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 1.4rem;
        padding: 1rem;
        box-shadow: 2px 3px 4px var(--darkgray-1);
        min-width: 24rem;
        min-height: 6rem;
        white-space: normal;


    }

    .info-symbol {
        z-index: 0;
    }

    .info-icon {
        z-index: 0;
    }
}


.info {
    z-index: 1000;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    color: var(--nietDarkBlue);

    &-symbol {
        z-index: 100;
    }

    &-icon {
        z-index: 100;
    }

    &-wrapper {
        position: absolute;
        background-color: var(--white);
        color: var(--nietDarkBlue);
        border: 1px solid var(--drakgray-1);
        border-radius: .25rem;
        font-weight: 400;
        font-size: 1.25rem;
        z-index: 1000;
        line-height: 1.4rem;
        padding: 1rem;
        margin-left: 1.4rem;
        margin-top: .75rem;
        box-shadow: 2px 3px 4px var(--darkgray-1);
        min-width: 10rem;
        max-width: 25rem;

    }

    .convert {
        white-space: nowrap;
        padding: .75rem;
        text-align: right;
        min-width: 6rem;
    }

  
}