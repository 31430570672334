.preferences {
  &__subheading {
    font-weight: 700;
    margin-top: 1.5em;
  }

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: right;
  }
}

.preferences-dialog {
  min-width: 50rem;
}