.observation-achievement-pay-ratios-table {
    .observation-achievement-pay-ratios-table {
        width: 100%;
        border-collapse: collapse;

        .row-header {
            font-weight: bold;
            font-size: 1rem;
            text-align: center;
        }

        td {
            padding: .5rem;
        }

        .main-header {
            th {
                width: 10.5%;
                font-size: 1.2rem;
                margin-right: 3rem;
                padding-right: 0rem;
                text-align: center;
                white-space: nowrap;
            }
            .blank-header {
                width: 10%;
                margin-right: 0;
            }
        }
    
        .sub-header {
            width: 100%;
            justify-content: center;
    
            th {
                width: 20%;
                padding: 0.5rem;
            }
    
            .blank-header {
                width: 1%;
                margin-right: 0;
                border-bottom: 0px;
            }
        }

        .score-header {
            text-align: center;
        }

        .norm-header {
            text-align: center;
        }
    }
    
    .observation-achievement-pay-ratios-table th,
    .observation-domain-weights-table td {
        padding-bottom: .5rem;
        text-align: left;
    }

    .table-container {
        display: flex;
        margin-right: 10px;
    }
    
    @media (max-width: 600px) {
        .observation-achievement-pay-ratios-table {
            font-size: 12px;
        }
    }
}