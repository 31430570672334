.InPageLoadingContainer {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-vertical-align: middle;
    vertical-align: middle;
    -webkit-justify-content: center;
    justify-content: center;
    align-items: center;
    gap: 1.75rem;
}