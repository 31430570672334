.payout-settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1.25%;
    width: 90%;
    margin: 0 auto;

    .title-container {
        display: flex;
    }

    .school-copy-dropdown {
        display: flex;
        margin-left: auto;
        align-items: center;
        justify-content: center;

        .school-copy-title {
            min-width: 21rem;
            font-size: 1.25rem;
            font-weight: 700;
            align-self: center;
        }

        .rs-picker-toggle-wrapper {
            align-self: center;
            min-width: 20rem;
        }
    }

    .rs-panel-title {
        font-size: 1.5rem;
        font-weight: bold;
        color: var(--nietDarkBlue);
    }

    .rs-panel-bordered {
        margin-bottom: 2rem;
    }

    .payout-container {
        max-width: 85rem;
    }

    .payout-admin-container {
        max-width: 85rem;
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .container {
        display: flex;
        flex-direction: column;
    }

    .top-line {
        display: flex;
        padding-bottom: 1rem;
        width: 100%;
        display: flex;
        flex-direction: row;

        .school-info {
            display: flex;
            width: 66.8%;
        }
        .year-info {
            display: flex;
        }
    }

    .headerColor {
        font-weight: bold;
        font-size: 1.25rem;
    }

    .headerValue {
        margin-left: 1rem;
        font-size: 1.25rem;
    }

    .bottom-line {
        display: flex;
        justify-content: space-between;
    }

    .field-input {
        margin-right: 2rem;
    }

    .student-school-ratio-container {
        display: flex;

        .rs-panel {
            width: 100%;
        }

        .rs-panel-header,
        .rs-panel-body {
            display: flex;
        }
    }

    @media (max-width: 1280px) {
        .student-school-ratio-container {
            width: 100%;
        }
    }

    .field-input .generic-input-component input,
    .field-input .generic-input-component select {
        box-shadow: none;
    }

    .observer-container {
        width: 70%;
    }

    .domain-container {
        width: 50%;
    }

    .apply-all-settings-container {
        margin: auto;
        margin-left: 0;

        .field-input .generic-input-component {
            display: flex;
        }
        .field-input .generic-input-component .title-header {
            line-height: 2.25rem;
        }
    }

    .dialog-control .dialog__footer .dialog-btn {
        font-size: 1rem;
    }

}