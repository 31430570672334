.rating-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .8rem;
  padding-bottom: 1rem;
  height: 14rem;

  &-stars {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-left: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    gap: 3.5rem;

    .star {
      display: flex;
      flex-direction: row;
      width: 3.8rem;

      height: 3.8rem;
    }

    svg {
      cursor: pointer;

      path {
        transform: scale(1.8);
        -ms-transform: scale(1.8);
        -webkit-transform: scale(1.8);
      }
    }
  }

  &-loader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 6rem;
    padding-top: 3.5rem;

  }
}