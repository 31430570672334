.growth-tracking {
    padding-top: 5rem;
    padding-bottom: 10rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .growth-tracking-container {
        display: flex;
        width: 65%;

        .growth-info-container {
            width: 43%;
        }

        .growth-header {
            font-size: 3rem;
            font-weight: bold;
            padding-bottom: 0.5rem;
        }

        .growth-intro {
            font-size: 1.3rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        .list-container {
            list-style-type: none;
            padding-top: 1.5rem;
            font-size: 1.3rem;

            li {
                padding-bottom: .5rem;
            }

            li:before {
                content: "";
                display: inline-block;
                width: .1rem;
                height: .1rem;
                // background-image: url("path/to/check-image.png");
                background-size: contain;
            }
        }

        .check {
            padding-right: .5rem;
        }

        .login-container {
            padding-right: 3rem;
        }

        .image-wrapper {
            margin-top: 3rem;
            position: relative;
            width: 100%;
            height: 100%;
            margin-left: 5rem;

            img {
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                height: 20rem;

                width: auto;

            }
        }

        .image-1 {
            z-index: 1;
        }

        .image-2 {
            z-index: 2;
            transform: translate(40%, 60%);
        }

        .image-3 {
            z-index: 3;
            transform: translate(80%, 120%);
        }
    }

}

@media (max-width:1600px) {
    .growth-tracking {
        .growth-tracking-container {
            width: 80%;
        }

    }
}


@media (max-width:1400px) {
    .growth-tracking {
        .growth-tracking-container {
            width: 90%;
        }
    }
}



@media (max-width:1280px) {
    .growth-tracking {
        .growth-tracking-container {
            width: 70%;
        }
    }
}