.field-input {
    width: 100%;
    display: flex;
    flex-direction: column;


    &__checkTreePicker {
        input {
            height: 0rem !important;
        }
    }

    .generic-input-component {
        position: relative;
        display: block;
        top: 0rem;
        left: 0rem;
        width: 100%;


        .title-header {
            margin-bottom: .25rem;
        }

        .title {
            position: relative;
            display: flex;
            flex-direction: row;
            gap: .5rem;
            margin-bottom: 0.25rem;
            top: 0rem;
            left: 0rem;
            font-weight: bold;
            font-size: 1.25rem;
        }

        .subtitle {
            position: relative;
            top: 0rem;
            left: 0rem;
            font-size: 1.1rem;
            margin-left: .1rem;
        }

        .input-text-wrapper {
            .units-align-right {
                position: absolute;
                top: inherit;
                z-index: 1;
                right: 1rem;
                height: 2rem;
                width: 2rem;
                text-align: center;
                line-height: 2rem;
                font-weight: bold;
            }

            .password-viewer-eye {
                position: relative;
                float: right;
                top: -1.75rem;
                right: 1rem;
                cursor: pointer;
            }
        }

        input,
        select {
            position: relative;
            top: 0rem;
            width: 100%;
            height: 2.3rem;
            border-radius: .5rem;
            border: 1px solid var(--nietDarkBlue);
            box-shadow: inset 0px 0px 1px 1px rgba(156, 156, 156, 0.5);
            padding-left: 0.85rem;
            color: var(--nietDarkBlue);

            option {
                color: var(--nietDarkBlue);
            }

            &::placeholder {
                color: var(--nietDarkBlue);
              
            }
        }

        select {
            padding: 0 1.5rem 0 1rem;
        }

        .number {

            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type=number] {
                -moz-appearance: textfield;
            }
        }

        textarea {
            top: 0rem;
            width: 100%;
            height: 4rem;
            border-radius: 3px;
            border: 1px solid var(--nietBlue);
            padding: 0.5rem;
            color: var(--nietDarkBlue);
            box-shadow: inset 0px 0px 1px 1px rgba(156, 156, 156, 0.5);
            margin-bottom: -.5rem;

            &::placeholder {
                color: var(--nietBlue);
                opacity: 0.63;
            }
        }

        .char-cnt {
            position: absolute;
            bottom: -1.1rem;
            right: 0rem;
            font-size: .75rem;
        }

        .ratingInput {
            width: 2rem;
            padding-top: 10px;
        }
    }

    .error-wrapper {
        position: relative;
        font-weight: 400;
        color: var(--error);
        height: 1.8rem;
        font-size: 1.1rem
    }

    .input-error {
        border: 2px solid var(--error) !important;

        &:focus {
            border: 2px solid var(--error);
        }

        &:focus-visible {
            border: 2px solid var(--error);
        }

        &:active {
            border: 2px solid var(--error);
        }

        color: var(--error) !important;
    }
}