.contentSync-table {
    .status {
        font-weight: bold;

        &-Success {
            color: var(--success);
        }

        &-Partial-Success {
            color: var(--cyan);
        }

        &-Skipped {
            color: var(--nietYellow);
        }

        &-Error,
        &-Fatal-Error {
            color: var(--error);
        }
    }
}