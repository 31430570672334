.faq {
    padding-top: 5rem;
    padding-bottom: 10rem;

    .faq-header {
        text-align: center;
        padding-top: 1rem;
        font-size: 3rem;
        font-weight: bold;
        padding-bottom: 0.5rem;
    }

    .question-container {
        max-width: 63rem;
        margin: 0 auto;
    }

    .faq-subheader {

        font-weight: 600;
        margin: 0 auto;
        max-width: 63rem;
    }

    .subheader-title {
        font-weight: 700;
        padding-top: 1rem;
        padding-bottom: .3rem;
    }

    .rs-panel {
        margin: 1rem;
        border-radius: .75rem;
        font-weight: 700;
    }

    .rs-panel-bordered {
        border: .1rem solid black;
    }

    .rs-panel-collapsible>.rs-panel-header {
        color: var(--nietDarkBlue);
    }

    .rs-panel-header,
    .rs-panel-body {
        padding: .8rem;
    }

    .rs-panel-header {
        font-size: 1.40rem;
    }

    .rs-panel-collapsible>.rs-panel-header>.rs-icon {
        top: 0.75rem;
        font-size: 1.75rem;
        color: var(--nietDarkBlue);
    }
}