.tos-wrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    gap: 2rem;

    .tos-header {
        margin-top: 1rem;

    }


    .tos-content-wrapper {
        border: 1px solid var(--primary-blue);
        height: 80%;
        overflow-y: scroll;

        .loading-tos {
            width: 100%;
            height: 100%;
            padding: 3rem;
            background-color: var(--nietUtilityGray);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }


        .tos-content {
            height: 100rem;
            width: 100%;
            padding: 3rem;
            background-color: var(--nietUtilityGray);
        }
    }

    .tos-footer {
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        gap: 2rem;
    }
}