.levels-wrapper {
    position: relative;
    top: 0rem;
    left: 0rem;
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    overflow: hidden;

    .blue-levels {
        margin-top: -5rem;
        margin-left: 9rem;
        width: 400px;
        height: 300px;
        background-color: #b5c4de;
        border-top-width: 4px;
        border-bottom-width: 1px;
        -webkit-transform: perspective(200px) rotateX(30deg);

    }

    .mountain-img-wrapper {
        position: relative;
        top: -120%;
        left: -1.25rem;
        width: 100%;
        height: 97%;
        opacity: 0.07;
    }

    .white-shadow-overlay {
        position: relative;
        top: -7rem;
        left: -12.75rem;
        width: 20rem;
        height: 120%;
        transform: rotate(29.9deg);
        background-color: white;
        //background-color: pink;
        z-index: 100;
    }

    .metrics-overlay {
        position: relative;
        top: -217%; //must subtract height of white-shadow-overlay
        left: 0%;
        width: 100%;
        height: 97%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .metric {
            padding-left: 0.75rem;
            //height: 20%;
            width: 100%;
            display: flex;
            align-items: center;

            .bar-area {
                height: 100%;
                width: 65%;
                border-bottom: 2px solid white;

                &.active {
                    background-color: rgba(181, 196, 222, 0.6);
                }

                .bar {
                    position: relative;
                    float: right;
                    top: 26%;
                    height: 48%;
                    background-color: #cacaca;
                    box-shadow: 2px 2px 6px 1px #767676;
                    border-bottom-left-radius: 6px;
                    border-top-left-radius: 6px;
                    z-index: 101;
                    transition: width 0.25s ease-in-out;
                    cursor: pointer;

                    &.active {
                        background-color: #073992;

                        &:hover {
                            background-color: #1c59c2;
                        }
                    }
                }

            }

            .metric-area {
                padding-left: 0.5rem;
                height: 100%;
                left: 65%;
                width: 35%;
                background-color: white;
                display: flex;
                align-items: center;
                z-index: 102;

                .metric-lvl-descriptor {
                    height: 100%;
                    width: 2.5rem;
                    display: flex;
                    align-items: center;
                    opacity: 0.5;

                    font-size: 2.25rem;
                    font-weight: 700;

                    &.active {
                        opacity: 1;
                    }
                }

                .magnitude {
                    font-size: 1.2rem;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

.two-chart-box {
    position: relative;
    top: 0rem;
    left: 0rem;
    width: 100%;
    height: 100%;
    display: flex;

    .left-chart {
        height: 100%;
        width: 50%;
    }

    .right-chart {
        height: 100%;
        width: 50%;
    }

}