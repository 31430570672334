.image-upload-wrapper {
    position: relative;
    top: 1rem;
    height: 2rem;
    border-radius: 3px;
    padding-left: 0.5rem;
    width: 100%;

    .file-upload {
        position: relative;
        border: 1px solid var(--primary-blue);
        background-color: var(--nietLightBlue);
    }

    .input-file {
        display: none;
    }
}




.upload-area {
    position: relative;
    width: 100%;
    top: 1rem;

    &.top-zero {
        top: 0rem;
    }


    .file-upload-image {
        background-repeat: no-repeat;
        background-color: var(--nietLightUI);
        background-position: center;
        background-size: contain;
        cursor: pointer;

        &.disable-pointer {
            cursor: default;
        }

        .video-player {
            width: 100%;
            height: 100%;
            padding-bottom: 0 !important;

            .azuremediaplayer {
                width: 100%;
                height: 100%;
            }

        }
    }

    .file-name {
        position: relative;
        top: 1rem;
        left: 0rem;
        font-size: 1rem;
        white-space: nowrap;

        span {
            font-style: italic;
        }
    }

    .title {
        position: relative;
        top: 0rem;
        left: 0rem;
        font-weight: bold;
    }

    .subtitle {
        position: relative;
        top: 0rem;
        left: 0rem;
        font-size: 0.9rem;
    }

    .image-upload-picture-loader {
        position: relative;
        top: 0.5rem;
        left: 0rem;
        width: 25rem;
        height: 25rem;
        background-color: var(--nietLightUI);
        border: 1px solid var(--primary-blue);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .image-upload-picture-icon {
        position: relative;
        top: 0.5rem;
        left: 0rem;
        width: 25rem;
        height: 25rem;
        background-color: var(--nietLightUI);
        border: 1px solid #1d2b727c;
        border-style: dashed;
        border-radius: 4px;
        cursor: pointer;

        &.no-bk-color {
            background-color: white;
        }

        &__drag-over {
            position: relative;
            top: 0.5rem;
            left: 0rem;
            width: 25rem;
            height: 25rem;
            background-color: var(--nietLightUI);
            border: 2px solid #1d2b727c;
            cursor: pointer;

            .faUpload-icon {
                position: absolute;
                top: calc(50% - 2rem);
                left: calc(50% - 2rem);
                height: 4rem;
                width: 4rem;
                opacity: 0.75;
            }
        }

        &:hover {
            border: 2px solid var(--primary-blue);
            border-style: dashed;

            .faCamera-icon {
                opacity: 0.75;
            }
        }


        .faCamera-icon {
            position: absolute;
            top: calc(50% - 2rem);
            left: calc(50% - 2rem);
            height: 4rem;
            width: 4rem;
            opacity: 0.55;
        }

        .plus-sign {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--primary-blue);
            font-size: 4rem;
        }
    }
}