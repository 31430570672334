.video-preview-container {
    display: block;
    max-width: 75%;
    margin: 0 auto;
    padding-top: 1rem;

    .video-header {
        padding-top: 1rem;
        padding-bottom: 2rem;
        text-align: center;
        font-weight: bold;
    }

    .video-container {
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
        margin: 0 auto;
    }

    #previewVideo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: right;
        align-items: flex-end;
        color: white;
        text-align: right;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8));
        padding: 1rem;
    }

    .overlay-info {
        max-width: 40%;
        text-align: center;
        margin-bottom: 3rem;
    }

    .video-title {
        font-size: 3.25vw;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .play-btn {
        border-radius: .5em;
        background-color: #ddd;
        border: none;
        color: black;
        font-weight: bold;
        padding: .5rem 1rem;
        width: 12rem;
    }

    .play-icon {
        padding-right: .75rem;
    }

    p {
        font-size: 1.25vw;
        line-height: 1.5;
        margin-bottom: 2rem !important;
    }

    .video-modal {
        display: block;
        position: fixed;
        z-index: 9000;
        padding-top: 9rem;
        left: 0;
        top: 0;
        margin: 0 auto;
        width: 100%;
        height: 100%;
     }

    .modal-video-content {
        margin: 0 auto;
        display: flex;
        width: 100%;
        justify-content: center;

        .azuremediaplayer {
            height: 100%;
            min-height: 60rem;
        }
    }

    .close {
        position: absolute;
        top: 5rem;
        right: 3rem;
        font-size: 4.5rem;
        font-weight: bold;
        color: #f1f1f1;
        cursor: pointer;
        z-index: 1;
    }

    .close:hover,
    .close:focus {
        color: #bbb;
        text-decoration: none;
        cursor: pointer;
    }

    .vjs-big-play-button
    {
        display: none;
    }

    .azuremediaplayer {
        width: 80%;
        margin: 0 auto;
    }

    .video-player {
        width: 100%;
    }
    .video-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
      }
      
      .modal-video-content {
        position: relative;
        width: 80%;
        max-width: 1000px;
      }
      
      .video-player {
        position: relative;
        padding-top: 56.25%;
        height: 0;
        overflow: hidden;
      }
      
      #player-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      
      .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        border: none;
        color: white;
        padding: 10px;
        cursor: pointer;
        font-size: 20px;
        z-index: 1001;
      }
      
      .close-btn:hover {
        background-color: rgba(0, 0, 0, 0.7);
      }
      
      .video-preview-container {
        text-align: center;
      }
      
      .video-header {
        margin-bottom: 20px;
      }
      
      .video-container {
        position: relative;
      }
      
      .video-thumbnail {
        width: 820px;
        height: 460px;
        background-size: cover;
        background-position: center;
      }
      
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.0);
      }
      
      .overlay-info {
        color: white;
        text-align: center;
        position: relative;
        left: 25rem;
        top: 10rem;
      }
      
      .play-btn {
        background-color: #ddd;
        border: none;
        border-radius: .5em;
        color: #000;
        font-weight: 700;
        padding: .5rem 1rem;
        width: 12rem;
        position: relative;
      }
      
      .play-icon {
        margin-right: 10px;
      }

      .bmpui-ui-watermark{
        background: transparent !important;
        border: none !important;
        font-size: 0 !important;
      }
      
      .video-modal {
        position: fixed;
        bottom: 15%; 
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 1000;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        overflow: auto;
        visibility: visible;
      }
      
    .bitmovinplayer-poster {
        display: none !important;
    }

    .bmpui-ui-errormessage-overlay .bmpui-ui-tvnoisecanvas {
        filter: blur(20px);
        background: black;
    }

    .bmpui-ui-errormessage-overlay .bmpui-ui-errormessage-label {
        color: black;
    }
 
    .bmpui-ui-titlebar {
        background: linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.4));
    }
      
}