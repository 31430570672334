.certq-filename {
    margin-right: 15px;
    display: flex;

    span:nth-child(1) {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    width: 100%;
    font-style: italic;
}

.ql-tooltip {
    left: unset !important;
    z-index: 999;
}

.certq-details-wrapper {
    .screen-footer {
        position: relative;
        bottom: 3rem;
        padding-bottom: 3rem;
    }
}

.certq-details-ans-add-dialog {
    max-height: 80vh;
    overflow-y: auto;

    &.dialog__inner {
        max-height: none;
        overflow-y: clip;
    }
}

// fit a video or image into a dialog
.certq-details-ans-content-dialog {
    display: block;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .dialog__inner {
        display: block;
        max-width: 70vw;
        max-height: 70vh;
        width: 100%;
        height: 100%;
    }

    .__content {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: var(--nietLightGray);
        border: 1px solid var(--nietDarkBlue);



        &.file-upload-image {
            height: 50vh;
            background-repeat: no-repeat;
            background-color: var(--nietLightUI);
            background-position: center;
            background-size: contain;
            cursor: pointer;

            .video-player {
                width: 100%;
                height: 100%;
                padding-bottom: 0 !important;

                .azuremediaplayer {
                    width: 100%;
                    height: 100%;
                }

            }
        }

        img {
            max-width: 60vw;
            max-height: 60vh;
            width: auto;
            height: 100%;
            object-fit: scale-down;
        }
    }

    >.dialog__header {
        width: 100%;
    }

    >div {
        max-width: 100%;
        height: auto;
    }
}

.certificationQuestion-answer-editor__wrapper {
    height: 70%;
    max-height: 40rem;

    .certificationQuestion-answer-editor__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 5px;

        &__text {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
        }
    }

    &__search {
        margin-bottom: 5px;
    }

    .certificationQuestion-answer-editor {
        margin-top: 5px;
        max-height: 30rem;
        overflow-y: auto;
    }

    .loading-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 2rem;
    }

    .overlay {
        opacity: 0.5;

        .__table {
            pointer-events: none;
        }
    }

    .__table {

        width: 100%;
        display: flow-root;

        .bold {
            font-weight: bold;
        }

        .blue {
            border-color: var(--nietDarkBlue);
        }

        .__header {
            display: inline-flex;
        }

        .__head {
            th {
                margin-top: 5px;
            }

            position: sticky;
            top: 0;
            background: white;
            z-index: 99;
        }

        .__content {
            padding-right: 2rem;
            padding-bottom: 1.2rem;
            width: 70%;
            vertical-align: top;


            .rq {
                .ql-container {
                    padding: unset;

                    .ql-editor {
                        padding: unset;
                    }
                }
            }

            >div,
            p,
            h5,
            h6 {
                margin: 0;
            }
        }

        .__flex {
            width: 15%;
            vertical-align: top;

            &.blue {
                padding-top: .2rem;
            }

            .certq-filename {
                width: 7rem;
                font-size: 0.8rem;
            }

            input[type="radio"] {
                &:after {
                    background: var(--nietDarkBlue);
                }

                display: flex;
                margin-left: 1.2em;
            }
        }

        .__icon {
            padding-right: 15px;
            height: 3rem;
            vertical-align: top;

            svg {
                display: block;
                margin: auto;
                width: 1rem;
                height: auto;
                cursor: pointer;
                transition: opacity 0.1s ease, transform 0.1s ease;
            }

            svg:hover {
                opacity: 0.7;
                transform: scale(1.1);
            }

            .hardDeleteIcon {
                margin-left: 7px;
                color: red;
            }
        }
    }
}




.tag-picker {
    max-width: 50rem;

    input {
        box-shadow: none !important;
    }

    .rs-picker-tag-wrapper {
        padding-bottom: 0px !important;
    }

    .rs-btn {
        display: flex;
    }

    .rs-stack-item svg {
        top: 7px !important;
    }

    .rs-picker-toggle-clean {
        top: 7px !important;
    }
}

.observation-component-text {
    margin-left: 1.5rem;
    width: 100%;
    color: var(--red);
}

.metrics-header {
    padding-bottom: 1rem;
}

.metrics-container {
    display: flex;
}

.metrics-button {
    padding-right: 1rem;

    .btn_control__okay {
        width: 21rem;
    }
}