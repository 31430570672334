@media print and (orientation: landscape) {

    .print-page {
        position: absolute;
        display: block;
        zoom: .75;

        .print-content {
            margin-top: 2rem;
            height: unset;


            .observation-table-wrapper {

                .row {
                    border-bottom: 2px solid rgba(0, 0, 0, .3);

                    .col {
                        border-right: 2px solid rgba(0, 0, 0, .3);
                    }
                }

                .table-row:last-child {
                    border-bottom: 2px solid rgba(0, 0, 0, .3);
                }

                .observation-table {
                    .table-header {
                        .fixed-width {
                            width: 12rem;
                        }
                    }
                    .table-row {
                        .survey-rubric-cell {
                            width: 12rem;
                            border-right: 2px solid rgba(0, 0, 0, .3);

                            &:first-child {
                                border-left: 2px solid rgba(0, 0, 0, .3);
                            }

                            &:last-child {
                                border-right: 2px solid rgba(0, 0, 0, .3);
                            }
                        }
                    }
                }
            }
        }
    }

    .certification-page {
        background-color: unset;

        .screen-wrapper {
            .rubric-modal {
                display: none;
            }

            .certification {
                display: none;
            }

            .blockui {
                display: none;
            }
        }
    }

    .observation-page {
        display: none;
    }

    .header {
        display: none;
    }

    .footer {
        display: none;
    }
}