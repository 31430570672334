.account-settings__heading-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 !important;
}

.account-settings__strengthen-security {
  padding-top: 1rem;
}

.field-input .generic-input-component .title {
  align-items: center;
}

.account-settings__heading {
  margin-bottom: 0;
  margin-left: 0.5em;
  font-weight: 700;
}

.account-settings__main {
  margin-top: 3em;
}

.account-settings__subheading {
  font-weight: 700;
  margin-top: 1.5em;
}

%account-settings__subheading-desc {
  font-size: 1.25rem;
  margin-left: 2.5em;
}

.account-settings__subheading-desc-password {
  @extend %account-settings__subheading-desc;
  font-weight: 500;
}

.account-settings__subheading-desc-mfa {
  @extend %account-settings__subheading-desc;
  font-weight: 400;
  margin-top: 0.5em;
}

.account-settings__subheading-desc-mfa-is-on {
  font-weight: 700;
}

%account-settings__field {
  display: grid;
}

.account-settings__field-main {
  @extend %account-settings__field;
  grid-template-columns: 8.5em auto;
}

.account-settings__field-mfa {
  @extend %account-settings__field;
  grid-template-columns: 9em auto;
  margin-top: 1em;
}

.account-settings__field-value {
  font-weight: 500;
  font-size: 1.25rem;
}

.account-settings__field-value-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.account-settings__copy-ee-pass-id {
  margin-left: 0.5em;
  cursor: pointer;
  align-items: flex-end;
}

.account-settings__button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1.5em;
}

.tooltip {
  position: relative;
}

.tooltip .tooltip-text {
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  left: 6rem;
  width: 6em;
  padding: 0.2em 0;
  margin-left: 0.5em;
  color: white;
  background-color: black;
  text-align: center;
  border-radius: 6px;
}

.tooltip .tooltip-hover-text {
  visibility: hidden;
  position: absolute;
  bottom: 100%;
  left: 6rem;
  width: 6em;
  padding: 0.2em 0;
  margin-left: 0.5em;
  color: white;
  background-color: black;
  text-align: center;
  border-radius: 6px;
}