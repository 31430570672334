.token-header {
    position: relative;
    top: 5rem;
    left: 5rem;
    font-size: 2rem;
    -webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.token-display-conatiner{
    position: relative;
    top: 6rem;
    left: 5rem;
    width: calc(100% - 10rem);
    min-width: 20rem;
}

.token-display{
    text-align: left;
    margin:10px;
    overflow: auto;
    white-space: nowrap;
    padding-bottom: 5px;
    font-weight: 600;
}

.token-display::-webkit-scrollbar {
    border-radius: 3px;
}
  
.token-display::-webkit-scrollbar-track {
    background: #222d32;        /* color of the tracking area */
    border-radius: 3px;
}
  
.token-display::-webkit-scrollbar-thumb {
    background-color: rgb(255, 255, 255);    /* color of the scroll thumb */
    border-radius: 3px;      /* roundness of the scroll thumb */
    border: 1px solid rgb(0, 0, 0);  /*creates padding around scroll thumb*/
}

.copy-token-btn {
    margin-bottom: 4rem;
    position: relative;
    top: 7rem;
    left: 5rem;
    width: 8rem;
    height: 3rem;
    cursor: pointer;
    text-align: center;
    line-height: 3rem;
    font-size: 1.2rem;
    border-radius: 5px;
    -webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.copy-token-btn:active {
    top: 7.1rem;
}


.claims-display-wrapper {
    position: relative;
    top: 5rem;
    left: 5rem;
    width: calc(100% - 10rem);
    min-width: 30rem;
}

.claims-display-header {
    position: relative;
    top: 0rem;
    left: 0rem;
    height: 4rem;
    width: 100%;
    text-align: left;
    line-height: 4rem;
    font-size: 2rem;
    -webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

pre {
    background-color: var(--color-left-side-nav);
    padding: 1rem;
    color: gray;
    font-size: 1.25rem;
    border-radius: 5px;
    overflow-x: auto;
    text-align: left;
}

pre::-webkit-scrollbar {
    height: 12px;
    border-radius: 3px;
}
  
pre::-webkit-scrollbar-track {
    background: #222d32;        /* color of the tracking area */
    border-radius: 3px;
}
  
pre::-webkit-scrollbar-thumb {
    background-color: rgb(255, 255, 255);    /* color of the scroll thumb */
    border-radius: 3px;      /* roundness of the scroll thumb */
    border: 1px solid rgb(0, 0, 0);  /*creates padding around scroll thumb*/
}