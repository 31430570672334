.home-container {
    height: 100%;
    max-width: 100%;
    padding-top: 1.25%;

    img {
        border-radius: 0.5rem;
    }

    .eepass-banner {
        position: absolute;
        top: 0.5rem;
        left: calc(50% - 22rem);
        width: 44rem;
        height: 100%;
        background-image: url('../../content/logos/EEPass_Logo_Banner.svg');
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
    }


    .hero-image {
        height: 36rem;
        width: 100%;
        object-fit: cover;
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
    }

    .hero-container {
        display: flex;
        place-content: space-evenly;
        max-width: 75%;
        margin: 0 auto;
    }

    .hero-column {
        padding-top: 2.5rem;
        display: inline-block;
        width: 50%;
        vertical-align: top;

        h1 {
            padding-bottom: .5rem;
        }
    }

    h1 {
        font-size: 4.25rem;
        font-weight: bold;
    }

    .header-content-desc {
        padding-top: 1.5rem;
        max-width: 40rem;
        font-weight: 500;
        font-size: 1.75rem;
        line-height: 1.3;
    }

    .login-btn {
        background-color: lightgray;
        border: 0.2rem solid var(--nietBlue);
        width: 12rem;
        color: var(--nietBlue);
        height: 0rem;
        padding: 1.25rem;
        font-weight: 800;
        max-width: 15rem;
        margin: 0 auto;
        margin-top: 5rem;
        letter-spacing: 0;
    }

    .rollover-banner {
        background-color: #FFEB3B;
        color: #FF0000;
        padding: 20px;
        text-align: center;
        font-size: 1.2em;
        font-weight: bold;
        margin-bottom: 20px; 
        max-width: 800px; 
        margin-left: auto; 
        margin-right: auto; 
        word-wrap: break-word;
    }
    
}