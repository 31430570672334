.plus-icon-rubric {
    font-size: large;
    bottom: 2.3em;
    position: absolute;
    left: -0.6em;
    z-index: 2000;
    
    &:hover {
        cursor: pointer;
    }
}