.testimonial-container {
    margin-left: 4rem;
    margin-right: 4rem;

    .testimonial-header {
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 3rem;
    }

    .selected-testinomial-image {
        height: 32rem;
        margin-right: 12rem;
        width: 86%;
        object-fit: cover;
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
    }

    .fade-in {
        opacity: 1 !important;
    }

    .fade-out {
        opacity: 0 !important;
    }

    .selected-member-container {
        display: flex;
        justify-content: center;
    }

    .testimonial-selected-container {
        max-width: 75%;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
    }

    .selected-testimonial-location {
        display: flex;
        align-items: center;
        justify-content: left;
    }

    .selected-testimonial-info {
        align-self: center;
        max-width: 47rem;
        font-size: 1.25rem;
        height: 29rem;

        .testimonial-body-text {
            max-width: 35rem;
            padding-bottom: 12rem;
            font-size: 1.5rem;
            font-weight: 500;
            min-height: 75%;
            max-height: 75%;
        }

        .selected-member-info {
            margin-right: 2em;
            height: 25%;
            max-width: 23rem;
            min-width: 23rem;
        }

        .member-name {
            font-weight: bold;
            font-size: 1.5rem;
        }

        .selected-testimonial-state-image {
            height: 8rem;
        }
    }

    .testimonial-list-img-container {
        width: 15rem;
        height: 12rem;
        overflow: hidden;
        border-radius: 0.5rem;
        cursor: pointer;
        margin: 0 20px;
    }

    .img-grid-container {
        display: flex;
        justify-content: center;
    }

    .testimonial-list-img {
        width: auto;
        height: 12rem;
        object-fit: cover;
    }

    .testimonial-img-container {
        padding-top: 5rem;
    }

    .not-selected-img {
        filter: grayscale(100%);
    }

    .line {
        border-top: 0.3rem solid var(--nietBlue);
        width: 10.5rem;
        margin-top: 1.75rem;
        position: absolute;
        transition: all 0.3s ease-in-out;
    }
}