.header {
    position: fixed;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 1rem;
    height: var(--header-height);
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    margin-top: .1rem;
    box-shadow: 0 4px 2px -2px gray;

    .schoolYear-message {
        color: var(--error);
        font-weight: 500;
        font-size: 1.35rem;
        margin-top: 7.75rem;
        margin-right: 1rem;
        background-color: rgba(255, 255, 255, 0.9);
        font-style: italic;
        padding-left: .5rem;
        padding-right: .25rem;
        border-radius: .5rem;
    }

    &.no-box-shadow {
        box-shadow: none;
    }

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &__main {
        height: var(--header-height);
    }

    &__logo {
        cursor: pointer;

        img {
            width: 9.5rem;
        }
    }

    &__left-section {
        display: flex;
        flex-direction: row;
        justify-content: right;
        width: 100%;
    }

    .eepass-banner {
        position: absolute;
        top: 0.5rem;
        left: calc(50% - 19.5rem);
        width: 39rem;
        height: 100%;
        background-image: url('../../content/logos/EEPass_Logo_Banner.svg');
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
    }

    .eepass-banner-homepage {
        position: absolute;
        top: 0rem;
        left: 2rem;
        width: 39rem;
        height: 100%;
        background-image: url('../../content/logos/EEPass_Logo_Banner.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .notification-banner {
        position: absolute;
        padding: 1rem;
        left: 50%;
        top: 6rem;
        width: 36rem;
        color: var(--red);
        border: 1px solid var(--red);
        background-color: white;
        transform: translate(-50%);
        z-index: 9999;
        display: flex;
        cursor: pointer;

        .icon-red {
            path {
                fill: #C61616;
            }
        }

        .notification-banner-header {
            display: flex;
            flex: 1;
            justify-content: space-between;
            align-items: center;
            font-size: 1.2rem;
            margin-left: 1rem;
        }
    }

    .profile {
        white-space: nowrap;

        &.authenticated {
            position: absolute;
            top: 0rem;
            right: 0rem;
            height: 100%;
        }

        .profile-btn {
            font-size: 2rem;
            color: var(--nietDarkBlue);
            margin-right: 3rem;
            cursor: pointer;
            font-weight: 600;
        }

        .testDisplay {
            color: green;
        }
        
        .profile-dropdown {
            position: absolute;
            //top: calc(var(--header-height) / 2);
            top: 0rem;
            right: 1rem;
            height: 100%;
            //background-color: pink;
            font-size: 1.25rem;
            cursor: pointer;

            .profile-image {
                cursor: pointer;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 1px solid var(--nietBlue);
                box-shadow: 0 0 0 1px var(--nietBlue);
                background-color: var(--nietLightGray);

                >svg {
                    width: 60%;
                    height: 60%;
                    position: relative;
                    top: 12%;
                    left: 20%;

                    >path {
                        fill: var(--nietBlue);
                    }
                }

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                }
            }

            .name-and-icon-wrapper {
                height: 100%;
                display: flex;
                align-items: center;

                .profile-wrapper {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    padding: 0.5rem 0.7rem;
                    border: 2px solid var(--nietDarkBlue);
                    border-radius: 0.6rem;

                    transition: transform 0.2s ease-in-out;

                    &:hover {
                        .drop-down-icon {
                            transform: scale(1.2);
                        }
                    }
                }

                .person-icon {
                    position: relative;
                    float: left;
                    color: var(--nietDarkBlue);
                }

                .display-name {
                    position: relative;
                    float: left;
                    margin-left: 1.25rem;
                    font-size: 1.5rem;
                    font-weight: 600;
                    color: var(--nietDarkBlue);
                }

                .menu-btn {
                    position: relative;
                    float: right;
                    margin-left: 1.25rem;
                    color: var(--nietDarkBlue);

                    .drop-down-icon {
                        transition: transform 0.2s ease-in-out;

                        &.active {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }

        .active-drop-down {
            position: absolute;
            top: 100%;
            right: 1rem;
            width: 15rem;
            background-color: white;
            border-left: 1px solid rgba(0, 61, 91, 0.2);
            border-bottom: 1px solid rgba(0, 61, 91, 0.2);
            box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            border-bottom-left-radius: 6px;

            .drop-down-option {
                height: 3rem;
                padding-right: 1.5rem;
                padding-left: 1.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 1.25rem;
                font-weight: 600;
                cursor: pointer;

                &:hover {
                    background-color: rgba(233, 234, 235, 0.5);
                }

                &.active {
                    background-color: rgba(233, 234, 235, 0.4);
                    color: #f47e3a;
                }

                .selected-school-year {
                    font-size: 0.9rem;

                    &.not-current-year {
                        color: red;
                    }
                }
            }
        }
    }

    .left-nav-btn {
        margin-left: 2rem;
        margin-top: .25rem;
    }
}

.header-box-shadow {
    box-shadow: 0px 2px 2px var(--lightgray-2);
}

.navigation {
    position: fixed;
    background-color: white;
    top: var(--header-height);
    left: 0;
    width: 28rem;
    height: calc(100% - var(--header-height));
    z-index: 100;
    transition: transform 400ms ease 0s;
    -webkit-transition: transform 400ms ease 0s;
    -moz-transition: transform 400ms ease 0s;
    -ms-transition: transform 400ms ease 0s;
}


.school-year-select {
    position: relative;
    left: 10%;
    width: 80%;
}