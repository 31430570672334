// a majority originates from base.scss

.sidebar-navigation {
  .nav-link {
    cursor: pointer;

  }
}

.sidebar-navigation ul li div .menuOptions {
  font-weight: bold;
  font-size: 2rem;
}

.sidebar-navigation ul li div .menuSubOptions {
  font-weight: 400;
  font-size: 1.7rem;

  &.sub {
    margin-left: 1.5rem;
    font-style: italic;
    font-size: 1.5rem;
  }
}

.sidebar-navigation ul li>div .sidebar-icon-indicator {
  position: relative;
  right: 1.5rem;
  opacity: 1;
}


.app-sidebar {
  background: #fff;
  top: 0;
}

.app-sidebar--header {
  height: 7rem;
  margin-bottom: 1.25rem;
}

.singleOption {
  margin-left: 2.5rem;
  margin-bottom: 1.9rem;
}

.groupOption {
  margin-left: 2.5rem;
  margin-bottom: 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .hasHover:hover {
    transform: translateY(-4px);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
}

.groupSubOption {
  height: 2rem !important;
  margin-left: 5.25rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
}

.groupSubOption.last {
  margin-bottom: 2.25rem;
}

.MuiButton-text {
  padding: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.sidebar-navigation h1 {
  margin-left: 2.5rem;
  font-weight: bold;
  font-size: 2rem !important;
}

.sidebar-navigation ul li {
  transition: max-height ease-in-out 0.25s;
  overflow: hidden;
}

.sidebar-navigation ul li div .menuOptions {
  font-weight: bold;
  font-size: 2rem;
}

.sidebar-navigation ul li>div .sidebar-icon-indicator {
  position: relative;
  right: 2.5rem;
  opacity: 1;
}

.groupOption svg.MuiSvgIcon-root {
  fill: var(--nietDarkBlue);
  transform: rotate(-90deg) scale(2.5);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.groupOption svg.MuiSvgIcon-root:hover {
  transform: rotate(-90deg) scale(3);
}

.nav-link.active,
.groupOption .active {
  color: var(--nietOrange);
}

.groupOption .active>svg.MuiSvgIcon-root {
  transform: rotate(90deg) scale(2.5);
}

.groupOption .active>svg.MuiSvgIcon-root:hover {
  transform: rotate(90deg) scale(3);
}

.sidebar-navigation ul li>div.active .sidebar-icon-indicator {
  transform: rotate(0);
}

.sidebar-navigation ul li>.active,
.sidebar-navigation ul li>.active:hover,
.sidebar-navigation ul li>.active .sidebar-icon-indicator svg.MuiSvgIcon-root,
.sidebar-navigation ul li>.active:hover .sidebar-icon-indicator svg.MuiSvgIcon-root {
  color: var(--nietOrange);
  fill: var(--nietOrange);
}

.sidebar-navigation ul li>.groupSubOption:hover {
  transform: translateY(-4px);
  transition: all 0.2s ease-in-out;
}