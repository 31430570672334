.profile__heading-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.screen-header {
    margin-left: 0rem;
  }

  margin-top: 1em;
}

.profile__heading {
  margin-bottom: 0;
  margin-left: 1rem;
  font-weight: 700;

  .instructions {
    margin-left: 1.5rem;
    font-weight: 100;
  }
}

.profile__subheading {
  font-weight: 700;
  margin-top: 1.5em;
}

%profile__field {
  display: grid;
}

.profile__field-contact {
  @extend %profile__field;
  grid-template-columns: 6em auto;
}

.profile__field-organization {
  @extend %profile__field;
  grid-template-columns: 9.5em auto;
}

.profile__field-permissions {
  @extend %profile__field;
  grid-template-columns: 8.5em auto;
}

.profile__field-value {
  font-weight: 500;
  font-size: 1.25rem;
}

.profile__field-edit {
  margin-left: 0.5em;
  cursor: pointer;
}

.profile__roles-list {
  margin-left: 4em;
  font-weight: 500;
  font-size: 1.25rem;

  ul {
    padding-left: unset;
  }
}

.profile__image {
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid var(--nietBlue);
  box-shadow: 0 0 0 1px var(--nietBlue);
  background-color: var(--nietLightGray);

  >svg {
    width: 60%;
    height: 60%;
    position: relative;
    top: 20%;
    left: 20%;

    >path {
      fill: var(--nietBlue);
    }
  }

  >img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}